import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Monty } from './monty';
import { MontyBodyComponent } from './monty-body/monty-body.component';
import { MontyConstructionComponent } from './monty-construction/monty-construction.component';
import { MontyEmptyComponent } from './monty-empty/monty-empty.component';
import { MontyErrorComponent } from './monty-error/monty-error.component';
import { MontyHeadComponent } from './monty-head/monty-head.component';
import { MontyHolidayComponent } from './monty-holiday/monty-holiday.component';
import { MontyLoadingComponent } from './monty-loading/monty-loading.component';
import { MontyNewComponent } from './monty-new/monty-new.component';
import { MontyOnlineBodyComponent } from './monty-online-body/monty-online-body.component';
import { MontySlowRequestComponent } from './monty-slow-request/monty-slow-request.component';
import { MontySuccessComponent } from './monty-success/monty-success.component';
import { MontySuccessBodyComponent } from './monty-success-body/monty-success-body.component';

@Component({
    selector: 'kip-monty',
    templateUrl: './monty.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class MontyComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #monty: Monty = Monty.Body;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set monty(value: Monty) {
    if (this.#monty !== value) {
      this.#monty = value;
      this.#rebuildMonty();
    }
  }

  get monty() {
    return this.#monty;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef | undefined;

  ngOnInit() {
    this.#rebuildMonty();
  }

  #rebuildMonty() {
    if (this.content) {
      this.content.clear();
      switch (this.monty) {
        case Monty.Body:
          this.content.createComponent(MontyBodyComponent);
          break;
        case Monty.Empty:
          this.content.createComponent(MontyEmptyComponent);
          break;
        case Monty.Error:
          this.content.createComponent(MontyErrorComponent);
          break;
        case Monty.New:
          this.content.createComponent(MontyNewComponent);
          break;
        case Monty.SlowRequest:
          this.content.createComponent(MontySlowRequestComponent);
          break;
        case Monty.Head:
          this.content.createComponent(MontyHeadComponent);
          break;
        case Monty.Loading:
          this.content.createComponent(MontyLoadingComponent);
          break;
        case Monty.Construction:
          this.content.createComponent(MontyConstructionComponent);
          break;
        case Monty.Holiday:
          this.content.createComponent(MontyHolidayComponent);
          break;
        case Monty.OnlineBody:
          this.content.createComponent(MontyOnlineBodyComponent);
          break;
        case Monty.Success:
          this.content.createComponent(MontySuccessComponent);
          break;
        case Monty.SuccessBody:
          this.content.createComponent(MontySuccessBodyComponent);
          break;
      }
    }
    this.#changeDetectorRef.markForCheck();
  }
}
