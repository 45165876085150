import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';

import { CertificateNode } from '../../models';

@Component({
    selector: 'kip-certificate-node',
    templateUrl: './certificate-node.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class CertificateNodeComponent {

  readonly icons = Icons;

  @Input({ required: true }) certificateNode: CertificateNode | undefined;
  @Output() readonly select = new EventEmitter<CertificateNode>();

  nodeSelected() {
    if (this.certificateNode) {
      if (this.certificateNode.children.length > 0) {
        this.certificateNode.expanded = !this.certificateNode.expanded;
      } else {
        this.select.emit(this.certificateNode);
      }
    }
  }

}
