import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { Button } from '../models';

@Component({
    selector: 'kip-no-results',
    templateUrl: './no-results.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoResultsComponent {

  readonly title = input.required();
  readonly text = input('');
  readonly canEdit = input<boolean | undefined>(false);
  readonly addButtons = input<Button[]>([]);

}
