import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'kip-overlay',
    templateUrl: './overlay.component.html',
    styleUrl: './overlay.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OverlayComponent {

  readonly bgColor = input('');

}
