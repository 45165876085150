import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-wizard-magic-f',
    templateUrl: './wizard-magic-f.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconWizardMagicFComponent implements IconComponent {

}
