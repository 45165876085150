import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'kip-spinner',
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SpinnerComponent {

}
