import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Icons } from 'icon-lib';
import { finalize, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fileUploadingProgressHandler } from 'service-lib';
import { SharedService, UploadedFileModel } from 'ui-common-lib';

import { ActivityUploaderParams } from '../shared';
import { SessionService } from '../tutor/services';
import { getEventsRequest } from '../tutor/store/log';

enum UploadStatus {
  Waiting = 0,
  Uploading = 1,
  Processing = 2,
  Error = 3,
  Success = 4
}
@Component({
    selector: 'kip-create-ad-hoc-activity-public',
    templateUrl: './create-ad-hoc-activity-public.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreateAdHocActivityPublicComponent implements OnInit, OnDestroy {

  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #sessionService = inject(SessionService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #sending = false;
  #result = '';
  #hasError = false;
  #isUploadingFinished = false;
  #lessonId?: number;
  #lessonPlanId?: number;
  readonly #progress$ = new Subject<number>();
  #activityName = '';
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly uploadStatus = UploadStatus;

  currentUploadStatus = UploadStatus.Waiting;
  files: readonly File[] = [];
  fileModels: readonly UploadedFileModel[] = [];

  set activityName(value: string) {
    this.#activityName = value;
  }

  get activityName() {
    return this.#activityName;
  }

  get hasError() {
    return this.#hasError;
  }

  get hasFileError() {
    return this.fileModels.some(model => model.hasError);
  }

  get isUploadingFinished() {
    return this.#isUploadingFinished;
  }

  get lessonId() {
    return this.#lessonId;
  }

  get lessonPlanId() {
    return this.#lessonPlanId;
  }

  get result() {
    return this.#result;
  }

  get isLoading() {
    return this.#sending;
  }

  get progress$(): Subject<number> {
    return this.#progress$;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#activatedRoute.queryParamMap.subscribe(queryParam => {
        this.#lessonId = +queryParam.get(ActivityUploaderParams.LessonId)!;
        this.#lessonPlanId = +queryParam.get(ActivityUploaderParams.LessonPlanId)!;
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  async createAdHocActivity() {

    let base64Img = '';
    if (this.files?.length) {
      base64Img = await SharedService.blobToBase64(this.files[0]) || '';
    }

    if (this.lessonId) {
      this.#result = 'Creating, please wait...';
      this.currentUploadStatus = UploadStatus.Uploading;
      this.#sending = true;
      this.#subscriptions.push(
        this.#sessionService.createAdHocActivity(this.#lessonPlanId!, base64Img, this.#activityName, getEventsRequest())
          .pipe(
            finalize(() => this.#sending = false),
            tap(response => this.#progress$.next(fileUploadingProgressHandler(response as HttpProgressEvent)))
          )
          .subscribe({
            next: result => {
              if (result.type === HttpEventType.Response) {
                this.files = [];
                this.fileModels = [];
                this.#result = 'Activity created!';
                this.currentUploadStatus = UploadStatus.Success;
                this.#isUploadingFinished = true;
              }
              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              this.#hasError = true;
              this.#isUploadingFinished = false;
              this.#result = 'Creating failed!';
              this.currentUploadStatus = UploadStatus.Error;
              this.#changeDetectorRef.markForCheck();
            }
          }));
    }
  }

  onNewUploading() {
    this.files = [];
    this.#result = '';
    this.#isUploadingFinished = false;
    this.#hasError = false;
    this.#sending = false;
    this.currentUploadStatus = UploadStatus.Waiting;
  }
}
