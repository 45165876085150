import { Pipe, PipeTransform } from '@angular/core';
import { Route } from 'ui-common-lib';

@Pipe({
    name: 'sideBar',
    standalone: false
})
export class SideBarPipe implements PipeTransform {
  transform(object: { [key: string]: Route } | undefined, legacy: boolean): { key: string, value: Route }[] {
    if (!object) {
      return [];
    }
    const values = Object.entries(object).map(([key, value]) => ({ key: key, value: value }));

    const returnValues: { key: string, value: Route }[] = [];
    for (const value of values) {
      const add = legacy ? !value.value.hidden : !value.value.hidden && !value.value.legacy;
      if (add) {
        returnValues.push(value);
      } else {
        const children = this.transform(value.value.children, legacy);
        if (children.length > 0) {
          for (const child of children) {
            child.value.pathOverride = `${value.value.path}/${child.value.path}`;
          }
          returnValues.push(...children);
        }
      }
    }

    return returnValues;
  }
}
