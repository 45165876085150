import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Award, Icon } from 'awards-lib';

@Component({
    selector: 'kip-student-awards-add',
    templateUrl: './student-awards-add.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAwardsAddComponent {

  #displayIcon: Icon | undefined;
  #selectedAward: Award | undefined;
  #keyword = '';

  get awardPickedIcon() {
    if (this.#selectedAward === undefined) {
      return this.#displayIcon;
    }

    return this.#selectedAward.id;
  }

  get selectedAward() {
    return this.#selectedAward;
  }

  set keyword(value: string) {
    this.#keyword = value;
  }

  get keyword() {
    return this.#keyword;
  }

  get isKeywordSearch() {
    return this.#keyword !== '';
  }

  get displayIcon() {
    return this.#displayIcon;
  }

  @Input({ required: true }) lessonId: number | undefined;
  @Input({ required: true }) studentId: number | undefined;

  @Output() readonly cancel = new EventEmitter();
  @Output() readonly chatAlert = new EventEmitter<string>();

  selectIcon(award: Award) {
    this.#selectedAward = award;
    this.#displayIcon = award.id;
  }

}
