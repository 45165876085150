import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { map, Observable, Subscription, takeWhile, tap, timer } from 'rxjs';

import { UpdatePaymentDetailsService } from '../services';

@Component({
    selector: 'kip-update-payment-details',
    templateUrl: './update-payment-details.component.html',
    styleUrl: './update-payment-details.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UpdatePaymentDetailsComponent implements OnInit, OnDestroy {

  readonly #updatePaymentDetailsService = inject(UpdatePaymentDetailsService);
  readonly #activeModal = inject(NgbActiveModal);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #updatePaymentSmsRetryExpiry: moment.Moment | undefined;
  #updatePaymentSmsRetryExpiryKey = 'updatePaymentSmsRetryExpiry';
  #invalidFormStyle = 'invalid-form-control';
  isLoading = false;
  mobileEnding: string | undefined;
  errorMessage: string | undefined;
  lastFour: string | undefined;
  verificationSent = false;
  updatePaymentRetryCounter$: Observable<number> | undefined;
  readonly icons = Icons;

  @ViewChild('verifyMobileForm') verifyMobileForm: ElementRef<HTMLFormElement> | undefined;
  @ViewChild('verifyMobileLastFourForm') verifyMobileLastFourForm: ElementRef<HTMLFormElement> | undefined;

  ngOnInit() {
    this.#subscriptions.push(this.#updatePaymentDetailsService.getMobileLastFour().subscribe(lastFour => this.lastFour = lastFour));

    const retryExpiry = localStorage.getItem(this.#updatePaymentSmsRetryExpiryKey);

    if (retryExpiry) {
      this.#updatePaymentSmsRetryExpiry = moment(retryExpiry);

      if (this.#updatePaymentSmsRetryExpiry > moment()) {
        const seconds = Math.floor(moment.duration(this.#updatePaymentSmsRetryExpiry.diff(moment())).asSeconds());

        this.updatePaymentRetryCounter$ = timer(0, 1000).pipe(
          map(n => (seconds - n) * 1000),
          takeWhile(n => n >= 0),
          tap(n => {
            if (n === 0) {
              this.updatePaymentRetryCounter$ = undefined;
            }
          })
        );
      }
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  verify() {
    this.isLoading = true;
    this.errorMessage = undefined;

    if (!this.verifyMobileLastFourForm) {
      this.isLoading = false;
      return;
    }

    const inputs = this.verifyMobileLastFourForm.nativeElement.querySelectorAll('input') as NodeList;
    let errors = false;
    let value = '';

    for (const input of inputs) {
      const element = input as HTMLInputElement;
      if (!element.value) {
        element.classList.add(this.#invalidFormStyle);
        errors = true;
      }

      value += element.value;
    }

    if (value.length !== 4 || value !== this.lastFour) {
      this.errorMessage = 'Incorrect last four digits. Please try again.';
      this.isLoading = false;
      return;
    }

    if (!errors && value.length === 4) {
      this.#subscriptions.push(this.#updatePaymentDetailsService.generateVerificationCode().subscribe(mobileEnding => {
        localStorage.setItem(this.#updatePaymentSmsRetryExpiryKey, moment().add(5, 'minutes').toISOString());
        this.verificationSent = true;
        this.mobileEnding = mobileEnding;
        this.isLoading = false;
        this.#changeDetectorRef.markForCheck();
      }));
    } else {
      this.isLoading = false;
    }
  }

  onKey(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    switch (event.key) {
      case 'Backspace':
        this.#handleBackSpace(target);
        break;
      case 'ArrowLeft':
        this.#handleArrowLeft(target);
        break;
      case 'ArrowRight':
        this.#handleArrowRight(target);
        break;
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    if (!this.verifyMobileForm) {
      return;
    }

    const inputs = this.verifyMobileForm.nativeElement.querySelectorAll<HTMLInputElement>('input');
    const clipboardData = event.clipboardData;

    if (clipboardData) {
      const value = clipboardData.getData('text');

      if (inputs) for (const [i, input] of inputs.entries()) {
        input.value = value[i] || '';
      }
    }
  }

  onInput(event: Event) {
    const element = event.target as HTMLInputElement;

    if (element.classList.contains(this.#invalidFormStyle)) {
      element.classList.remove(this.#invalidFormStyle);
    }

    const nextSibling = element.nextElementSibling as HTMLInputElement;

    if (nextSibling && element.value) {
      nextSibling.focus();

      if (nextSibling.value) {
        nextSibling.select();
      }
    }
  }

  onSubmit() {
    this.isLoading = true;

    if (!this.verifyMobileForm) {
      this.isLoading = false;
      return;
    }

    const inputs = this.verifyMobileForm.nativeElement.querySelectorAll('input') as NodeList;
    let errors = false;
    let value = '';

    for (const input of inputs) {
      const element = input as HTMLInputElement;
      if (!element.value) {
        element.classList.add(this.#invalidFormStyle);
        errors = true;
      }

      value += element.value;
    }

    if (!errors && value.length === 6) {
      this.#subscriptions.push(this.#updatePaymentDetailsService.verifyCode(value).subscribe({
        next: url => {
          this.isLoading = false;
          window.open(url, '_blank');
          this.#activeModal.close();
        },
        error: _ => {
          this.errorMessage = 'Invalid verification code.';
          this.isLoading = false;
        }
      }));
    } else {
      this.isLoading = false;
    }
  }

  cancel() {
    this.#activeModal.close();
  }

  #handleBackSpace(element: HTMLInputElement) {
    if (element.value) {
      element.value = '';
      return;
    }

    if (element.previousElementSibling) {
      (element.previousElementSibling as HTMLInputElement).focus();
    }
  }

  #handleArrowLeft(element: HTMLInputElement) {
    const previousInput = element.previousElementSibling as HTMLInputElement;
    if (!previousInput) return;
    previousInput.focus();
  }

  #handleArrowRight(element: HTMLInputElement) {
    const nextInput = element.nextElementSibling as HTMLInputElement;
    if (!nextInput) return;
    nextInput.focus();
  }
}
