import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentGoal } from '../../../../models';

@Component({
    selector: 'kip-goal-card',
    templateUrl: './goal-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GoalCardComponent {

  readonly icons = Icons;

  @Input({ required: true })
  studentGoal: StudentGoal | undefined;
}
