import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { determineOptional, FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-text',
    templateUrl: './form-control-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlTextComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | string | null>>();
  readonly labelExtra = input('');
  readonly type = input<'email' | 'password' | 'tel' | 'text'>('text');
  readonly maxLength = input<number | null>(null);

  get optional() {
    return determineOptional(this.control());
  }

}
