import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { RouteParams, SharedService, Signalify } from 'ui-common-lib';

import { LessonHistory, LessonHistoryActivity, signalifyLessonHistory, StudentDetailTabs, StudentSummary } from '../../models';
import { StudentService } from '../../services';

@Component({
    selector: 'kip-student-detail-tabs',
    templateUrl: './student-detail-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentDetailTabsComponent implements OnInit, OnDestroy {

  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #studentService = inject(StudentService);
  readonly #sharedService = inject(SharedService);

  #studentGradeId: number | undefined;
  #subscriptions: Subscription[] = [];
  #loadUpToDate: moment.Moment;
  readonly #receiveWeeksAtATime: number = 4;

  readonly tabNames = StudentDetailTabs;

  showLoadMore = true;
  lessonHistory: Signalify<LessonHistory>[] = [];
  loadingHistory = false;
  initialTab: string = StudentDetailTabs.LessonHistory;

  get goalCount(): number {
    return this.studentDetail?.goalSummary === undefined ? 0 : this.studentDetail.goalSummary.review;
  }

  get studentGradeId() {
    return this.#studentGradeId;
  }

  get studentId() {
    return this.studentDetail?.id;
  }

  @Input({ required: true }) studentDetail: StudentSummary | undefined;

  constructor() {
    this.#loadUpToDate = moment();
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#activatedRoute.queryParamMap.subscribe(queryParam => {
        const selectedTab = queryParam.get(RouteParams.SelectedTab);
        this.initialTab = selectedTab ?? StudentDetailTabs.LessonHistory;
        this.#changeDetectorRef.markForCheck();
      }));
    if (this.studentDetail) {
      this.#subscriptions.push(
        this.#sharedService.getGrades().subscribe(grades => {
          const studentGrade = grades.find(grade => grade.title === this.studentDetail?.grade);
          if (studentGrade) {
            this.#studentGradeId = studentGrade.id;
          }
          this.#changeDetectorRef.markForCheck();
        }));
    }

    this.loadMoreHistory();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onTabChange(event: NgbNavChangeEvent<string>) {
    this.#router.navigate([], {
      relativeTo: this.#activatedRoute,
      queryParams: { [RouteParams.SelectedTab]: event.nextId },
      queryParamsHandling: 'merge'
    });
  }

  loadMoreHistory() {
    if (this.studentDetail?.id) {
      const studentId = this.studentDetail?.id;

      const attendedOnly = false;
      const attemptedOnly = false;

      this.loadingHistory = true;
      this.#subscriptions.push(
        this.#studentService.getLessonHistoryByStudentId(studentId, this.#loadUpToDate.toDateStruct(), this.#receiveWeeksAtATime, attendedOnly, attemptedOnly)
          .subscribe({
            next: history => {
              if (history?.lessonHistory && history.lessonHistory.lessons.length > 0) {
                // ensure no duplicates
                const existingLessonIds = new Set(this.lessonHistory.map(s => s.lessonId));

                const newLessonHistory = history.lessonHistory.lessons.filter(s => !existingLessonIds.has(s.lessonId));
                for (const lessonHistory of newLessonHistory) {
                  this.#summarize(lessonHistory.activities);
                  this.#summarize(lessonHistory.homework);
                }

                const newLessonHistorySignalify = history.lessonHistory.lessons.filter(s => !existingLessonIds.has(s.lessonId)).map(s => signalifyLessonHistory(s));
                this.lessonHistory = [...this.lessonHistory, ...newLessonHistorySignalify];

                this.#loadUpToDate = moment().toMoment(history.lessonHistory.from);
              }

              this.showLoadMore = history.lessonHistory.lessons.length > 0;
              this.loadingHistory = false;
              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              this.loadingHistory = false;
              this.#changeDetectorRef.markForCheck();
            }
          }));
    }
  }

  #updateHeader(aiHeader: LessonHistoryActivity | undefined, activity: LessonHistoryActivity) {
    if (aiHeader) {
      aiHeader.aiCorrect = (aiHeader.aiCorrect ?? 0) + (activity.correct ?? 0);
      aiHeader.aiAttempted = (aiHeader.aiAttempted ?? 0) + (activity.attempted ?? 0);
      aiHeader.aiTotal = (aiHeader.aiTotal ?? 0) + (activity.total ?? 0);

      if (aiHeader.aiAttempted) {
        aiHeader.aiPercentage = Math.floor(100 * aiHeader.aiCorrect / aiHeader.aiAttempted);
      }
    }
  }

  #summarize(activities: readonly LessonHistoryActivity[]) {
    let aiGradeAndSubject = '';
    let aiHeader: LessonHistoryActivity | undefined;
    for (const activity of activities) {
      const newAiGradeAndSubject = activity.aiGradeAndSubject ?? '';
      if (aiGradeAndSubject === newAiGradeAndSubject) {
        activity.aiGradeAndSubject = null;
        this.#updateHeader(aiHeader, activity);
      } else {
        aiHeader = activity;
        this.#updateHeader(aiHeader, activity);
        aiGradeAndSubject = newAiGradeAndSubject;
      }
    }
  }
}
