import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numericEnumToArrayPipe',
    standalone: false
})
export class NumericEnumToArrayPipe implements PipeTransform {
  transform(data: object) {
    const keys = Object.keys(data);

    const numbers = keys.length / 2;

    const returnValues: { id: number, name: string }[] = [];
    for (let i = 0; i < numbers; i++) {
      returnValues.push({ id: Number.parseInt(keys[i], 10), name: keys[i + numbers].replace(/([A-Z])/g, ' $1').trim() });
    }

    return returnValues;
  }
}
