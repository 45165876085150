import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { QuestionLayoutType, QuestionSummary, RegionId } from '../models';
import { currencySwapFunction } from '../utilities/regions/currency-swap-function';
import { convertClock } from './types/clock-text';
import { convertDrill } from './types/drill-text';
import { convertFillInTheBlank } from './types/fill-in-the-blank-text';
import { convertGeneric } from './types/generic-text';
import { convertMatching } from './types/matching-text';
import { convertMultipleChoice } from './types/multiple-choice-text';
import { convertSelection } from './types/selection-text';
import { convertSingleChoice } from './types/single-choice-text';
import { convertSorting } from './types/sorting-text';
import { convertSpelling } from './types/spelling-text';
import { convertWordPicker } from './types/word-picker-text';

@Component({
    selector: 'kip-question-summary',
    templateUrl: './question-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuestionSummaryComponent {

  readonly #domSanitizer = inject(DomSanitizer);

  #question: QuestionSummary<any> = {
    type: 0,
    text: '',
    answers: [],
    parameters: null
  };

  html: SafeHtml | undefined;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set question(question: QuestionSummary<any>) {
    if (this.#question !== question) {
      this.#question = {
        type: question.type,
        answers: question.answers,
        parameters: question.parameters,
        text: this.#render(question.text)
      };
      this.#convertQuestionToHtml();
    }
  }

  get question() {
    return this.#question;
  }

  @Input() showAnswerOnly = true;

  /* eslint-enable kip/no-unused-public-members */

  #render(input: string) {
    if (input === null || input === undefined) {
      return '';
    }

    let output = input;
    output = output.replace(/\u00a0/g, ' ');

    // remove paragraph markers and line breaks - not required

    let regex = new RegExp(/<p>/g);
    output = output.replace(regex, '&nbsp;');

    regex = new RegExp(/<\/p>/g);
    output = output.replace(regex, '&nbsp;');

    regex = new RegExp(/<br>/g);
    output = output.replace(regex, '&nbsp;');

    return output;
  }

  #convertQuestionToHtml() {

    try {

      // This tries to simplify a question down to a single line of readable text
      // This is displayed in activities so questions can be summarized
      const genericInput = '<span class="">&nbsp;</span>';
      const genericChoice = '<span class="kip-generic-choice">&nbsp;</span>';
      // let text = this.#question.text;
      let text = '';

      switch (this.#question.type) {
        case QuestionLayoutType.Sorting:
          text = convertSorting(this.#question, genericInput, (render: string) => this.#render(render), this.showAnswerOnly);
          break;
        case QuestionLayoutType.Selection:
          text = convertSelection(this.#question, genericInput, (render: string) => this.#render(render), this.showAnswerOnly);
          break;
        case QuestionLayoutType.Matching:
          text = convertMatching(this.#question, genericInput, (render: string) => this.#render(render), this.showAnswerOnly);
          break;
        case QuestionLayoutType.Drill:
          text = convertDrill(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.SingleAnswerMultipleChoice:
          text = convertSingleChoice(this.#question, genericInput, genericChoice, (render: string) => this.#render(render), this.showAnswerOnly);
          break;
        case QuestionLayoutType.MultipleAnswerMultipleChoice:
          text = convertMultipleChoice(this.#question, genericInput, genericChoice, (render: string) => this.#render(render), this.showAnswerOnly);
          break;
        case QuestionLayoutType.AnalogueClock:
          text = convertClock(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.WordPicker:
          text = convertWordPicker(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.DigitalClock:
          text = convertClock(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.Spelling:
          text = convertSpelling(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.Generic:
          text = convertGeneric(this.#question, genericInput, this.showAnswerOnly);
          break;
        case QuestionLayoutType.FillInTheBlank:
          text = convertFillInTheBlank(this.#question, genericInput, this.showAnswerOnly);
          break;
      }

      const controlRegex = new RegExp(/<img [^>]+>/g);

      text = text.replace(controlRegex, (_substring: string) => '<div class="kip-generic-image">I</div>');

      text = currencySwapFunction(text, RegionId.Australia, true);

      this.html = this.#domSanitizer.bypassSecurityTrustHtml(text);

    } catch {
      this.html = this.#domSanitizer.bypassSecurityTrustHtml('Uh oh - something went wrong!');
    }
  }
}
