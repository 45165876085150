import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[kipDisableControl]',
    standalone: false
})
export class DisableControlDirective {

  // eslint-disable-next-line accessor-pairs
  @Input({ required: true }) set kipDisableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    }
  }

  constructor(private readonly ngControl: NgControl) {
  }

}
