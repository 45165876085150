import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-learning-maths-male-e',
    templateUrl: './learning-maths-male-e.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconLearningMathsMaleEComponent implements IconComponent {

}
