import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { FormBuilderTypeSafe } from 'forms-lib';
import { ProfileService, Tab } from 'ui-common-lib';

import { ActivityNote, AIResponse, LessonNote, LessonNoteUpdate, NotesForLesson } from '../models';
import { EditNotesBaseComponent } from '../shared/edit-notes-base.component';

enum TabIndex {
  Internal = 1,
  Parent = 2
}

@Component({
    selector: 'kip-tutor-edit-lesson-notes',
    templateUrl: './edit-lesson-notes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditLessonNotesComponent extends EditNotesBaseComponent<LessonNote, NotesForLesson> implements OnInit {

  #lastAIResponse: AIResponse | undefined;
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #profileService = inject(ProfileService);

  readonly #generatingAI = signal(false);

  get parentNoteApproved() {
    return this.noteForm.getSafe(s => s.parentNoteApproved);
  }

  get aiNote() {
    return this.noteForm.getSafe(s => s.aiNote);
  }

  get focusNote() {
    return this.noteForm.getSafe(s => s.focusNote);
  }

  readonly activityNotes = input.required<ActivityNote[] | undefined>();

  readonly tabIndex = TabIndex;
  readonly generatingAI = this.#generatingAI.asReadonly();
  readonly aiModelId = toSignal(this.#profileService.aiModelId(), { initialValue: 0 });
  readonly currentTabIndex = signal(TabIndex.Internal);

  readonly hasActivityNotes = computed(() => {
    const activityNotes = this.activityNotes();
    if (!activityNotes?.length) {
      return false;
    }

    return activityNotes.some(n => !!n.internalNote);
  });

  readonly tabs = computed(() => {
    const tabs: Tab<TabIndex>[] = [{
      index: TabIndex.Internal,
      title: 'Internal',
      visible: true
    },
    {
      index: TabIndex.Parent,
      title: 'Parent',
      visible: true
    }];

    return tabs;
  });

  readonly cancelledEditing = output<AIResponse | undefined>();

  constructor() {
    super();
    this.noteForm = this.#fb.group<NotesForLesson>({
      internalNote: new FormControl<string | null>(null),
      parentNote: new FormControl<string | null>(null),
      parentNoteRanking: new FormControl<string | null>(null),
      internalNoteRanking: new FormControl<string | null>(null),
      focusNote: new FormControl<string | null>(null),
      aiNote: new FormControl<string | null>(null), // Not used to update
      parentNoteApproved: new FormControl<boolean>(false)
    });

    this.aiNote.disable();
    this.parentNoteApproved.disable();
  }

  ignoreSave() {
    this.cancelledEditing.emit(this.#lastAIResponse);
  }

  override submit() {
    const note = this.note();
    if (!note) {
      throw new Error('Attempted to submit lesson note when note is not set');
    }

    if (!note.lessonId) {
      throw new Error('Attempted to submit lesson note when lessonId is not set');
    }

    const lessonNoteUpdate: LessonNoteUpdate = {
      lessonId: note.lessonId,
      internalNote: this.noteForm.value.internalNote,
      parentNote: this.noteForm.value.parentNote,
      focusNote: this.noteForm.value.focusNote,
      internalNoteRanking: this.noteForm.value.internalNoteRanking,
      parentNoteRanking: this.noteForm.value.parentNoteRanking
    };
    this.subscriptions.push(
      this.noteService.updateLessonNote(lessonNoteUpdate).subscribe({
        next: _value => {
          // need to get raw value to include aiNote which is disabled
          this.save.emit(this.noteForm.getRawValue());
          this.cancelledEditing.emit(undefined);
          this.changeDetectorRef.markForCheck();
        },
        error: (error: unknown) => {
          this.noteForm.handleServerErrors(error);
          this.changeDetectorRef.markForCheck();
        }
      }));
  }

  override patch() {
    const note = this.note();
    if (this.noteForm && note) {
      this.noteForm.patchSafe({
        internalNote: note.internalNote,
        parentNote: note.parentNote,
        parentNoteRanking: note.parentNoteRanking,
        internalNoteRanking: note.internalNoteRanking,
        focusNote: note.focusNote,
        aiNote: note.aiNote,
        parentNoteApproved: note.parentNoteApproved
      });
    }
  }

  generateAIResponse() {
    const note = this.note();
    if (note) {
      this.#generatingAI.set(true);
      this.subscriptions.push(this.noteService.getAIResponse(note.lessonId, this.aiModelId(), this.focusNote.value).subscribe(value => {
        this.#lastAIResponse = value;
        this.aiNote.setValue(value.promptText);
        this.parentNote.setValue(value.response);
        this.parentNoteApproved.setValue(value.approved);
        this.currentTabIndex.set(TabIndex.Parent);
        this.#generatingAI.set(false);
      }));
    }
  }

  appendActivityNotes() {
    const activityNotes = this.activityNotes();
    if (!activityNotes) {
      return;
    }

    // Concat all the activity notes
    const notes = activityNotes.map((n, i) => {
      if (!n.internalNote) {
        return '';
      }

      let summary = '';
      const internalNote = n.internalNote.trim();

      summary = i > 0 ? `\n\n${n.activityName}: ${internalNote}` : `${n.activityName}: ${internalNote}`;

      return summary;
    }).join('');

    if (this.note && notes?.length) {
      const currentNote = this.internalNote.value;
      this.note.set(Object.assign({}, this.note(), {
        internalNote: currentNote?.length > 0 ? `${currentNote}\n\n${notes}` : notes
      }));
      this.patch();
    }
  }

}
