import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-computer-whiz-girl-a',
    templateUrl: './computer-whiz-girl-a.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconComputerWhizGirlAComponent implements IconComponent {

}
