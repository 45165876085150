import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';

import { Address } from '../../models';
import { AddressPickerComponent } from '../address-picker/address-picker.component';

@Component({
    selector: 'kip-address-picker-optional',
    templateUrl: './address-picker-optional.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddressPickerOptionalComponent {

  readonly #fb = inject(FormBuilderTypeSafe);

  readonly #address = 'address';

  #available = false;

  addressForm!: FormGroupTypeSafe<Address>;

  @Input()
  set available(value: boolean) {
    this.#available = value;
    if (value) {
      this.addAddress();
    } else {
      this.removeAddress();
    }
  }

  get available(): boolean {
    return this.#available;
  }

  @Input({ required: true })
  parentForm!: FormGroupTypeSafe<{ address?: Address }>;

  constructor() {
    this.addressForm = AddressPickerComponent.buildDefaultControls(this.#fb);
  }

  addAddress() {
    if (!this.parentForm.get(this.#address)) {
      this.parentForm.addControl(this.#address, this.addressForm);
    }
  }

  removeAddress() {
    if (this.parentForm.get(this.#address)) {
      this.parentForm.removeControl(this.#address);
    }
  }
}
