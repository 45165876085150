import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-wizard-magic-a',
    templateUrl: './wizard-magic-a.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconWizardMagicAComponent implements IconComponent {

}
