import { ChangeDetectionStrategy, Component, input, model, output, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { PageInfo, PageListSize, PageRequest, SortBy } from '../models';

@Component({
    selector: 'kip-paged-table',
    templateUrl: './paged-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagedTableComponent<TFilter = void> {

  readonly pageLoading = input.required<Observable<PageInfo>>();
  readonly initialPageSize = input.required<PageListSize>();
  readonly listType = input.required<string>();
  readonly isSearchable = input(false);
  readonly filter = model<TFilter>({} as TFilter);
  readonly tableHeaderTemplate = input<TemplateRef<any> | null>(null);
  readonly tableOptionButtonTemplate = input<TemplateRef<any> | null>(null);
  readonly noResultsInSystemTemplate = input<TemplateRef<any> | undefined>(undefined);
  readonly sortBy = input<SortBy | undefined>(undefined);

  readonly pageSelected = output<PageRequest<TFilter>>();

}
