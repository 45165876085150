import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time',
    standalone: false
})
export class TimePipe implements PipeTransform {

  transform(value: number | string | undefined, defaultValue?: string): string {

    // If the value is not numerical, then return the default value

    let returnValue = value;

    if (returnValue === undefined || Number.isNaN(+returnValue)) {
      if (defaultValue) {
        return defaultValue;
      }

      returnValue = 0;
    }

    returnValue = +returnValue;

    // get rid of any decimals

    returnValue = Math.floor(returnValue);

    // Calculate the components
    const totalMinutes = Math.floor(returnValue / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes - hours * 60;
    const seconds = returnValue - totalMinutes * 60;

    // Build the output
    let builder = '';

    if (hours > 0) {
      builder += hours < 10 ? `0${hours}:` : `${hours}:`;
    }

    builder += minutes < 10 ? `0${minutes}:` : `${minutes}:`;
    builder += seconds < 10 ? `0${seconds}` : `${seconds}`;

    return builder;
  }
}
