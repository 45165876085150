import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import { DateStruct } from 'moment-extensions-lib';
import { Subscription } from 'rxjs';
import { NoteCategory, SharedService } from 'ui-common-lib';

import { NewNote, Note, SpecialNoteCategories, StudentNote } from '../../../../models';
import { StudentService } from '../../../../services';

@Component({
    selector: 'kip-student-note-edit',
    templateUrl: './student-note-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentNoteEditComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #sharedService = inject(SharedService);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  noteForm: FormGroupTypeSafe<Note>;

  noteCategories: NoteCategory[] = [];

  get locked() {
    return this.studentNote?.lessonInfo !== undefined && this.studentNote?.lessonInfo !== null;
  }

  get id() {
    return this.noteForm.getSafe(x => x.id);
  }

  get noteCategoryId() {
    return this.noteCategory.getSafe(x => x.id);
  }

  get noteCategory() {
    return this.noteForm.getSafeForm(x => x.noteCategory);
  }

  get pinned() {
    return this.noteForm.getSafe(x => x.pinned);
  }

  get description() {
    return this.noteForm.getSafe(x => x.description);
  }

  get action(): string {
    return this.id.value > 0 ? 'Edit' : 'Add';
  }

  get buttonText(): string {
    return this.id.value > 0 ? 'Save Changes' : 'Add Note';
  }

  @Input({ required: true })
  studentNote: StudentNote | undefined;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  studentId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly submitted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */
    const noteCategory = this.#fb.group<NoteCategory>({
      id: new FormControl<number>(5, Validators.required), // id from database
      name: new FormControl<string>('General') // name from database
    });
    /*eslint-enable @typescript-eslint/unbound-method */

    /*eslint-disable @typescript-eslint/unbound-method */
    this.noteForm = this.#fb.group<Note>({
      id: new FormControl<number>(0, Validators.required),
      noteCategory: noteCategory,
      pinned: new FormControl<boolean>(false, Validators.required),
      description: new FormControl<string | null>(null, Validators.required),
      ranking: new FormControl<number | null>(null),
      dueDate: new FormControl<DateStruct | null>(null),
      dueTime: new FormControl<DateStruct | null>(null),
      approved: new FormControl(true)
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#sharedService.getNoteCategories()
        .subscribe(
          noteCategories => {
            const blockedIds = new Set([
              SpecialNoteCategories.Activity,
              SpecialNoteCategories.Lesson,
              SpecialNoteCategories.Session,
              SpecialNoteCategories.Assessment,
              SpecialNoteCategories.Strengths,
              SpecialNoteCategories.Weaknesses,
              SpecialNoteCategories.StartLesson,
              SpecialNoteCategories.FinishLesson]);
            this.noteCategories = noteCategories.filter(s => !blockedIds.has(s.id));
            this.#changeDetectorRef.markForCheck();
          }
        ));

    if (this.studentNote) {
      this.noteForm.patchSafe(this.studentNote.note);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (this.noteForm.invalid) {
      markControlsAsTouched(this.noteForm);
      return;
    }
    if (this.studentId) {
      const note = this.noteForm.value;
      if (note.id > 0) {
        this.#subscriptions.push(
          this.#studentService.updateExistingNote(this.studentId, note)
            .subscribe(
              {
                next: () => this.submitted.emit(),
                error: (error: unknown) => this.noteForm.handleServerErrors(error)
              }));
      } else {

        let newNote: NewNote = {
          note: note,
          sessionId: null,
          lessonId: null,
          internal: null
        };

        if (this.studentNote?.lessonInfo) {
          newNote = {
            note: {
              id: note.id,
              noteCategory: note.noteCategory,
              pinned: false,
              description: note.description,
              ranking: note.ranking,
              dueDate: note.dueDate,
              dueTime: note.dueTime,
              approved: note.approved
            },
            sessionId: this.studentNote.lessonInfo.sessionId,
            lessonId: this.studentNote.lessonInfo.lessonId,
            internal: this.studentNote.lessonInfo.internal
          };
        }

        this.#subscriptions.push(
          this.#studentService.postNewNote(this.studentId, newNote)
            .subscribe(
              {
                next: () => this.submitted.emit(),
                error: (error: unknown) => this.noteForm.handleServerErrors(error)
              }));
      }
    }
  }

  delete() {
    if (this.studentId) {
      const note = this.noteForm.value;
      this.#subscriptions.push(
        this.#studentService.deleteExistingNote(this.studentId, note.id)
          .subscribe(
            {
              next: () => this.submitted.emit(),
              error: (error: unknown) => this.noteForm.handleServerErrors(error)
            }));
    }
  }

}
