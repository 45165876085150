import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';

import { commonRegex } from '../common-regex/common-regex';
import { CommonValidators } from '../common-validators/common-validators';

interface PasswordCheck {
  readonly password: string;
  readonly confirmPassword: string;
}

@Component({
    selector: 'kip-password-change',
    templateUrl: './password-change.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PasswordChangeComponent {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #modalService = inject(NgbModal);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #defaultPassword = '';

  readonly icons = Icons;

  resetForm: FormGroupTypeSafe<PasswordCheck>;

  @Input({ required: true }) title = '';
  @Input() showHeader = true;
  @Input() icon = Icons.information.regular;
  @Input() set defaultPassword(value: string) {
    this.#defaultPassword = value;
    this.resetForm.patchSafe({
      password: value,
      confirmPassword: value
    });
  }

  get defaultPassword() {
    return this.#defaultPassword;
  }

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

  @Output() readonly passwordChanged = new EventEmitter<string>();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */
    this.resetForm = this.#fb.group<PasswordCheck>({
      password: new FormControl<string | null>(null, [Validators.required, Validators.pattern(commonRegex.password), CommonValidators.hasUniqueCharacter]),
      confirmPassword: new FormControl<string | null>(null, Validators.required)
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  /* eslint-disable  kip/no-unused-public-members */

  open(size: 'lg' | 'sm' | 'xl' = 'sm') {
    setTimeout(() => {
      this.#modalService.open(this.modalContent, { size: size, centered: true });
      this.#changeDetectorRef.markForCheck();
    }, 100);
  }

  /* eslint-enable  kip/no-unused-public-members */

  submit() {
    this.passwordChanged.emit(this.resetForm.value.password);
    this.resetForm.patchSafe({
      password: '',
      confirmPassword: ''
    });
    this.#modalService.dismissAll();
  }

  cancel() {
    this.cancelled.emit();
    this.#modalService.dismissAll();
  }
}
