import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-ranking',
    templateUrl: './ranking.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RankingComponent {

  readonly icons = Icons;
  readonly label = input('');
  readonly ranking = input.required<number | null | undefined>();
  readonly comment = input<string | null>();

}
