import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'usersTimeZone',
    standalone: false
})
export class UsersTimeZonePipe implements PipeTransform {

  transform(dateTimeOffset: string, userTimeZoneName: string, format = 'D MMM YYYY[,] h:mm a'): string {
    return moment(dateTimeOffset).tz(userTimeZoneName).format(format);
  }
}
