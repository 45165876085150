import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';

import { CurriculumActivity, CurriculumNode, PreviewActivity } from '../../models';

@Component({
    selector: 'kip-curriculum-node',
    templateUrl: './curriculum-node.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurriculumNodeComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #resetTime: Date | undefined;

  readonly icons = Icons;

  set expand(expand: boolean) {
    if (this.rootNode) {
      this.rootNode.expanded = expand;
    }
  }

  get expand() {
    if (this.rootNode) {
      return this.rootNode.expanded === undefined ? false : this.rootNode.expanded;
    }
    return false;
  }

  @Input({ required: true }) allowPreview = true;
  @Input({ required: true }) singleSelection = false;
  @Input() showStatsAndHistory = true;
  @Input({ required: true }) rootNode: CurriculumNode | undefined;
  @Input({ required: true }) regionId: number = RegionId.Australia;

  // This is hack to force change detection of selected when we use reset at the parent level

  @Input({ required: true }) set resetTime(value: Date | undefined) {
    this.#resetTime = value;
    this.#changeDetectorRef.markForCheck();
  }

  get resetTime() {
    return this.#resetTime;
  }

  @Output() readonly showPreview = new EventEmitter<PreviewActivity>();

  selectActivity(activity: CurriculumActivity) {
    if (this.singleSelection) {
      this.previewActivity(activity);
    } else {
      activity.selected = !activity.selected;
    }
  }

  previewActivity(node: CurriculumActivity) {
    this.showPreview.emit({ id: node.id ?? 0, name: node.name ?? '', description: node.description ?? '' });
  }
}
