import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'diyActivityNames',
    standalone: false
})
export class DIYActivityNamesPipe implements PipeTransform {
  transform(activityNames: { id: number, name: string }[] | null | undefined): string {

    if (!activityNames) {
      return '[None]';
    }

    if (activityNames.length === 0) {
      return '[None]';
    }

    return `[${activityNames.map(s => s.name)}]`;
  }
}
