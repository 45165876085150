import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId, regions } from 'questions-lib';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AssociationType, ChildType, ContentHistory, ContentNode, ContentStatsNode, CurriculumActivity, CurriculumNode, LessonPlanActivity, PreviewActivity } from '../models';
import { ExploreService } from '../services';

@Component({
    selector: 'kip-activity-selector',
    templateUrl: './activity-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ActivitySelectorComponent implements OnInit, OnDestroy {

  readonly #exploreService = inject(ExploreService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #expandActivityId: number | undefined;
  #searchText = '';
  #contentNode: ContentNode | undefined;
  #filteredContentNodes: ContentNode[] = [];
  #filteredCurriculumNodes: CurriculumNode[] = [];
  #expandNodeId: number | undefined;
  #contentHistory: Map<number, ContentHistory> | undefined;
  #curriculumNodes: CurriculumNode[] | undefined;
  #curriculumMap = new Map<number, string[]>();
  #regionId = RegionId.Australia;
  readonly #searchSubject = new Subject<string>();
  #subscriptions: Subscription[] = [];
  readonly #searchText$: Observable<string>;
  #displayCurriculumNodes = true;

  readonly icons = Icons;
  readonly regions = regions;

  resetTime: Date | undefined;

  get displayCurriculumNodes() {
    return this.#displayCurriculumNodes;
  }

  get filteredCurriculumNodes() {
    return this.#filteredCurriculumNodes;
  }

  get filteredContentNodes(): ContentNode[] {
    return this.#filteredContentNodes;
  }

  set searchText(searchText: string) {
    this.#searchText = searchText;
  }

  get searchText(): string {
    return this.#searchText ?? '';
  }

  get selected() {
    const activities: LessonPlanActivity[] = [];
    const contentNodes = this.#filteredContentNodes ?? [];
    for (const contentNode of contentNodes) {
      this.#addSelectedActivitiesContentNodes(contentNode, activities);
    }
    const curriculumNodes = this.#filteredCurriculumNodes ?? [];
    if (curriculumNodes) {
      for (const curriculum of curriculumNodes) {
        this.#addSelectedActivitiesCurriculumNodes(curriculum, activities);
      }
    }
    return activities;
  }

  @Input() singleSelection = false;
  @Input() assessmentsOnly = false;
  @Input() allowPreview = true;
  @Input() showSearch = true;
  @Input() showRegion = true;

  @Input({ required: true }) set regionId(value: RegionId) {
    if (this.#regionId !== value) {
      this.#regionId = value;
      this.reload(() => { this.regionIdChange.emit(value); });
    }
  }

  get regionId() {
    return this.#regionId;
  }

  @Input() set contentHistory(value: Map<number, ContentHistory> | undefined) {
    if (this.#contentHistory !== value) {
      this.#contentHistory = value;
      this.#filterNodes();
      this.#changeDetectorRef.markForCheck();
    }
  }

  get contentHistory() {
    return this.#contentHistory;
  }

  @Input()
  set expandActivityId(value: number | undefined) {
    this.#expandActivityId = value;
    this.#refreshExpanded(this.#contentNode);
  }

  get expandActivityId() {
    return this.#expandActivityId;
  }

  @ViewChild('input', { static: false }) input: ElementRef<HTMLElement> | undefined;

  @Output() readonly showPreview = new EventEmitter<PreviewActivity>();
  @Output() readonly expandActivity = new EventEmitter<{ id: number, name: string, description: string }>();
  @Output() readonly regionIdChange = new EventEmitter<number>();

  constructor() {
    this.#searchText$ = this.#searchSubject
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged());
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  /* eslint-disable kip/no-unused-public-members */

  reload(completeAfter?: () => void) {
    if (this.assessmentsOnly) {
      this.#subscriptions.push(
        this.#exploreService
          .getAssessmentActivities(this.regionId)
          .subscribe(node => {
            this.#addActivities(node);
          }));
    } else {

      this.#subscriptions.push(
        this.#exploreService.getAccessKeys().subscribe(accessKeysAndCurriculum => {

          this.#displayCurriculumNodes = accessKeysAndCurriculum.displayCurriculumNodes;
          this.#getCurriculumNodes(() => {
            this.#filterNodes();
          });

          this.#subscriptions.push(
            this.#exploreService
              .getActivities(this.regionId)
              .subscribe(node => {
                this.#secureNode(node, accessKeysAndCurriculum.accessKeys);
                this.#addActivities(node);
                this.#updateNodesCurriculum(node);
                if (completeAfter) {
                  completeAfter();
                }
              }));
        }));
    }

    this.#subscriptions.push(
      this.#searchText$
        .subscribe(searchText => {
          this.#searchText = searchText;
          this.#filterNodes();
          this.#changeDetectorRef.markForCheck();
        }));
  }

  resetAll() {
    this.searchText = '';
    this.#searchSubject.next('');

    const contentNodes = this.#filteredContentNodes ?? [];
    for (const contentNode of contentNodes) {
      this.#resetContentNode(contentNode);
    }

    const curriculumNodes = this.#filteredCurriculumNodes ?? [];
    for (const curriculumNode of curriculumNodes) {
      this.#resetCurriculumNode(curriculumNode);
    }

    this.#filterNodes();
    this.resetTime = new Date();
    this.#changeDetectorRef.markForCheck();
  }

  /* eslint-enable kip/no-unused-public-members */

  change(event: KeyboardEvent) {
    this.#searchSubject.next((event.target as HTMLInputElement).value);
  }

  #getCurriculumNodes(callback?: () => void) {
    if (this.#displayCurriculumNodes) {
      this.#subscriptions.push(
        this.#exploreService.getCurriculumTree(this.#regionId).subscribe(curriculumNodes => {
          this.#curriculumNodes = curriculumNodes;
          this.#createRegionalMap(curriculumNodes);
          for (const curriculumNode of curriculumNodes) {
            this.#resetVisibleCurriculumNodes(curriculumNode, true);
          }
          this.#updateNodesCurriculum(this.#contentNode);
          if (callback) {
            callback();
          }
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  #addRegionalActivity(activities: LessonPlanActivity[], regionalActivity: CurriculumActivity) {
    if (regionalActivity.selected) {
      activities.push({
        activityId: regionalActivity.id,
        name: regionalActivity.name ?? '',
        previousActivityId: regionalActivity.previousActivityId,
        associationType: AssociationType.Manual,
        description: regionalActivity.description ?? '',
        isManual: regionalActivity.isManual ?? false,
        nextActivityId: regionalActivity.nextActivityId,
        internalNote: ''
      });
    }
  }

  #filterCurriculumNodesByText(curriculumNodes: CurriculumNode[] | undefined, filterText: string) {
    const filteredCurriculumNodes: CurriculumNode[] = [];
    if (curriculumNodes) {
      for (const curriculumNode of curriculumNodes) {
        const node = this.#filterCurriculumNodeByText(curriculumNode, filterText);
        if (node) {
          filteredCurriculumNodes.push(node);
        }
      }
    }

    return filteredCurriculumNodes;
  }

  #filterCurriculumNodeByText(curriculumNode: CurriculumNode, filterText: string): CurriculumNode | undefined {

    const isMatch = !filterText ||
      curriculumNode.topic?.code?.toLowerCase().includes(filterText) ||
      curriculumNode.topic?.description?.toLowerCase().includes(filterText) ||
      curriculumNode.topic?.activities?.find(
        a => a.name?.toLowerCase().includes(filterText)
          || a.description?.toLowerCase().includes(filterText)
      );

    if (isMatch) {
      curriculumNode.expanded = true;
      return Object.assign({}, curriculumNode);
    }

    if (curriculumNode.children) {
      const children: CurriculumNode[] = [];
      for (const child of curriculumNode.children) {
        const newChild = this.#filterCurriculumNodeByText(child, filterText);
        if (newChild) {
          newChild.expanded = true;
          children.push(newChild);
        }
      }
      if (children.length > 0) {
        const newNode = Object.assign({}, curriculumNode);
        newNode.children = children;
        newNode.expanded = true;
        return newNode;
      }
    }

    return undefined;
  }

  #resetVisibleCurriculumNodes(curriculumNode: CurriculumNode, visible: boolean) {
    curriculumNode.visible = visible;

    if (curriculumNode.children) {
      for (const child of curriculumNode.children) {
        this.#resetVisibleCurriculumNodes(child, visible);
      }
    }

    if (curriculumNode.topic) {
      if (curriculumNode.topic.activities) {
        for (const activity of curriculumNode.topic.activities) {
          activity.visible = visible;
        }
      }

      if (curriculumNode.topic.skillbuilderActivities) {
        for (const skillbuilderActivity of curriculumNode.topic.skillbuilderActivities) {
          skillbuilderActivity.visible = visible;
        }
      }
    }
    this.#changeDetectorRef.markForCheck();
  }

  #updateMapCurriculumNodes(curriculumNode: CurriculumNode, initialValue = '') {

    /* eslint-disable  no-param-reassign */

    if (curriculumNode.grade) {
      initialValue += ` / ${curriculumNode.grade.name}`;
    }

    if (curriculumNode.subject) {
      initialValue += ` /  ${curriculumNode.subject.name}`;
    }

    if (curriculumNode.region) {
      initialValue += ` / ${curriculumNode.region.name}`;
    }

    if (curriculumNode.topic) {
      initialValue += ' / ';
      if (curriculumNode.topic.code) {
        initialValue += curriculumNode.topic.code;

        if (curriculumNode.topic.description) {
          initialValue += ` : ${curriculumNode.topic.description}`;
        }
      }
      else if (curriculumNode.topic.description) {
        initialValue += curriculumNode.topic.description;
      }
    }

    /* eslint-enable  no-param-reassign */

    if (curriculumNode.children) {
      for (const child of curriculumNode.children) {
        this.#updateMapCurriculumNodes(child, initialValue);
      }
    }

    if (curriculumNode.topic) {
      if (curriculumNode.topic.activities) {
        for (const activity of curriculumNode.topic.activities) {
          this.#updateRegionalMap(activity.id, initialValue);
        }
      }

      if (curriculumNode.topic.skillbuilderActivities) {
        for (const skillbuilderActivity of curriculumNode.topic.skillbuilderActivities) {
          this.#updateRegionalMap(skillbuilderActivity.id, initialValue);
        }
      }
    }
  }

  #updateRegionalMap(activityId: number, nodePath: string) {
    const value = this.#curriculumMap.get(activityId) ?? [];
    value.push(nodePath);
    this.#curriculumMap.set(activityId, value);
  }

  #createRegionalMap(curriculumNodes: CurriculumNode[]) {
    this.#curriculumMap = new Map<number, string[]>();
    for (const curriculumNode of curriculumNodes) {
      const initialValue = '';

      this.#updateMapCurriculumNodes(curriculumNode, initialValue);
    }
  }

  #addSelectedActivitiesCurriculumNodes(curriculumNode: CurriculumNode, activities: LessonPlanActivity[] = []) {
    if (curriculumNode) {
      if (curriculumNode.topic) {
        if (curriculumNode.topic.activities) {
          for (const activity of curriculumNode.topic.activities) {
            this.#addRegionalActivity(activities, activity);
          }
        }

        if (curriculumNode.topic.skillbuilderActivities) {
          for (const activity of curriculumNode.topic.skillbuilderActivities) {
            this.#addRegionalActivity(activities, activity);
          }
        }
      }

      if (curriculumNode.children) {
        for (const child of curriculumNode.children) {
          this.#addSelectedActivitiesCurriculumNodes(child, activities);
        }
      }
    }
  }

  #addActivities(contentNode: ContentNode) {
    this.#subscriptions.push(
      this.#exploreService.getAdditionalActivities().subscribe(additional => {
        if (contentNode.children) {
          contentNode.children.push(...additional);
        }
        this.#refreshExpanded(contentNode);
        this.#resetCurriculumNode(contentNode);
        this.#contentNode = contentNode;
        this.#filterNodes();
        this.#changeDetectorRef.markForCheck();
      }));
  }

  #collapseAll(contentNode: ContentNode | undefined) {
    if (contentNode) {
      contentNode.expanded = false;
      if (contentNode.hasChildren && contentNode.children) {
        for (const child of contentNode.children) {
          this.#collapseAll(child);
        }
      }
    }
  }

  #filterNodes() {
    if (this.#contentNode) {
      this.#collapseAll(this.#contentNode);
      this.#contentNode.expanded = true;

      if (this.#searchText !== '') {

        const filterText = this.searchText.toLowerCase();

        this.#filteredCurriculumNodes = this.#filterCurriculumNodesByText(this.#curriculumNodes, filterText);
        this.#filteredContentNodes = this.#filterContentNodesByText(this.#contentNode, filterText);
      } else {
        this.#filteredContentNodes = this.#contentNode.children ?? [];
        this.#filteredCurriculumNodes = this.#curriculumNodes ?? [];
      }

      this.#filterNodesRegion(this.#filteredContentNodes);
    }

    this.#recalculateStatsAndHistory();
  }

  #recalculateStatsAndHistory() {
    const parentNode = { children: this.#filteredContentNodes, id: 0, name: '', description: '', warningTimeTutorOnly: false, hasChildren: true };
    this.#matchHistory(parentNode);
    this.#calculateStats(parentNode);
  }

  #filterNodesRegion(contentNodes: ContentNode[]) {
    const filteredNodes: ContentNode[] = [];
    for (const contentNode of contentNodes) {
      const node = this.#filterContentNodeByRegion(contentNode, this.#regionId);
      if (node) {
        filteredNodes.push(node);
      }
    }
    this.#filteredContentNodes = filteredNodes;
  }

  #refreshExpanded(contentNode: ContentNode | undefined) {
    if (contentNode) {
      this.#collapseAll(contentNode);
      if (this.#expandActivityId) {
        this.#expandNodeId = undefined;
        this.#matchActivity(contentNode, this.#expandActivityId);
        if (this.#expandNodeId) {
          setTimeout(() => {
            const element = document.querySelector(`#CN${this.#expandNodeId}`);
            if (element) {
              element.scrollIntoView(true);
            }
            this.#changeDetectorRef.markForCheck();
          }, 200);
        }
      }
    }
  }

  #secureNode(contentNode: ContentNode, accessKeys: number[]) {
    contentNode.accessBlocked = false;
    if (contentNode.accessKeys) {
      contentNode.accessBlocked = !contentNode.accessKeys.some(a => accessKeys.includes(a));
    }
    if (contentNode.hasChildren && contentNode.children) {
      for (const child of contentNode.children) {
        this.#secureNode(child, accessKeys);
      }
    }
  }

  #matchHistory(contentNode: ContentNode) {
    if (contentNode.childType === ChildType.Activity) {
      contentNode.history = this.#contentHistory && contentNode.childId ? this.#contentHistory.get(contentNode.childId) : undefined;
    }
    if (contentNode.hasChildren && contentNode.children) {
      for (const child of contentNode.children) {
        this.#matchHistory(child);
      }
    }
  }

  #calculateStats(contentNode: ContentNode): ContentStatsNode {
    const contentNodeStats = { activityCount: 0, completeCount: 0, percentage: 0 };
    if (contentNode.childType === ChildType.Activity) {
      contentNodeStats.activityCount++;
      if (contentNode.history) {
        contentNodeStats.completeCount += 1;
        contentNodeStats.percentage += contentNode.history.percentage;
      }
    }

    if (contentNode.children) {
      for (const childNode of contentNode.children) {
        const childStats = this.#calculateStats(childNode);
        contentNodeStats.activityCount += childStats.activityCount;
        contentNodeStats.completeCount += childStats.completeCount;
        contentNodeStats.percentage += childStats.percentage;
      }
    }

    contentNode.stats = contentNodeStats;

    return contentNodeStats;
  }

  #matchActivity(contentNode: ContentNode, activityId: number) {
    let matchFound = 0;
    if (contentNode.childType === ChildType.Activity && contentNode.childId === activityId) {
      this.#expandNodeId = contentNode.id;
      this.expandActivity.emit({ id: contentNode.childId, name: contentNode.name, description: contentNode.description ?? '' });
      matchFound += 1;
    } else {
      if (contentNode.hasChildren && contentNode.children) {
        for (const child of contentNode.children) {
          matchFound += this.#matchActivity(child, activityId);
        }
      }
    }

    if (matchFound > 0) {
      contentNode.expanded = true;
    }

    return matchFound;
  }

  #updateNodesCurriculum(contentNode: ContentNode | undefined) {
    if (contentNode) {
      if (contentNode.childId && contentNode.childType === ChildType.Activity) {
        contentNode.curriculum = this.#curriculumMap.get(contentNode.childId);
      }
      if (contentNode.children) {
        for (const cn of contentNode.children) {
          this.#updateNodesCurriculum(cn);
        }
      }
    }
  }

  #addSelectedActivitiesContentNodes(contentNode: ContentNode | undefined, activities: LessonPlanActivity[]) {
    if (contentNode) {
      if (contentNode.selected) {
        // don't add activity if it is already there
        if (!activities.some(a => a.activityId === (contentNode.childId ?? 0))) {
          activities.push({
            activityId: contentNode.childId ?? 0,
            name: contentNode.name,
            associationType: AssociationType.Manual,
            description: contentNode.description ?? '',
            isManual: contentNode.isManual ?? false,
            nextActivityId: contentNode.nextActivityId,
            previousActivityId: contentNode.previousActivityId,
            internalNote: ''
          });
        }
        if (contentNode.linkedActivities) {
          for (const linkedActivity of contentNode.linkedActivities) {
            // don't add linked activities if they are already there
            if (!activities.some(a => a.activityId === linkedActivity.id)) {
              activities.push({
                activityId: linkedActivity.id,
                name: linkedActivity.name,
                associationType: AssociationType.Linked,
                description: linkedActivity.description,
                isManual: linkedActivity.isManual ?? false,
                nextActivityId: linkedActivity.nextActivityId,
                previousActivityId: linkedActivity.previousActivityId,
                internalNote: '',
                linkedActivityIsHomework: linkedActivity.isHomework
              });
            }
          }
        }
      }
      if (contentNode.children) {
        for (const cn of contentNode.children) {
          this.#addSelectedActivitiesContentNodes(cn, activities);
        }
      }
    }
  }

  #resetCurriculumNode(contentNode: CurriculumNode | undefined) {
    if (contentNode) {
      if (contentNode.topic) {
        if (contentNode.topic.activities) {
          for (const activity of contentNode.topic.activities) {
            activity.selected = false;
          }
        }

        if (contentNode.topic.skillbuilderActivities) {
          for (const activity of contentNode.topic.skillbuilderActivities) {
            activity.selected = false;
          }
        }
      }

      if (contentNode.children) {
        for (const cn of contentNode.children) {
          this.#resetCurriculumNode(cn);
        }
      }
    }
  }

  #resetContentNode(contentNode: ContentNode | undefined) {
    if (contentNode) {
      if (contentNode.selected) {
        contentNode.selected = false;
      }
      if (contentNode.children) {
        for (const cn of contentNode.children) {
          this.#resetContentNode(cn);
        }
      }
    }
  }

  #filterContentNodeByRegion(contentNode: ContentNode, regionId: number): ContentNode | undefined {
    const isMatch = !contentNode?.regions || contentNode?.regions && (contentNode.regions.length === 0 || contentNode.regions.includes(regionId));
    if (isMatch) {
      if (contentNode.hasChildren && contentNode.children) {
        const children: ContentNode[] = [];
        for (const child of contentNode.children) {
          const newChild = this.#filterContentNodeByRegion(child, regionId);
          if (newChild) {
            children.push(newChild);
          }
        }
        if (children.length > 0) {
          const newNode = Object.assign({}, contentNode);
          newNode.hasChildren = children.length > 0;
          newNode.children = children;
          return newNode;
        }
        return undefined;
      }

      return Object.assign({}, contentNode);
    }

    return undefined;
  }

  #filterContentNodesByText(contentNode: ContentNode, filterText: string) {
    const filteredNodes: ContentNode[] = [];
    if (contentNode?.children) {
      for (const child of contentNode.children) {
        const node = this.#filterContentNodeByText(child, filterText);
        if (node) {
          filteredNodes.push(node);
        }
      }
    }

    return filteredNodes;
  }

  #filterContentNodeByText(contentNode: ContentNode, filterText: string): ContentNode | undefined {
    const isMatch = !filterText || contentNode.name.toLowerCase().includes(filterText) ||
      contentNode.description?.toLowerCase().includes(filterText);
    if (isMatch) {
      contentNode.expanded = true;
      return Object.assign({}, contentNode);
    }

    if (contentNode.hasChildren && contentNode.children) {
      const children: ContentNode[] = [];
      for (const child of contentNode.children) {
        const newChild = this.#filterContentNodeByText(child, filterText);
        if (newChild) {
          newChild.expanded = true;
          children.push(newChild);
        }
      }
      if (children.length > 0) {
        const newNode = Object.assign({}, contentNode);
        newNode.hasChildren = children.length > 0;
        newNode.children = children;
        newNode.expanded = true;
        return newNode;
      }
    }

    return undefined;
  }
}
