
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from 'icon-lib';

import { StudentAwardSummary } from '../../models';

@Component({
    selector: 'kip-points-student-list',
    templateUrl: './points-student-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PointsStudentListComponent {

  readonly icons = Icons;

  showAll = false;

  @Input({ required: true }) students: StudentAwardSummary[] | undefined = [];

  @Input() title = '';
  @Input() count = 0;
}

