import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DeliveryMethodType } from '../../models';

@Component({
    selector: 'kip-delivery-method-single-picker',
    templateUrl: './delivery-method-single-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DeliveryMethodSinglePickerComponent {

  readonly deliveryMethods: { id: number, name: string, checked: boolean }[] = [
    { id: DeliveryMethodType.InCentre, name: 'In Centre', checked: false },
    { id: DeliveryMethodType.Online, name: 'Online', checked: false }];
  readonly name = input.required<string>();
  readonly label = input('');
  readonly labelClass = input<string | undefined>(undefined);
  readonly deliveryMethodId = input.required<FormControl<number>>();

}
