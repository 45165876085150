import {
  AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject,
  Input, OnDestroy, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { AnalogueClockComponent } from '../layouts/analogue-clock/analogue-clock.component';
import { DigitalClockComponent } from '../layouts/digital-clock/digital-clock.component';
import { DrillComponent } from '../layouts/drill/drill.component';
import { FillInTheBlankComponent } from '../layouts/fill-in-the-blank/fill-in-the-blank.component';
import { GenericComponent } from '../layouts/generic/generic.component';
import { MatchingComponent } from '../layouts/matching/matching.component';
import { MultipleChoiceComponent } from '../layouts/multiple-choice/multiple-choice.component';
import { QuestionLayout } from '../layouts/question-layout';
import { SortingComponent } from '../layouts/sorting/sorting.component';
import { SpellingComponent } from '../layouts/spelling/spelling.component';
import { WordMatchComponent } from '../layouts/word-match/word-match.component';
import { WordPickerComponent } from '../layouts/word-picker/word-picker.component';
import {
  AnswerType, AnswerValidator, AnswerValidatorResult, AnswerValidatorType,
  Question,
  QUESTION_BEHAVIOUR_CONTROLLER_FACTORY,
  QuestionLayoutType, QuestionSettings, QuestionSettingsKey,
  QuestionSpeed, Region, RegionId, SoundState, Tag,
  ValidationResult
} from '../models';
import { QuestionsService } from '../services';
import { currencySwapFunction, regions } from '../utilities';
import { AlgebraValidator } from '../validators/algebra.validator';
import { CurrencyValidationHelper } from '../validators/currency/currency-validation.helper';
import { CurrencyValidator } from '../validators/currency/currency-validator';
import { EqualityValidator } from '../validators/equality.validator';
import { EqualityUnorderedValidator } from '../validators/equality-unordered.validator';
import { FractionsValidator } from '../validators/fractions.validator';
import { MathsValidator } from '../validators/maths.validator';
import { MathsUnorderedValidator } from '../validators/maths-unordered.validator';
import { NumberValidator } from '../validators/number/number-validator';
import { ParagraphValidator } from '../validators/paragraph.validator';
import { TimeMinutesHoursValidator } from '../validators/time-minutes-hours.validator';
import { UnitsValidator } from '../validators/units.validator';
import { WordPickerValidator } from '../validators/word-picker.validator';

@Component({
    selector: 'kip-question',
    templateUrl: './question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: '.question-btn-link { border: 1px solid transparent; transition: border 0.15s; } .question-btn-link:hover { border: 1px solid #EEE;}',
    standalone: false
})
export class QuestionComponent implements AfterContentChecked, OnDestroy {

  #regionQuestion: Question | undefined;
  #question: Question | undefined;
  #tags: Tag[] = [];
  #regionId = RegionId.Australia;
  #soundRegionId = RegionId.Australia;
  #region: Region | undefined;
  #subscriptions: Subscription[] = [];
  #soundState = SoundState.Unknown;

  readonly #layouts = {
    [QuestionLayoutType.Generic]: GenericComponent,
    [QuestionLayoutType.SingleAnswerMultipleChoice]: MultipleChoiceComponent,
    [QuestionLayoutType.MultipleAnswerMultipleChoice]: MultipleChoiceComponent,
    [QuestionLayoutType.Sorting]: SortingComponent,
    [QuestionLayoutType.Selection]: SortingComponent,
    [QuestionLayoutType.Matching]: MatchingComponent,
    [QuestionLayoutType.DigitalClock]: DigitalClockComponent,
    [QuestionLayoutType.AnalogueClock]: AnalogueClockComponent,
    [QuestionLayoutType.WordPicker]: WordPickerComponent,
    [QuestionLayoutType.Spelling]: SpellingComponent,
    [QuestionLayoutType.WordMatch]: WordMatchComponent,
    [QuestionLayoutType.FillInTheBlank]: FillInTheBlankComponent,
    [QuestionLayoutType.Drill]: DrillComponent
  };

  readonly #validators: { [typeId in AnswerValidatorType]: AnswerValidator } = {
    [AnswerValidatorType.Equality]: new EqualityValidator(),
    [AnswerValidatorType.Maths]: new MathsValidator(),
    [AnswerValidatorType.Paragraph]: new ParagraphValidator(),
    [AnswerValidatorType.WordPicker]: new WordPickerValidator(),
    [AnswerValidatorType.EqualityUnordered]: new EqualityUnorderedValidator(),
    [AnswerValidatorType.MathsUnordered]: new MathsUnorderedValidator(),
    [AnswerValidatorType.TimeMinutesHours]: new TimeMinutesHoursValidator(),
    [AnswerValidatorType.Money]: new CurrencyValidator(),
    [AnswerValidatorType.Number]: new NumberValidator(),
    [AnswerValidatorType.Units]: new UnitsValidator(),
    [AnswerValidatorType.Fractions]: new FractionsValidator(),
    [AnswerValidatorType.Algebra]: new AlgebraValidator()
  };

  #layoutRef: ComponentRef<QuestionLayout> | undefined;
  readonly #replaySubscription: Subscription;
  #started = false;
  #ready = false;
  #validating = false;

  get soundState() {
    switch (this.#soundState) {
      case SoundState.Playing:
        return 'Playing';
      case SoundState.Finished:
        return 'Finished';
      case SoundState.Error:
        return 'Unable';
      default:
        return '';
    }
  }

  get regionClass() {
    if (this.#region) {
      return `region-${this.#region.countryCode}`;
    }

    return '';
  }

  get canSubmit(): boolean {
    return this.#started && this.#ready && !this.#validating;
  }

  get showSubmit(): boolean {
    return this.allowSubmit && !this.readonly;
  }

  @Input() set tags(value: Tag[]) {
    this.#tags = value;
  }

  get tags() {
    return this.#tags;
  }

  @Input() showSoundState = false;
  @Input() version = '';
  @Input() allowSkip = false;
  @Input() recordSkip = false;
  @Input() allowQuit = false;
  @Input() allowShowAnswer = false;

  @Input({ required: true }) set question(value: Question | undefined) {
    if (value !== undefined) {
      this.#question = value;
      this.#setRegionQuestion();
    } else {
      this.#question = undefined;
    }
  }

  get question() {
    return this.#question;
  }

  @Input({ required: true }) set regionId(value: number) {
    this.#regionId = value;
    this.#region = regions.find(s => s.id === value);
    this.#setRegionQuestion();
  }

  get regionId() {
    return this.#regionId;
  }

  @Input() set soundRegionId(value: number) {
    this.#soundRegionId = value;
  }

  get soundRegionId() {
    return this.#soundRegionId;
  }

  @Input() autoFocus = true;
  @Input({ required: true }) readonly = false;
  @Input() allowSubmit = true;
  @Input() studentAnswers: readonly AnswerType[] | undefined;

  @ViewChild('layout', { read: ViewContainerRef, static: true }) layout: ViewContainerRef | undefined;

  @Output() readonly answered = new EventEmitter();
  @Output() readonly skipped = new EventEmitter();
  @Output() readonly updated = new EventEmitter<AnswerValidatorResult>();
  @Output() readonly validated = new EventEmitter<AnswerValidatorResult>();
  @Output() readonly rendered = new EventEmitter<Date>();
  @Output() readonly quit = new EventEmitter();

  constructor(
    private readonly questionsService: QuestionsService,
    private readonly changeDetectorRef: ChangeDetectorRef,

    // Has problems if you define type
    // https://github.com/angular/angular/issues/12631

    @Inject(QUESTION_BEHAVIOUR_CONTROLLER_FACTORY) private readonly behaviourControllerFactory: any) {

    // Listen for replay requests and invoke the current layout
    this.#subscriptions.push(
      this.#replaySubscription = this.questionsService.replay$.subscribe(() => {
        if (this.#started && this.#ready && !this.#validating) {
          this.#started = false;
          this.#ready = false;

          this.changeDetectorRef.detectChanges();

          this.#invoke(true);
        }
      }));
  }

  ngAfterContentChecked() {
    this.#rebuild(true);
  }

  ngOnDestroy() {
    if (this.#replaySubscription) {
      this.#replaySubscription.unsubscribe();
    }

    this.#cleanup();

    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  showAnswer() {
    this.readonly = true;
    this.#rebuild(false);
  }

  next() {
    this.readonly = false;
    this.skip();
  }

  skip() {

    // HACK - we have different functionality for skip depending on whether it is used in lesson or self assessments
    if (this.recordSkip) {
      this.skipped.emit();
    } else if (this.#layoutRef) {
      const question = this.#layoutRef.instance.question;
      if (question) {
        this.validated.emit({
          correct: false,
          question: question,
          answers: []
        });
      }
    }
  }

  focusControl() {
    this.#layoutRef?.instance.incomplete();
  }

  validateAnswers() {
    if (this.#layoutRef && !this.#validating) {

      // Validate the answers and broadcast the result
      const answers = this.#layoutRef.instance.answers;
      const isIncomplete = answers.length === 0 || answers.includes('');

      // if no answers, ignore submit
      if (isIncomplete) {
        this.#layoutRef.instance.incomplete();
        return;
      }

      this.#validating = true;
      this.changeDetectorRef.detectChanges();

      const result = this.#getValidatorResult(answers);

      if (result) {
        const emit = () => {
          this.validated.emit(result.answer);
        };

        // If correct, ensure the correct behaviour sequence is run
        if (result.answer.correct) {
          if (this.#layoutRef.instance.behaviour) {
            this.#subscriptions.push(
              this.#layoutRef.instance.behaviour
                .invokeSequence('correct')
                .subscribe(() => emit()));
          }

        } else {
          this.#layoutRef.instance.validationResults = result.items;
          emit();

          // We don't know how the parent component will handle the incorrect answer
          // As a quick workaround, add a timer to reactivate the submit button - should stop double clicking
          this.#subscriptions.push(
            timer(2000).subscribe(() => {
              this.#validating = false;
              this.changeDetectorRef.detectChanges();
            }));
        }
      }
    }
  }

  // eslint-disable-next-line kip/no-unused-public-members
  updateAnswers() {
    if (this.#layoutRef) {
      const answers = this.#layoutRef.instance.answers;
      const result = this.#getValidatorResult(answers);
      if (result) {
        this.updated.emit(result.answer);
      }
    }
  }

  #getValidatorResult(answers: AnswerType[]): { answer: AnswerValidatorResult, items: ValidationResult[] } | undefined {
    if (this.#layoutRef) {

      // Get the answer validator and check the answers
      const question = this.#layoutRef.instance.question;
      if (question) {
        const validator = this.#validators[question.validator];

        if (!validator) {
          throw new Error(`No validator found for type '${question.validator}'.`);
        }

        validator.region = this.#region;

        const validationResults = validator.validate(question, answers);
        let correct = true;
        if (validationResults.length > 0) {
          for (const validationResult of validationResults) {
            if (validationResult === ValidationResult.Incorrect) {
              correct = false;
              break;
            }
          }
        } else {
          correct = false;
        }

        return {
          answer: {
            correct: correct,
            question: question,
            answers: answers
          },
          items: validationResults
        };
      }
    }

    return undefined;
  }

  // the font used makes it look like we are telling them how many letters there are, so need to use different font
  #changeUnderscoreFont(text: string) {
    let outputText = text;

    const regExp = new RegExp('[_]{2,}', 'gi');
    outputText = outputText.replace(regExp, (value: string) => {
      return `<span class='text-sans-serif'>${value}</span>`;
    });

    return outputText;
  }

  #setRegionQuestion() {

    this.#regionQuestion = JSON.parse(currencySwapFunction(this.#changeUnderscoreFont(JSON.stringify(this.#question)), this.#regionId));

    // swap out answer (expects a number - formatting to region)
    // money validation questions can only have 1 answer so it is ok to swap like this

    if (this.#question && this.#question.validator === AnswerValidatorType.Money && this.#region && this.#regionQuestion) {
      for (let index = 0, lastIndex = this.#question.answers.length; index < lastIndex; index++) {
        const answerToSwap = this.#question.answers[index].values[0].toString().replace('.', this.#region.currencySwap.decimalSymbol);
        const validationHelper = new CurrencyValidationHelper(answerToSwap,
          new Intl.NumberFormat(this.#region.locale, { style: 'currency', currency: this.#region.currency }),
          this.#region.validSuffixes);

        this.#regionQuestion.answers[index].values = validationHelper.createMatches();
      }
    }

    if (this.#question && this.#question.validator === AnswerValidatorType.Number && this.#region && this.#regionQuestion) {

      for (let index = 0, lastIndex = this.#question.answers.length; index < lastIndex; index++) {
        const answerValue = this.#question.answers[index].values[0].toString();
        const answerToSwap = answerValue.replace('.', this.#region.currencySwap.decimalSymbol);

        // if the number has a zero decimal (e.g 1.0 it would display 1, which is not what we want)

        let minimumFractionDigits = 0;

        if (answerValue.includes('.')) {
          minimumFractionDigits = answerValue.split('.')[1].length;
        }

        // This is a hack, as some numbers have more than 3 decimal places
        // and if you don't specify, it defaults to 3

        const validationHelper = new CurrencyValidationHelper(answerToSwap,
          new Intl.NumberFormat(this.#region.locale,
            {
              style: 'decimal',
              minimumFractionDigits: minimumFractionDigits,
              maximumFractionDigits: 10
            }), []);

        this.#regionQuestion.answers[index].values = validationHelper.createMatches();
      }
    }
  }

  #rebuild(check: boolean) {

    const questionToDisplay = this.#regionQuestion;

    // If the layout has the same question id then do nothing, otherwise clean up
    if (check && this.#layoutRef && this.#layoutRef.instance.question === questionToDisplay &&
      this.#layoutRef.instance.studentAnswers === this.studentAnswers) {
      return;
    }

    this.#cleanup();

    // Render the provided question via its layout
    if (questionToDisplay) {
      const layoutType = this.#layouts[questionToDisplay.type];

      if (!layoutType) {
        throw new Error(`No layout found for type '${questionToDisplay.type}'.`);
      }

      // Initialize the tracking state
      // Could this be done better to avoid too many variables???
      this.#started = false;
      this.#ready = false;
      this.#validating = false;

      // If found, configure and render it
      const behaviour = this.behaviourControllerFactory(questionToDisplay.behaviour);

      behaviour.regionId = this.#soundRegionId;

      if (this.layout) {
        this.#layoutRef = this.layout.createComponent<QuestionLayout>(layoutType);
        this.#layoutRef.instance.region = this.#region;
        this.#layoutRef.instance.question = questionToDisplay;
        this.#layoutRef.instance.behaviour = behaviour;
        this.#layoutRef.instance.autoFocus = this.autoFocus;
        this.#layoutRef.instance.readonly = this.readonly;
        this.#layoutRef.instance.studentAnswers = this.studentAnswers;
        this.#layoutRef.instance.answered = () => this.answered.emit();
        this.#layoutRef.instance.updated = () => this.updateAnswers();
        this.#layoutRef.instance.submit = () => this.validateAnswers();
        this.#layoutRef.instance.behaviour?.soundState$.subscribe(value => {
          this.#soundState = value;
          this.changeDetectorRef.markForCheck();
        });
        this.#layoutRef.instance.ready = ready => {
          this.#ready = ready;
          this.rendered.emit(new Date());
          this.changeDetectorRef.detectChanges();
        };
      }

      this.changeDetectorRef.detectChanges();

      this.rendered.emit(new Date());

      // Invoke the start behaviour sequence once the layout is added

      if (!this.readonly && this.#layoutRef) {

        // Apply the settings needed to control the layout externally
        // If not explicitly set apply a default replayable value based on the start sequence
        let settings: QuestionSettings | undefined;

        settings = this.#applySetting(QuestionSettingsKey.Replayable, this.#layoutRef.instance.replayable, settings);
        settings = this.#applySetting(QuestionSettingsKey.Speed, this.#layoutRef.instance.speed, settings);

        if (!settings || settings.replayable === undefined) {
          const canStart = behaviour.hasSequence('start');
          settings = this.#applySetting(QuestionSettingsKey.Replayable, canStart, settings);
        }

        if (settings) {
          this.questionsService.settings(settings);
        }

        // Invoke the layout rendering and sequences
        this.#invoke(false);
      }

    }
  }

  #applySetting(name: QuestionSettingsKey, value: any, settings: QuestionSettings | undefined): QuestionSettings {

    let returnSettings = settings;
    if (value !== undefined) {
      returnSettings = returnSettings ?? {
        speed: QuestionSpeed.Normal
      };
      if (name === QuestionSettingsKey.Replayable) {
        returnSettings.replayable = value;
      } else {
        returnSettings.speed = value;
      }
    }

    return returnSettings!;
  }

  #invoke(replay: boolean) {
    if (this.#layoutRef) {

      // First invoke the start sequence
      if (this.#layoutRef.instance.behaviour) {
        this.#subscriptions.push(
          this.#layoutRef.instance.behaviour
            .invokeSequence('start')
            .subscribe(() => {
              this.#started = true;
              this.changeDetectorRef.detectChanges();
            }));
      }

      // Then get the layout to replay as required
      // This is not needed when the layout is initialized as it is assume it will auto play
      if (replay) {
        this.#layoutRef.instance.replay();
      }
    }
  }

  #cleanup() {
    if (this.#layoutRef) {
      if (this.#layoutRef.instance.behaviour) {
        this.#layoutRef.instance.behaviour.dispose();
      }
      this.#layoutRef.destroy();

      this.#layoutRef = undefined;
    }
  }
}
