import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-graduated-boy-d',
    templateUrl: './graduated-boy-d.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconGraduatedBoyDComponent implements IconComponent {

}
