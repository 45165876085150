import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-currency-picker',
    templateUrl: './currency-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrencyPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly currencies = toSignal(this.#sharedService.getCurrencies(), { initialValue: [] });
  readonly name = input.required<string>();
  readonly label = input.required<string>();
  readonly currencyCode = input.required<FormControl<string>>();
  readonly disabled = input(false);
  readonly labelClass = input<string | undefined>(undefined);

}
