import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Button, ProfileService } from 'ui-common-lib';

import { StudentStrength } from '../../../models';
import { StudentService } from '../../../services';
import { StudentStrengthHistoryComponent } from './student-strength-history.component';

@Component({
    selector: 'kip-student-strengths',
    templateUrl: './student-strength.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentStrengthComponent implements OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #studentService = inject(StudentService);

  #buttons: Button[] = [];
  #studentId: number | undefined;
  #editing = false;
  #studentStrengths: StudentStrength[] = [];
  #studentStrengthToEdit: StudentStrength | undefined;
  #canEdit = false;
  #subscriptions: Subscription[] = [];

  historyEnabled = false;

  get buttons() {
    return this.#buttons;
  }

  get canEdit() {
    return this.#canEdit;
  }

  get studentStrengths() {
    return this.#studentStrengths;
  }

  get studentStrengthToEdit() {
    return this.#studentStrengthToEdit;
  }

  get hasResults() {
    return this.#studentStrengths && this.#studentStrengths.length > 0;
  }

  get editing() {
    return this.#editing;
  }

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#init();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @ViewChild(StudentStrengthHistoryComponent)
  readonly studentStrengthHistoryComponent: StudentStrengthHistoryComponent | undefined;

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  add() {
    this.#editing = true;
  }

  edit(studentStrength: StudentStrength) {
    if (this.canEdit) {
      this.#editing = true;
      this.#studentStrengthToEdit = studentStrength;
    }
  }

  cancelled() {
    this.#studentStrengthToEdit = undefined;
    this.#editing = false;
  }

  submitted() {
    this.#init();
  }

  strengthCompleted() {
    if (this.studentStrengthHistoryComponent) {
      this.studentStrengthHistoryComponent.load();
    }
  }

  #init() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        const buttons: Button[] = [];
        if (value) {
          buttons.push({ text: 'Set a Strength', action: () => { this.add(); } });
        }
        this.#buttons = buttons;
        this.#changeDetectorRef.markForCheck();
      }));
    this.#studentStrengthToEdit = undefined;
    this.#editing = false;
    if (this.studentId) {
      this.#subscriptions.push(
        this.#studentService.getStrengthsByStudentId(this.studentId).subscribe(
          strengths => {
            this.#studentStrengths = strengths;
            this.#changeDetectorRef.markForCheck();
          }));
    } else {
      this.#studentStrengths = [];
    }
  }
}
