import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Proficiency, Subject } from '../models';

@Component({
    selector: 'kip-tutor-drop-in-session-settings',
    templateUrl: './tutor-drop-in-session-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TutorDropInSessionSettingsComponent {

  @Input({ required: true }) allSubjects: Subject[] | undefined;
  @Input({ required: true }) allProficiencies: Proficiency[] | undefined;
  @Input({ required: true }) dropInSessionDescription: FormControl<string | undefined> | undefined;
  @Input({ required: true }) dropInSessionSubjects: FormControl<Subject[] | undefined> | undefined;
  @Input({ required: true }) dropInSessionProficiencies: FormControl<Proficiency[] | undefined> | undefined;
}
