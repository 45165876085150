import { Pipe, PipeTransform } from '@angular/core';

import { ageResult } from '../helpers';
import { Age, AgeResult, NullableAge } from '../models';

@Pipe({
    name: 'ageResult',
    standalone: false
})
export class AgeResultPipe implements PipeTransform {
  transform(ageResultValue?: AgeResult | null, age?: NullableAge, studentAge?: Age | null, short = false): string {
    return ageResult(ageResultValue, age, studentAge, short);
  }
}
