import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-bundle-offer-type-picker',
    templateUrl: './bundle-offer-type-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BundleOfferTypePickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly bundleOfferTypes = toSignal(this.#sharedService.getBundleOfferTypes(), { initialValue: [] });
  readonly name = input.required<string>();
  readonly label = input.required<string>();
  readonly bundleOfferTypeId = input.required<FormControl<number>>();
  readonly disabled = input(false);
  readonly labelClass = input<string | undefined>(undefined);

}
