import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { UserName } from '../models';
import { AvatarService } from '../services';

@Component({
    selector: 'kip-avatar',
    templateUrl: './avatar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AvatarComponent implements OnDestroy {

  readonly #avatarService = inject(AvatarService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #text = '';
  #userId: number | undefined;
  #userName: UserName | undefined;
  #myAvatar = false;
  #avatarAvailable = false;
  #avatarSubscription: Subscription | undefined;

  readonly icons = Icons;
  isLoading = true;

  get avatarAvailable() {
    return this.#avatarAvailable;
  }

  get empty(): boolean {
    return !this.#text;
  }

  get initial(): string | undefined {
    if (this.#text) {
      const letter = this.#text[0];
      return letter.toUpperCase();
    }

    return undefined;
  }

  @Input() size = 24;

  @Input()
  set name(value: UserName | undefined) {
    this.#userName = value;
    if (value) {
      this.#text = value.givenName;
    }
  }

  get name() {
    return this.#userName;
  }

  @Input()
  set userId(value: number | undefined) {
    if (this.#userId !== value) {
      if (value) {
        this.#avatarService.getAvatar(value).then(url => {
          this.#avatarAvailable = true;
          if (this.img) {
            this.img.nativeElement.src = url;
          }
          this.#changeDetectorRef.markForCheck();
        });
      }
      this.#userId = value;
      this.#changeDetectorRef.markForCheck();
    }
  }

  get userId() {
    return this.#userId;
  }

  @Input() avatarSize = 30;

  @Input()
  set myAvatar(value: boolean) {
    if (this.#myAvatar !== value) {
      if (this.#avatarSubscription) {
        this.#avatarSubscription.unsubscribe();
      }
      this.#avatarSubscription = this.#avatarService.trackMyAvatar().subscribe(avatarUrl => {
        if (this.img) {
          this.img.nativeElement.src = avatarUrl ?? '';
        }
        this.#avatarAvailable = true;
        this.#changeDetectorRef.markForCheck();
      });
      this.#myAvatar = value;
      this.isLoading = false;
      this.#changeDetectorRef.markForCheck();
    }
  }

  get myAvatar() {
    return this.#myAvatar;
  }

  @ViewChild('img', { static: true }) img: ElementRef<HTMLImageElement> | undefined;

  ngOnDestroy() {
    if (this.#avatarSubscription) {
      this.#avatarSubscription.unsubscribe();
      this.#avatarSubscription = undefined;
    }
  }
}
