@if (studentDetail) {
<div class="kip-card kip-card--summary p-0">
  <div class="kip-tabs-container kip-card__body">
    <ul #nav="ngbNav" class="nav-fill" ngbNav [activeId]="initialTab" (navChange)="onTabChange($event)">
      <li [ngbNavItem]="tabNames.LessonHistory">
        <a ngbNavLink>
          {{'Lesson History' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-lesson-history [isLoadingHistory]="loadingHistory" [showLoadMore]="showLoadMore"
                [studentId]="studentId ?? 0" [studentLessonHistory]="lessonHistory"
                (loadMoreLessons)="loadMoreHistory()" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Contacts">
        <a ngbNavLink>
          {{'Contacts' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-contact [studentDetail]="studentDetail" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Enrolments">
        <a ngbNavLink>
          {{'Enrolments' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-enrolment [studentDetail]="studentDetail" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Assessments">
        <a ngbNavLink>
          {{'Assessments' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-assessment [gradeId]="studentGradeId" [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Notes">
        <a ngbNavLink>
          {{'Notes' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-note [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Goals">
        <a ngbNavLink>
          {{'Goals' | uppercase }}
          @if (goalCount > 0) {
          <span class="kip-badge kip-badge--primary ms-2 text-uppercase">{{goalCount}}</span>
          }
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-goal [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Strengths">
        <a ngbNavLink>
          {{'Strengths' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-strengths [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Weaknesses">
        <a ngbNavLink>
          {{'Weaknesses' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-weakness [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Awards">
        <a ngbNavLink>
          {{'Awards' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-awards [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Trees">
        <a ngbNavLink>
          {{'Trees' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-tree-progress [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="tabNames.Settings">
        <a ngbNavLink>
          {{'Settings' | uppercase }}
        </a>
        <ng-template ngbNavContent>
          <div class="row flex-grow-1 g-0">
            <div class="col-12">
              <kip-student-settings [studentId]="studentDetail.id" />
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div class="card-header-tabs p-0 m-0 h-100 mh-100" [ngbNavOutlet]="nav"></div>
  </div>
</div>
}