import { ChangeDetectionStrategy, Component, inject, input, output, TemplateRef, viewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IconDefinition, Icons } from 'icon-lib';

@Component({
    selector: 'kip-ok-dialog',
    templateUrl: './ok-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OkDialogComponent {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;
  readonly title = input.required<string>();
  readonly okText = input.required<string>();
  readonly icon = input.required<IconDefinition>();
  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly ok = output();

  /* eslint-disable kip/no-unused-public-members */

  open(size: 'lg' | 'sm' | 'xl' = 'lg') {
    const modalContent = this.modalContent();
    if (modalContent) {
      this.#modalService.open(modalContent, { size: size, centered: true });
    }
  }

  /* eslint-enable kip/no-unused-public-members */

  answerOk() {
    this.ok.emit();
    this.#modalService.dismissAll();
  }
}
