import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noteClass',
    standalone: false
})
export class NoteClassPipe implements PipeTransform {

    transform(internalNote: string | undefined, parentNote: string | undefined, focusNote: string | undefined): string {

        return internalNote || parentNote || focusNote ? 'text-success' : 'text-primary';
    }
}
