import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardPageSize } from 'ui-common-lib';

import { StudentAccount } from '../../../models';

@Component({
    selector: 'kip-student-account-list',
    templateUrl: './student-account-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAccountListComponent {

  readonly pageSize = CardPageSize.Two;

  @Input({ required: true }) accounts: readonly StudentAccount[] = [];

}
