import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormGroupTypeSafe } from 'forms-lib';

import { ResetPassword } from '../models';

@Component({
    selector: 'kip-password-form-partial',
    templateUrl: './password-form-partial.component.html',
    // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class PasswordFormPartialComponent {
  #form!: FormGroupTypeSafe<ResetPassword>;

  showPassword = false;

  get confirmPassword() {
    return this.form.getSafe(x => x.confirmPassword);
  }

  get password() {
    return this.form.getSafe(x => x.password);
  }

  @Input({ required: true })
  set form(value) {
    this.#form = value;

    const validatorFunc = this.#confirmationValidator('password', 'confirmPassword');
    if (!this.#form.hasValidator(validatorFunc)) {
      this.#form.addValidators(validatorFunc);
    }
  }

  get form() {
    return this.#form;
  }

  toggleShowPassword(event: Event) {
    this.showPassword = (event.target as HTMLInputElement).checked;
  }

  #confirmationValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);
      if (
        matchingControl?.errors &&
        !matchingControl?.errors.confirmationValidator
      ) {
        return null;
      }
      if (control?.value !== matchingControl?.value) {
        const validationError = { confirmationValidator: 'The entered password does not match the confirmed password' } as ValidationErrors;
        matchingControl?.setErrors(validationError);
        return validationError;
      } else {
        return null;
      }
    };
  }
}
