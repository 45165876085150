import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-expandable-setting',
    templateUrl: './expandable-setting.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExpandableSettingComponent {

  readonly icons = Icons;
  readonly header = input.required<string>();
  readonly hint = input.required<string>();
  readonly expanded = input.required<boolean>();
  readonly toggled = output();

}
