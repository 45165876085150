import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AcceptedFileType, AcceptedFileTypesModel, UploadedFileModel } from '../models';

@Component({
    selector: 'kip-file-uploader',
    templateUrl: './file-uploader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FileUploaderComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #domSanitizer = inject(DomSanitizer);

  #files: UploadedFileModel[] = [];
  #progress = 0;

  readonly #validFileTypes: AcceptedFileTypesModel[] = [
    { type: AcceptedFileType.Gif, text: '.gif' },
    { type: AcceptedFileType.Png, text: '.png' },
    { type: AcceptedFileType.Jpeg, text: '.jpeg' },
    { type: AcceptedFileType.Bmp, text: '.bmp' }
    // {type: AcceptedFileType.Pdf, text: '.pdf'},
    // {type: AcceptedFileType.MsWord, text: '.doc, .docx'},
  ];

  get files() {
    return this.#files;
  }

  get validFileTypes() {
    return this.#validFileTypes.map(item => item.type).join(', ');
  }

  get validFileTypesText() {
    return this.#validFileTypes.map(item => item.text).join(', ');
  }

  @Input() multiple = false;
  @Input() isFileListActive = true;
  @Input() maxSize = 20; // mb
  @Input() disabled = false;
  @Input() isUploading = false;
  @Input() hasError = false;
  @Input() isUploaded = false;
  @Input() title = 'Click here to browse and select or drag and drop here';
  @Input() classes = '';
  @Input() uploadErrorMessage: string | undefined;

  /* eslint-disable kip/no-unused-public-members */

  @Input() set updateFileList(value: UploadedFileModel[]) {
    if (value) {
      this.#files = value;
    }
  }

  get updateFileList() {
    return this.#files;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input() set progress(value: number | null) {
    this.#progress = value ?? 0;
  }

  get progress() {
    return this.#progress;
  }

  @Output() readonly fileList = new EventEmitter<readonly File[]>();
  @Output() readonly newUploading = new EventEmitter<boolean>();
  @Output() readonly fileModelList = new EventEmitter<UploadedFileModel[]>();

  fileUploadChange(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target.files) {
      this.handleFiles(target.files);
    }
  }

  delete(file: UploadedFileModel) {
    this.#files = this.files.filter(item => item !== file);
    this.emitFileList();
  }

  handleFiles(fileList: FileList) {

    if (!this.multiple || this.isUploaded) {
      this.#files = [];
    }

    if (this.isUploaded) {
      this.newUploading.emit(true);
    }

    // add files that are not already in the list

    let i = this.files.length + 1;
    const files = [...this.files];

    for (const file of fileList) {

      if (!this.files.some(s => s.fileName === file.name)) {
        const isValidFileSize = this.#isValidFileSize(file);
        const isValidFileType = this.#isValidFileType(file);

        const hasError = !isValidFileSize || !isValidFileType;

        const uploadingInfo: string[] = [];

        if (!isValidFileSize) {
          uploadingInfo.push('File too big. 20mb max.');
        }

        if (!isValidFileType) {
          uploadingInfo.push(`File type incorrect. Allowed file types ${this.validFileTypes}.`);
        }

        const newFile: UploadedFileModel = {
          fileName: file.name,
          file: file,
          index: i,
          selected: false,
          progress: 0,
          editMode: false,
          hasError: hasError,
          uploadingInfo: hasError ? `Upload failed.\n${uploadingInfo.join('\n')}` : '',
          size: this.#bytesToSize(file.size)
        };
        files.push(newFile);
        i++;
      }
    }

    // process files

    for (const file of files) {
      this.#previewFile(file);
    }

    this.#files = files;
    this.emitFileList();
  }

  emitFileList() {
    this.fileList.emit(this.files.filter(item => !item.hasError).map(item => {
      const file = item.file;
      const blob = file.slice(0, file.size, file.type);
      return new File([blob], item.fileName, { type: file.type });
    }));

    this.fileModelList.emit(this.files);
  }

  #bytesToSize(bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Number.parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
  }

  #isValidFileSize(file: File) {
    return !(file.size / (1024 * 1024) > this.maxSize);
  }

  #isValidFileType(file: File) {
    return this.validFileTypes.includes(file.type);
  }

  #previewFile(fileData: UploadedFileModel) {
    if (!fileData.src) {
      const reader = new FileReader();
      reader.readAsDataURL(fileData.file);
      reader.onloadend = () => {
        fileData.src = this.#domSanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.#files = [...this.files];
        this.fileModelList.emit(this.#files);
        this.#changeDetectorRef.markForCheck();
      };
    }
  }

}
