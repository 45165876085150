import { Directive, ElementRef, Input } from '@angular/core';

import { getBackgroundColorFromPercentage, getTextColorFromPercentage } from '../helpers';

@Directive({
    selector: '[kipScoringColorFromPercentage]',
    standalone: false
})
export class ScoringColorDirective {

  #percentage: number | undefined;

  constructor(private readonly el: ElementRef<HTMLDivElement>) { }

  @Input('kipScoringColorFromPercentage')
  set percentage(value: number | undefined) {
    if (this.#percentage !== value) {
      this.#percentage = value;
      this.el.nativeElement.style.backgroundColor = getBackgroundColorFromPercentage(value);
      this.el.nativeElement.style.color = getTextColorFromPercentage(value);
    }
  }

  get percentage() {
    return this.#percentage;
  }
}
