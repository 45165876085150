import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-triangle-measurement',
    templateUrl: './triangle-measurement.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconTriangleMeasurementComponent implements IconComponent {

}
