@if (trees.length > 0)
{
<div class="row">
    <div>
        <div class="kip-explore-menu overflow-hidden mt-3">
            @for (tree of trees; track tree) {
            <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                [class.kip-activity--expanded]="tree.expanded" (click)="tree.expanded = !tree.expanded">
                <div class="flex-column text-start">
                    @if (tree.html)
                    {
                    <ng-template #popContentTree>
                        <div [innerHTML]="tree.html | safe: 'html'"></div>
                    </ng-template>
                    <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                        [ngbPopover]="popContentTree" />
                    }
                    <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                            [icon]="tree.expanded ? icons.folder.open : icons.calendar.expand" />
                        {{ tree.regionTag }} ({{ tree.name }}) :
                        Percentage Target {{ tree.percentageTarget }}%
                    </span>
                </div>
                <div class="flex-column text-end">
                    <span class="text-muted small me-2">
                        @if (tree.completion)
                        {
                        {{ tree.completion }} % Complete
                        }
                    </span>
                </div>
            </div>
            @if (tree.expanded) {
            <div class="ms-2">
                @for (stream of tree.streams; track stream) {
                <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                    [class.kip-activity--expanded]="stream.expanded" (click)="stream.expanded = !stream.expanded">
                    <div class="flex-column text-start">
                        @if (stream.html)
                        {
                        <ng-template #popContentStream>
                            <div [innerHTML]="stream.html | safe: 'html'"></div>
                        </ng-template>
                        <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                            [ngbPopover]="popContentStream" />
                        }
                        <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                                [icon]="stream.expanded ? icons.folder.open : icons.calendar.expand" /> {{ stream.name
                            }}</span>
                    </div>
                    <div class="flex-column text-end">
                        <span class="text-muted small me-2">
                            @if (stream.completion)
                            {
                            {{ stream.completion }} % Complete
                            }
                        </span>
                    </div>
                </div>
                @if (stream.expanded) {
                <div class="ms-2">
                    @for (topic of stream.topics; track topic) {
                    <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                        [class.kip-activity--expanded]="topic.expanded"
                        [style.background-color]="topic.percentage | topicColor : tree.percentageTarget">
                        <div class="flex-column text-start">
                            @if (topic.html)
                            {
                            <ng-template #popContentTopic>
                                <div [innerHTML]="topic.html | safe: 'html'"></div>
                            </ng-template>
                            <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                                [ngbPopover]="popContentTopic" />
                            }
                            <span class="me-2">
                                {{ topic.name }}
                            </span>
                        </div>
                        <div class="flex-column text-end">
                            <span class="text-muted small me-2">
                                @if (topic.percentage)
                                {
                                {{ topic.percentage }} %
                                }
                            </span>
                        </div>
                    </div>
                    }
                </div>
                }
                }
            </div>
            }
            }
        </div>
    </div>
</div>
}
@else {
<kip-no-results text="Get tutor to assign one." title="It looks like no trees have been set for this student!"
    [canEdit]="false" />
}