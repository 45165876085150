import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { OrganisationGoalPickerComponent, SelectedGoal } from 'organisation-lib';
import { Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

import { Goal, StudentGoal } from '../../../../models';
import { StudentService } from '../../../../services';

@Component({
    selector: 'kip-goal-edit',
    templateUrl: './goal-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GoalEditComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #isParent = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  goalForm!: FormGroupTypeSafe<Goal>;

  get isParent() {
    return this.#isParent;
  }

  get id() {
    return this.goalForm.getSafe(x => x.id);
  }

  get description() {
    return this.goalForm.getSafe(x => x.description);
  }

  get reviewDate() {
    return this.goalForm.getSafe(x => x.reviewDate);
  }

  get completed() {
    return this.goalForm.getSafe(x => x.completed);
  }

  get action(): string {
    return !this.isNew ? 'Edit' : 'Add';
  }

  get buttonText(): string {
    return !this.isNew ? 'Save Changes' : 'Add Goal';
  }

  get minDate() {
    return moment().toDateStruct();
  }

  get isNew() {
    return this.id.value === 0;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) studentGoal: StudentGoal | undefined;
  @Input({ required: true }) studentId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild(OrganisationGoalPickerComponent)
  readonly organisationGoalPickerComponent: OrganisationGoalPickerComponent | undefined;

  @Output() readonly submitted = new EventEmitter();
  @Output() readonly goalCompleted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  ngOnInit() {

    /*eslint-disable @typescript-eslint/unbound-method */

    this.goalForm = this.#fb.group<Goal>({
      id: new FormControl<number>(0, Validators.required),
      description: new FormControl<string | null>(null, Validators.required),
      reviewDate: new FormControl<DateStruct | null>(null, Validators.required),
      completed: new FormControl<boolean>(false)
    });

    this.#subscriptions.push(
      this.#profileService.getIsParent().subscribe(value => {
        this.#isParent = value;
        this.#changeDetectorRef.markForCheck();
      }));

    /*eslint-enable @typescript-eslint/unbound-method */

    if (this.studentGoal) {
      this.goalForm.patchSafe(this.studentGoal.goal);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  choose() {
    if (this.organisationGoalPickerComponent) {
      this.organisationGoalPickerComponent.open();
    }
  }

  selectGoal(selectedGoal: SelectedGoal) {
    this.description.setValue(selectedGoal.description);
  }

  submit() {
    if (this.goalForm.invalid) {
      markControlsAsTouched(this.goalForm);
      return;
    }
    const goal = this.goalForm.value;
    if (this.studentId) {
      const studentId = this.studentId;
      if (goal.id > 0) {
        this.#subscriptions.push(
          this.#studentService.updateExistingGoal(goal)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (goal.completed) {
                    this.goalCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.goalForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }));
      } else {
        this.#subscriptions.push(
          this.#studentService.postNewGoal(studentId, goal)
            .subscribe(
              {
                next: () => {
                  this.submitted.emit();
                  if (goal.completed) {
                    this.goalCompleted.emit();
                  }
                  this.#changeDetectorRef.markForCheck();
                },
                error: (error: unknown) => {
                  this.goalForm.handleServerErrors(error);
                  this.#changeDetectorRef.markForCheck();
                },
                complete: () => this.#studentService.refreshCacheItem(studentId)
              }
            ));
      }
    }
  }

  delete() {
    const goal = this.goalForm.value;
    if (this.studentId) {
      this.#subscriptions.push(
        this.#studentService.deleteExistingGoal(goal.id)
          .subscribe(
            {
              next: () => {
                this.submitted.emit();
                this.#changeDetectorRef.markForCheck();
              },
              error: (error: unknown) => {
                this.goalForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              },
              complete: () => this.#studentService.refreshCacheItem(this.studentId!)
            }
          ));
    }
  }

}
