import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-superhero-power-c',
    templateUrl: './superhero-power-c.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconSuperheroPowerCComponent implements IconComponent {

}
