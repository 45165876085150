import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

import { StudentAccount } from '../../models';

@Component({
    selector: 'kip-student-account-summary',
    templateUrl: './student-account-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAccountSummaryComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #canViewAccount = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get isSingleAccount() {
    return this.accounts && this.accounts.length === 1;
  }

  get canViewAccount() {
    return this.#canViewAccount;
  }

  @Input({ required: true }) accounts: readonly StudentAccount[] = [];

  ngOnInit() {
    this.#init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #init() {
    this.#subscriptions.push(
      this.#profileService.getCanViewAccount().subscribe(value => {
        this.#canViewAccount = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }
}
