import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { IdleStatus } from '../models';
import { idleLogoutTimeInSeconds, IdleService } from '../services';

@Component({
    selector: 'kip-idle-warning',
    templateUrl: './idle-warning.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IdleWarningComponent<T, T2> {

  readonly #modalService = inject(NgbModal);

  #open = false;
  #idleStatus: IdleStatus = IdleStatus.Active;

  readonly icons = Icons;
  readonly idleLogoutTime = idleLogoutTimeInSeconds;

  @Input({ required: true }) idleService: IdleService<T, T2> | undefined;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set idleStatus(value: IdleStatus) {
    this.#idleStatus = value;
    if (value === IdleStatus.IdleLessonOver || value === IdleStatus.IdleLessonOverLogoutSoon) {
      this.#warnAboutIdle();
    }
  }

  get idleStatus() {
    return this.#idleStatus;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('idleWarning') idleWarning: TemplateRef<any> | undefined;

  close() {
    this.#modalService.dismissAll();
    this.#open = false;
  }

  onDoNotAskAgain(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.checked) {
      this.idleService?.disable();
    } else {
      this.idleService?.enable();
    }
  }

  logout() {
    this.idleService?.logout();
  }

  #warnAboutIdle() {
    if (!this.#open) {
      this.#modalService.open(this.idleWarning, { size: 'lg', centered: true, modalDialogClass: 'kip-modal-themed theme-aurora bg-none' });
      this.#open = true;
    }
  }
}
