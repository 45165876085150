import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Icons } from 'icon-lib';

import { QuestionDigitalClock, QuestionParametersClock, ValidationResult } from '../../models';
import { QuestionLayout } from '../question-layout';

@Component({
    selector: 'kip-digital-clock',
    templateUrl: './digital-clock.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DigitalClockComponent extends QuestionLayout {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #hours = 0;
  #minutes = 0;
  #interval = 1;
  #isValidHours: boolean | undefined;
  #isValidMinutes: boolean | undefined;
  #timer: number | undefined;

  readonly icons = Icons;

  override question: QuestionDigitalClock | undefined;

  get interval(): number {
    return this.#interval;
  }

  get hours(): string {
    return this.#hours < 10 ? `0${this.#hours}` : this.#hours.toString();
  }

  get isValidHours() {
    return this.#isValidHours;
  }

  get minutes(): string {
    return this.#minutes < 10 ? `0${this.#minutes}` : this.#minutes.toString();
  }

  get isValidMinutes() {
    return this.#isValidMinutes;
  }

  get answers(): number[] {
    return [this.#hours, this.#minutes];
  }

  override set validationResults(value: ValidationResult[]) {
    this._validationResults = value;
    if (value.length === 2) {
      this.#isValidHours = value[0] === ValidationResult.Correct;
      this.#isValidMinutes = value[1] === ValidationResult.Correct;
      this.#changeDetectorRef.markForCheck();
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  override initialize() {
    const parameters: QuestionParametersClock = this.question?.parameters ?? {
      hours: {},
      minutes: {}
    };
    this.#hours = parameters.hours.value ?? 0;
    this.#minutes = parameters.minutes.value ?? 0;
    this.#interval = parameters.minutes.interval ?? 1;
    this.displayAnswer();
  }

  updateMinutes(value: number, factor: number) {
    let min = this.#minutes;
    min = min + value * factor;
    if (min >= 60) {
      min = min - 60;
      this.updateHours(1);
    }
    if (min < 0) {
      min = 60 + min;
      this.updateHours(-1);
    }
    this.#minutes = min;
    this.#isValidMinutes = undefined;
    this.sendUpdates();
    this.#changeDetectorRef.markForCheck();
  }

  updateHours(value: number) {
    this.#hours = this.#hours + value;
    if (this.#hours > 23) {
      this.#hours = 0;
    }
    if (this.#hours < 0) {
      this.#hours = 23;
    }
    this.#isValidHours = undefined;
    this.sendUpdates();
    this.#changeDetectorRef.markForCheck();
  }

  incrementHours() {
    this.#timer = window.setInterval(() => {
      this.#hours++;
      this.#hours = this.#hours === 24 ? 0 : this.#hours;
      this.#isValidHours = undefined;
      this.sendUpdates();
      this.#changeDetectorRef.markForCheck();
    }, 300);
  }

  incrementMinutes() {
    this.#timer = window.setInterval(() => {
      this.#minutes++;
      this.#minutes = this.#minutes === 60 ? 0 : this.#minutes;
      if (this.#minutes === 0) {
        this.updateHours(1);
      }
      this.#isValidMinutes = undefined;
      this.sendUpdates();
      this.#changeDetectorRef.markForCheck();
    }, 300);
  }

  decrementHours() {
    this.#timer = window.setInterval(() => {
      this.#hours = this.#hours === 0 ? 24 : this.#hours;
      this.#hours--;
      this.#isValidHours = undefined;
      this.sendUpdates();
      this.#changeDetectorRef.markForCheck();
    }, 300);
  }

  decrementMinutes() {
    this.#timer = window.setInterval(() => {
      this.#minutes = this.#minutes === 0 ? 60 : this.#minutes;
      if (this.#minutes === 60) {
        this.updateHours(-1);
      }
      this.#minutes--;
      this.#isValidMinutes = undefined;
      this.sendUpdates();
      this.#changeDetectorRef.markForCheck();
    }, 300);
  }

  pauseTimer() {
    if (this.#timer) {
      clearInterval(this.#timer);
    }
  }

  displayAnswer() {
    if (this.readonly && this.question) {
      const correctHours = Number.parseInt(this.question.answers[0].values[0].toString(), 10);
      const correctMinutes = Number.parseInt(this.question.answers[1].values[0].toString(), 10);
      if (this.studentAnswers !== undefined && this.studentAnswers.length === 2) {
        this.#hours = Number.parseInt(this.studentAnswers[0].toString(), 10);
        this.#minutes = Number.parseInt(this.studentAnswers[1].toString(), 10);
      } else {
        this.#hours = correctHours;
        this.#minutes = correctMinutes;
      }

      this.#isValidHours = this.#hours === correctHours;
      this.#isValidMinutes = this.#minutes === correctMinutes;
    }
    this.#changeDetectorRef.markForCheck();
  }
}
