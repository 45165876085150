import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconDefinition, Icons } from 'icon-lib';
import { QuestionSpeed } from 'questions-lib';

import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-spelling-speed',
    templateUrl: './form-control-spelling-speed.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlSpellingSpeedComponent extends BaseFormControlComponent {

  readonly control = input.required<FormControl<number>>();
  readonly questionSpeedValues: { value: QuestionSpeed, label: string, icon: IconDefinition }[] = [
    { value: QuestionSpeed.Slow, label: 'Slow', icon: Icons.playSpeed.slow },
    { value: QuestionSpeed.Normal, label: 'Normal', icon: Icons.playSpeed.normal },
    { value: QuestionSpeed.Fast, label: 'Fast', icon: Icons.playSpeed.fast }];
  readonly disabled = input(false);

}
