/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Chart, ChartDataset, ChartOptions, ChartType } from 'chart.js';
// @ts-ignore
import annotationPlugin from 'chartjs-plugin-annotation';
import { convertAgeToNumber, convertAgeToString, convertNumberToAge } from 'pipes-directives-lib';
import { Age, ReadingTest } from 'ui-common-lib';

interface ReadingTestStudentData {
  age: Age;
  words: number;
}

@Component({
    selector: 'kip-reading-assessment-chart',
    templateUrl: './reading-assessment-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReadingAssessmentChartComponent {

  #readingTest: readonly ReadingTest[] = [];
  #readingTestStudentData: ReadingTestStudentData = { age: { year: 0, month: 0 }, words: 0 };

  lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        stacked: false,
        title: {
          display: true,
          text: 'Age'
        }
      },
      y: {
        stacked: false,
        title: {
          display: true,
          text: 'Words'
        }
      }
    },
    plugins: {
      annotation: {
        annotations: [
          {
            type: 'line',
            xMin: '6Y10M',
            xMax: '6Y10M',
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              display: true,
              color: 'orange',
              content: 'Line'
            }
          }
        ]
      },
      legend: {
        position: 'right'
      }
    }
  };
  lineChartLabels: string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  lineChartType: ChartType = 'line';
  lineChartLegend = false;

  lineChartData: ChartDataset[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }
  ];

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set readingTestStudentData(value: ReadingTestStudentData) {
    if (this.#readingTestStudentData !== value) {
      this.#readingTestStudentData = value;
      this.#rebuildChartData();
    }
  }

  get readingTestStudentData() {
    return this.#readingTestStudentData;
  }

  @Input({ required: true }) set readingTest(value: readonly ReadingTest[]) {
    if (this.#readingTest !== value) {
      this.#readingTest = value;
      this.#rebuildChartData();
    }
  }

  get readingTest() {
    return this.#readingTest;
  }

  /* eslint-enable kip/no-unused-public-members */

  #rebuildChartData() {
    Chart.register(annotationPlugin);
    if (this.#readingTest.length > 0) {
      const readingTestWithAges = this.#readingTest.filter(s => s.age !== null);
      const ages = readingTestWithAges.map(s => s.age!);

      const studentAge = convertAgeToNumber(this.#readingTestStudentData.age);
      const studentLabel = convertAgeToString(this.#readingTestStudentData.age, 'Y', 'M');
      const minAge = Math.min(...ages, studentAge);
      const maxAge = Math.max(...ages, studentAge);

      const labels: string[] = [];
      for (let age = minAge; age <= maxAge; age++) {
        labels.push(convertAgeToString(convertNumberToAge(age), 'Y', 'M'));
      }

      const line: { x: string, y: number }[] = readingTestWithAges.map(a => {
        let label = '';
        if (a.age) {
          label = convertAgeToString(convertNumberToAge(a.age), 'Y', 'M');
        }
        return { x: label, y: a.wordsMin };
      });

      this.lineChartOptions = {
        responsive: true,
        scales: {
          x: {
            stacked: false,
            type: 'category',
            title: {
              display: true,
              text: 'Age'
            }
          },
          y: {
            stacked: false,
            title: {
              display: true,
              text: 'Words'
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: 'line',
                xMin: studentLabel,
                xMax: studentLabel,
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                  display: true,
                  color: 'orange',
                  content: 'Student'
                }
              },
              {
                type: 'line',
                yMin: this.#readingTestStudentData.words,
                yMax: this.#readingTestStudentData.words,
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                  display: false,
                  color: 'orange',
                  content: 'Student'
                }
              }
            ]
          }
        }
      };

      this.lineChartLabels = labels;
      this.lineChartData = [
        {
          data: line as any, label: 'Standard Words', fill: false, pointRadius: 0
        }
      ];
    }
  }
}
