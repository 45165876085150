import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentage',
    standalone: false
})
export class PercentagePipe implements PipeTransform {

  transform(value: number | null | undefined, dashForZero = false): string {
    return value === 0 && dashForZero ? '-%' : `${Math.round(value ?? 0)}%`;
  }
}
