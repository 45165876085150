import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Icon, SkinTone } from '../icons';

interface AwardMessage {
  readonly text?: string;
  readonly icon?: Icon;
  readonly label?: string;
}

@Component({
    selector: 'kip-awards-message',
    templateUrl: './awards-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AwardsMessageComponent {

  #message = '';
  #skinTone = SkinTone.SkinToneA;
  #awardMessage: AwardMessage | undefined;

  get awardMessage() {
    return this.#awardMessage;
  }

  get skinTone() {
    return this.#skinTone;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set message(value: string) {
    this.#message = value;

    const awardInsert = value.split(' ').find(x => x.includes('_'));
    if (awardInsert) {
      const label = value.replace(awardInsert, '');
      const icon = Number(awardInsert.split('_')[1]);

      if (!Number.isNaN(icon)) {
        this.#awardMessage = { text: 'Award:', icon: icon, label: label };
      }
      return;
    }

    this.#awardMessage = { text: value };
  }

  get message() {
    return this.#message;
  }

  /* eslint-enable kip/no-unused-public-members */
}
