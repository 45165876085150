import { ChangeDetectionStrategy, Component, inject, input, output, TemplateRef, viewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmDialogComponent {

  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;
  readonly title = input.required<string>();
  readonly yesText = input('Yes');
  readonly noText = input('No');
  readonly yesEnabled = input(true);
  readonly noEnabled = input(true);
  readonly showHeader = input(true);
  readonly icon = input(Icons.information.regular);
  readonly modalContent = viewChild<TemplateRef<any>>('content');
  readonly yes = output();
  readonly no = output();

  /* eslint-disable  kip/no-unused-public-members */

  open(size: 'lg' | 'sm' | 'xl' = 'sm') {
    const modalContent = this.modalContent();
    if (modalContent) {
      this.#modalService.open(modalContent, { size: size, centered: true });
    }
  }

  /* eslint-enable  kip/no-unused-public-members */

  answerYes() {
    this.yes.emit();
    this.#modalService.dismissAll();
  }

  answerNo() {
    this.no.emit();
    this.#modalService.dismissAll();
  }
}
