import { Pipe, PipeTransform } from '@angular/core';
import { LessonType } from 'ui-common-lib';

import { LessonSummary } from '../models';

@Pipe({
    name: 'lessonPlanned',
    standalone: false
})
export class LessonPlannedPipe implements PipeTransform {
  transform(lesson: LessonSummary): boolean {

    return lesson.lessonType === LessonType.HomeMakeUp ? (lesson.isDIYReady ?? false) || (lesson.accepted ?? false) && (lesson.isDIYGenerated ?? false)
      : !!lesson.lessonPlanId && (lesson.accepted ?? false);
  }
}
