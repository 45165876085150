import { Pipe, PipeTransform } from '@angular/core';

import { PaymentMethodTypes } from '../models';

@Pipe({
    name: 'paymentMethod',
    standalone: false
})
export class PaymentMethodPipe implements PipeTransform {

  transform(paymentMethod: PaymentMethodTypes): string {
    switch (paymentMethod) {
      case PaymentMethodTypes.CreditCard:
        return 'Credit Card';
      case PaymentMethodTypes.DirectDebit:
        return 'Direct Debit';
      default:
        return '';
    }
  }
}
