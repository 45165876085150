import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFormControlComponent, FormControlDefinition } from 'ui-common-lib';

@Component({
    selector: 'kip-form-control-emoji',
    templateUrl: './form-control-emoji.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlEmojiComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<string | null>>();

}
