/* eslint-disable spellcheck/spell-checker */

import { ChangeDetectionStrategy, Component, effect, input, OnDestroy, signal } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { serverError } from '../helpers/typed-form';

@Component({
    selector: 'kip-control-error',
    templateUrl: './control-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ControlErrorComponent implements OnDestroy {

  #subscription: Subscription | undefined;

  readonly icons = Icons;
  readonly showIcon = input(false);
  readonly label = input('');
  readonly maxLength = input<number | null>(null);
  readonly minLength = input<number | null>(null);
  readonly range = input('');
  readonly control = input.required<AbstractControl>();
  readonly skipServerError = input(false);
  readonly clientSideErrorMessage = input('');
  readonly errors = signal<string[]>([]);

  constructor() {
    effect(() => {
      const control = this.control();

      if (this.#subscription) {
        this.#subscription.unsubscribe();
      }

      this.#subscription = control.valueChanges.subscribe(() => {
        this.errors.set(this.#recalculateErrors());
      });
    });
  }

  ngOnDestroy() {
    if (this.#subscription) {
      this.#subscription.unsubscribe();
      this.#subscription = undefined;
    }
  }

  #recalculateErrors() {

    const clientSideErrorMessage = this.clientSideErrorMessage();
    const errors: string[] = [];
    const control = this.control();
    const range = this.range();
    const minLength = this.minLength();
    const maxLength = this.maxLength();
    const label = this.label();

    if (this.skipServerError()) {
      return [clientSideErrorMessage];
    }

    if (control) {
      if (control.errors) {
        if (control.hasError(serverError)) {
          return control.getError(serverError) as string[];
        }
        if (!clientSideErrorMessage && label) {
          if (control.errors) {
            if (control.hasError('required')) {
              const minLengthText = minLength !== null ? ` and has a minimum length of ${minLength}` : '';
              const maxLengthText = maxLength !== null ? ` and has a maximum length of ${maxLength}` : '';
              errors.push(`is required${minLengthText}${maxLengthText}`);
            }
            if (control.hasError('min') && range) {
              errors.push(`must be ${range}`);
            }
            if (control.hasError('max') && range) {
              errors.push(`must be ${range}`);
            }
            if (control.hasError('notInteger')) {
              errors.push('must be a whole number (no decimals)');
            }
            if (control.hasError('maxLength')) {
              const maxLengthError = control.errors['maxlength'];
              errors.push(`must have a maximum length of ${maxLengthError.requiredLength}`);
            }
            if (control.hasError('minLength')) {
              const minLengthError = control.errors['minlength'];
              errors.push(`must have a minimum length of ${minLengthError.requiredLength}`);
            }
            if (control.hasError('email') || control.hasError('pattern')) {
              errors.push('does not appear to be valid');
            }
          }
          return [`${label} ${errors.join(' and ')}`];
        }
      }
      return [clientSideErrorMessage];
    }

    return [];
  }
}
