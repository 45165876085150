import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { generateBreadcrumbs, RouteParams, Student, studentsRoute, toName } from 'ui-common-lib';

import { StudentService } from '../services';

@Component({
    selector: 'kip-student-edit-password',
    templateUrl: './student-edit-password.component.html',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentEditPasswordComponent implements OnInit, OnDestroy {

  readonly #location = inject(Location);
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #student: Student | undefined;

  get breadcrumbs() {
    return generateBreadcrumbs(3, [studentsRoute, toName(this.#student), 'Edit Student']);
  }

  get id() {
    return this.#student?.id ?? 0;
  }

  get student() {
    return this.#student;
  }

  ngOnInit() {
    const studentDetailsFromHistory = (this.#location.getState() as any)?.student as Student;

    if (studentDetailsFromHistory) {
      this.#student = studentDetailsFromHistory;
    } else {
      this.#subscriptions.push(
        this.#route.paramMap.subscribe(paramMap => {
          const studentId = +(paramMap.get(RouteParams.StudentId) ?? 0);
          this.#subscriptions.push(
            this.#studentService.getStudentInfoById(studentId)
              .subscribe(studentDetails => {
                this.#student = studentDetails;
                this.#changeDetectorRef.markForCheck();
              }));
        }));
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    this.#router.navigate(['students', this.id.toString()]);
  }

  cancel() {
    this.#router.navigate(['..'], { relativeTo: this.#route });
  }
}
