import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimalSwap',
    standalone: false
})
export class DecimalSwapPipe implements PipeTransform {
  transform(value: number, decimal: string): string {
    let stringValue = value.toString();
    if (stringValue.includes('.')) {
      stringValue = value.toFixed(2);
    }

    return stringValue.replace('.', decimal);
  }
}
