/* eslint-disable @angular-eslint/no-forward-ref */
import { ChangeDetectionStrategy, Component, forwardRef, inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SharedService } from '../../services';
import { BaseMultiple, BaseMultiplePickerComponent } from '../base-multiple-picker/base-multiple-picker.component';

@Component({
    selector: 'kip-currency-multiple-picker',
    templateUrl: '../base-multiple-picker/base-multiple-picker.component.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyMultiplePickerComponent),
            multi: true
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrencyMultiplePickerComponent extends BaseMultiplePickerComponent implements OnInit {

  readonly #sharedService = inject(SharedService);

  ngOnInit() {
    this.subscriptions.push(
      this.#sharedService.getAllCurrencies()
        .subscribe(currencies => {
          this.multipleValues = currencies.map<BaseMultiple>(s => ({
            id: s.id, name: s.name, checked: this.ids.includes(s.id)
          }));
          this.changeDetectorRef.markForCheck();
        }));
  }

}
