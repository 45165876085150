import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ExploreLibModule } from 'explore-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { TopicColorPipe, TopicQuestionsPipe } from './pipes';
import { TreePickerComponent } from './tree-picker/tree-picker.component';
import { TreeProgressComponent } from './tree-progress/tree-progress.component';

@NgModule({
  declarations: [
    TopicColorPipe,
    TopicQuestionsPipe,
    TreePickerComponent,
    TreeProgressComponent
  ],
  imports: [
    CommonModule,
    ExploreLibModule,
    IconLibModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTooltipModule,
    PipesDirectivesLibModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    TopicColorPipe,
    TopicQuestionsPipe,
    TreePickerComponent,
    TreeProgressComponent
  ]
})
export class TreePickerLibModule { }
