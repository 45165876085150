import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthService } from 'auth-lib';

@Component({
    selector: 'kip-forbidden',
    templateUrl: './forbidden.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ForbiddenComponent {

  readonly #authService = inject(AuthService);

  gotoParentPortal() {
    window.location.href = window.location.origin.replace('beta', 'parent');
  }

  reloadPage() {
    window.location.reload();
  }

  logout() {
    this.#authService.logout();
  }

}
