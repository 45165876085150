import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { CertificateNode } from '../models';
import { AwardsService } from '../services';

@Component({
    selector: 'kip-certificate-tree',
    templateUrl: './certificate-tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class CertificateTreeComponent implements OnInit, OnDestroy {

  readonly #awardService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  certificateNode: CertificateNode | undefined;

  @ViewChild('frame', { static: false }) frame: ElementRef<HTMLIFrameElement> | undefined;

  @Output() readonly selectCertificateId = new EventEmitter<number>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#awardService.getCertificates().subscribe(value => {
        if (value) {
          value.expanded = true;
        }
        this.certificateNode = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  select(certificateNode: CertificateNode) {
    if (certificateNode.data) {

      this.#subscriptions.push(
        this.#awardService.getCertificatePdfFile(certificateNode.data.pdfFile).subscribe(
          {
            next: response => {
              if (this.frame) {
                this.frame.nativeElement.src = response.body ? window.URL.createObjectURL(response.body) : '';
              }
              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              alert('Cannot load pdf file');
              this.#changeDetectorRef.markForCheck();
            }
          }));

      this.selectCertificateId.emit(certificateNode.data.id);
    }
  }

}
