import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthService, ConfigId } from 'auth-lib';

@Component({
    selector: 'kip-clever-instant-login-redirect',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CleverInstantLoginRedirectComponent implements OnInit {

  readonly #authService = inject(AuthService);

  ngOnInit() {
    this.#authService.login(ConfigId.Clever);
  }

}
