import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
    standalone: false
})
export class DurationPipe implements PipeTransform {

  transform(value: number | undefined, defaultValue?: string): string {

    // If the value is not numerical, then return the default value

    let returnValue = value;

    if (returnValue === undefined || Number.isNaN(+returnValue)) {
      if (defaultValue) {
        return defaultValue;
      }

      returnValue = 0;
    }

    // Calculate the components
    const totalMinutes = Math.floor(returnValue / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes - hours * 60);
    const seconds = Math.round(returnValue - totalMinutes * 60);

    // Build the output
    let builder = '';

    if (hours > 0) {
      builder += hours < 10 ? `0${hours}h` : `${hours}h`;
    }

    builder += minutes < 10 ? `0${minutes}m` : `${minutes}m`;
    builder += seconds < 10 ? `0${seconds}s` : `${seconds}s`;

    return builder;
  }
}
