import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';
import { commonRegex, Contact } from 'ui-common-lib';

import { StudentContactDetail } from '../../../../models';
import { StudentService } from '../../../../services';

@Component({
    selector: 'kip-student-contact-edit',
    templateUrl: './student-contact-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentContactEditComponent implements OnDestroy, AfterViewInit {

  readonly #studentService = inject(StudentService);
  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];

  contactForm: FormGroupTypeSafe<StudentContactDetail>;
  hasAddress = true;

  get id() {
    return this.contactForm.getSafe(x => x.id);
  }

  get relationshipId() {
    return this.contactForm.getSafe(x => x.relationshipId);
  }

  get contact() {
    return this.contactForm.getSafeForm(x => x.contact);
  }

  get givenName() {
    return this.contact.getSafe(x => x.givenName);
  }

  get familyName() {
    return this.contact.getSafe(x => x.familyName);
  }

  get email() {
    return this.contact.getSafe(x => x.email);
  }

  get phone() {
    return this.contact.getSafe(x => x.phone);
  }

  get isPrimary() {
    return this.contact.getSafe(x => x.isPrimary);
  }

  get isEmergency() {
    return this.contact.getSafe(x => x.isEmergency);
  }

  get action(): string {
    return this.id.value > 0 ? 'Edit' : 'Add';
  }

  get buttonText(): string {
    return this.id.value > 0 ? 'Save Changes' : 'Add Contact';
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) studentContact: StudentContactDetail | undefined;
  @Input({ required: true }) studentId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly submitted = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */
    const contact = this.#fb.group<Contact>({
      id: new FormControl<number>(0, Validators.required),
      givenName: new FormControl<string | null>(null, Validators.required),
      familyName: new FormControl<string | null>(null, Validators.required),
      email: new FormControl<string | null>(null, [Validators.email]),
      phone: new FormControl<string | null>(null, [Validators.required, Validators.pattern(commonRegex.phone)]),
      isPrimary: new FormControl<boolean>(false, [Validators.required]),
      isEmergency: new FormControl<boolean>(false, Validators.required)
    });
    /*eslint-enable @typescript-eslint/unbound-method */

    /*eslint-disable @typescript-eslint/unbound-method */
    this.contactForm = this.#fb.group<StudentContactDetail>({
      id: new FormControl<number>(0, Validators.required),
      contact: contact,
      relationshipId: new FormControl<number | null>(null)
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngAfterViewInit() {
    if (this.studentContact) {
      this.contactForm.patchSafe(this.studentContact);
    }

    this.hasAddress = !!this.studentContact?.contact.address;
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {

    if (this.studentId) {
      const studentContact = this.contactForm.value;

      if (studentContact.id) {
        this.#subscriptions.push(
          this.#studentService.updateExistingContact(this.studentId, studentContact).subscribe(
            {
              next: value => {
                if (value === null) {
                  this.submitted.emit();
                }
              },
              error: (error: unknown) => {
                this.contactForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              }
            }));
      } else {
        this.#subscriptions.push(
          this.#studentService.postNewContact(this.studentId, studentContact).subscribe(
            {
              next: value => {
                if (value === null) {
                  this.submitted.emit();
                }
              },
              error: (error: unknown) => {
                this.contactForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              }
            }));
      }
    }
  }
}
