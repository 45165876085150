import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
    name: 'formHasError',
    pure: false,
    standalone: false
})
export class FormHasErrorPipe implements PipeTransform {

  transform(formGroup: FormGroup, error?: string): boolean {
    return formGroup.hasError(error ?? '') && (formGroup.touched || formGroup.dirty);
  }
}
