import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';

import { AnswerType, QuestionWordPicker, ValidationResult } from '../../models';
import { QuestionLayout } from '../question-layout';

interface Word {
  text: string;
  valid: ValidationResult;
  index: number;
}

@Component({
    selector: 'kip-question-word-picker',
    templateUrl: './word-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WordPickerComponent extends QuestionLayout {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #selectedWordIndices: number[] = [];
  #correctAnswer: AnswerType[] = [];
  #wordSet: Word[] = [];

  showHint = false;

  override question: QuestionWordPicker | undefined;

  get answers(): AnswerType[] {
    return [...this.#selectedWordIndices].sort((a, b) => a - b);
  }

  get paragraphs() {
    const paragraphs: { words: Word[] }[] = [];
    const allWords: Word[] = [];
    let words: Word[] = [];
    if (this.question) {
      for (let index = 0, lastIndex = this.question.parameters.length; index < lastIndex; index++) {
        const word: Word = { text: this.question.parameters[index], valid: ValidationResult.NotKnown, index: index };
        if (this.question.parameters[index] === '\n') {
          paragraphs.push({ words: words });
          words = [];
        } else {
          words.push(word);
          allWords.push(word);
          this.#wordSet = allWords;
        }
      }
      paragraphs.push({ words: words });
    }
    // Highlight words correct/incorrect from the validation results
    if (!this.readonly) {
      for (let index = 0, lastIndex = this.answers.length; index < lastIndex; index++) {
        const wordClass = allWords.find(s => s.index === this.answers[index]);
        const validationResult = this._validationResults[index];
        if (wordClass !== undefined && validationResult !== undefined) {
          wordClass.valid = validationResult;
        }
      }
    } else {
      if (this.question) {
        let isCorrectAnswer: boolean;
        let wordClass: Word[] = [];
        this.#correctAnswer = this.question.answers.map(s => s.values[0]);
        if (this.studentAnswers !== undefined) {
          wordClass = this.#getSelectedWordCSS(this.studentAnswers);
          for (let index = 0, lastIndex = this.studentAnswers.length; index < lastIndex; index++) {
            isCorrectAnswer = this.studentAnswers[index] === this.#correctAnswer[index];
            wordClass[index].valid = isCorrectAnswer ? ValidationResult.Correct : ValidationResult.Incorrect;
          }
        } else {
          // Show the correct answers in the tutor mode..
          wordClass = this.#getSelectedWordCSS(this.#correctAnswer);
          for (let index = 0, lastIndex = wordClass.length; index < lastIndex; index++) {
            wordClass[index].valid = ValidationResult.Correct;
          }
        }
      }
    }
    return paragraphs;
  }

  override set validationResults(validationResults: ValidationResult[]) {
    if (this.question) {
      this._validationResults = validationResults;
      // display hint if all the answers they entered were correct
      // but not everything has been selected correctly
      this.showHint = false;
      if (this.answers.length < this.validationResults.length) {
        let allCorrect = true;
        for (let index = 0, lastIndex = this.answers.length; index < lastIndex; index++) {
          if (this.validationResults[index] !== ValidationResult.Correct) {
            allCorrect = false;
          }
        }

        this.showHint = allCorrect;
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  selected(index: number): boolean {
    return this.#selectedWordIndices.includes(index);
  }

  updateWordIndices(currentIndex: number) {
    if (!this.readonly) {
      // hide the hint as soon as user clicks anything
      this.showHint = false;
      // as soon as user clicks anything and validation results exist
      // remove any incorrect selected word indexes, leaving only the correct ones
      // If user was clicking on incorrect answer, don't reselect it after clearing
      // the incorrect selected word indexes
      let currentIndexWasIncorrect = false;
      if (this._validationResults.length > 0 && this._validationResults.includes(ValidationResult.Incorrect)) {
        const newSelectedWordIndices: number[] = [];
        for (let index = 0, lastIndex = this._validationResults.length; index < lastIndex; index++) {
          if (this._validationResults[index] === ValidationResult.Correct) {
            newSelectedWordIndices.push(this.#selectedWordIndices[index]);
          } else {
            if (this.#selectedWordIndices[index] === currentIndex) {
              currentIndexWasIncorrect = true;
            }
          }
        }
        this.#selectedWordIndices = newSelectedWordIndices;
      }
      // clear the validation results, setting everything back to the selected color
      this._validationResults = [];
      // don't allow user to reselect an incorrect answer after it has been removed from loop above
      if (currentIndexWasIncorrect) {
        return;
      }
      if (!this.#selectedWordIndices.includes(currentIndex)) {
        this.#selectedWordIndices.push(currentIndex);
      } else {
        this.#selectedWordIndices.splice(this.#selectedWordIndices.indexOf(currentIndex), 1);
      }

      this.sendUpdates();
    }
  }

  #getSelectedWordCSS(arr: readonly any[]): Word[] {
    const wordCss: Word[] = [];
    for (let index = 0, lastIndex = arr.length; index < lastIndex; index++) {
      this.#selectedWordIndices.push(arr[index]);
    }
    for (let index = 0, lastIndex = arr.length; index < lastIndex; index++) {
      const wordCssValue = this.#wordSet.find(s => s.index === arr[index]);
      if (wordCssValue) {
        wordCss.push(wordCssValue);
      }
    }
    return wordCss;
  }
}
