import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';

@Pipe({
    name: 'day',
    standalone: false
})
export class DayPipe implements PipeTransform {

  transform(value: DateStruct): string {
    if (value) {
      return moment().set(
        {
          years: value.year,
          months: value.month - 1, // Moment months are 0 to 11, C# and Nb bootstrap 1 to 12
          date: value.day         // so changed the month value to align with C# and bootstrap.
        })
        .format('MMMM DD, YYYY');
    }
    return '';
  }
}
