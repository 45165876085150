import { Injectable } from '@angular/core';
import { HttpService } from 'service-lib';

import { Picker, StudentTreeResult, Tree, TreesFull } from '../models';

@Injectable({ providedIn: 'root' })
export class TreePickerService extends HttpService {

  getTrees(picker: Picker) {
    return this.post<TreesFull>('tree', picker);
  }

  getTreesByIds(treeSubjectIds: number[]) {
    return this.post<Tree[]>('tree/multiple', treeSubjectIds);
  }

  getProgress(studentId: number) {
    return this.post<Tree[]>(`tree/${studentId}/progress`, null);
  }

  getStudentTreeResults(treeIds: number[], studentId: number) {
    return this.post<StudentTreeResult[]>('tree/results', { treeIds: treeIds, studentId: studentId });
  }

}
