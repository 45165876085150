import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'topicColor',
    standalone: false
})
export class TopicColorPipe implements PipeTransform {
  transform(topicPercentage: number | null | undefined, percentageTarget: number): string {

    if (topicPercentage) {
      return topicPercentage >= percentageTarget ? 'lightGreen' : 'lightRed';
    }

    return '';
  }
}
