import { Pipe, PipeTransform } from '@angular/core';

import { getLearningRangeFromPercentage } from '../helpers';
import { LearningRange } from '../models';

@Pipe({
    name: 'percentageResult',
    standalone: false
})
export class PercentageResultPipe implements PipeTransform {

  transform(percentage: number | null | undefined, success: string, warning: string, danger: string, zero: string | undefined = undefined): { readonly [key: string]: boolean } {

    if (percentage === undefined || percentage === null) {
      return {
        [success]: false,
        [warning]: false,
        [danger]: false
      };
    }

    if (percentage === 0 && zero) {
      return {
        [success]: false,
        [warning]: false,
        [danger]: false,
        [zero]: true
      };
    }

    const range = getLearningRangeFromPercentage(percentage);

    return {
      [success]: range === LearningRange.Perfect || range === LearningRange.Excellent,
      [warning]: range === LearningRange.Pass,
      [danger]: range === LearningRange.NeedsImprovement
    };
  }
}
