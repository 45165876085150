import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { determineOptional, FormControlDefinition, Gender } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-gender',
    templateUrl: './form-control-gender.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlGenderComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<Gender | null>>();

  get optional() {
    return determineOptional(this.control());
  }

}
