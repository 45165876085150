import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { OpentokNames, OpentokService } from '../opentok.service';

@Component({
    selector: 'kip-observer',
    templateUrl: './observer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ObserverComponent implements OnDestroy {

  readonly #modalService = inject(NgbModal);
  readonly #opentokService = inject(OpentokService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #sessionId: number | undefined;
  #subscriptions: Subscription[] = [];
  #screenShares: OT.Stream[] = [];
  #audioShares: OT.Stream[] = [];
  #publishing = false;

  readonly icons = Icons;
  readonly publisherName = OpentokNames.ObserverVideo;

  session: OT.Session | undefined;
  audioDevice: string | undefined;
  videoDevice: string | undefined;

  get publishing() {
    return this.#publishing;
  }

  get audioShares() {
    return this.#audioShares;
  }

  get screenShares() {
    return this.#screenShares;
  }

  @Input() modalOptions: NgbModalOptions = { fullscreen: true };

  @Input() set sessionId(value: number | undefined) {
    if (this.#sessionId !== value) {
      this.#sessionId = value;
      if (value) {
        this.#opentokService.initSessionForObserver(value).then(session => {
          this.#opentokService.getCurrentDevices().then(currentDevice => {
            this.audioDevice = currentDevice.audio?.deviceId;
            this.videoDevice = currentDevice.video?.deviceId;
          });
          this.#modalService.open(this.modalContent, this.modalOptions);
          this.session = session.session;
          this.#subscriptions.push(
            this.#opentokService.streams.subscribe(streams => {
              if (this.#publishing && streams.length === 0) {
                this.#publishing = false;
              }
              this.#screenShares = streams.filter(s => s.stream.videoType === 'screen' && s.stream.name === OpentokNames.ObserverScreenShare).map(s => s.stream);
              this.#audioShares = streams.filter(s => s.stream.videoType === 'camera').map(s => s.stream);

              this.#changeDetectorRef.detectChanges();
            }));
          this.#changeDetectorRef.markForCheck();
        });
      } else {
        this.close();
        this.#changeDetectorRef.markForCheck();
      }
    }
  }

  get sessionId() {
    return this.#sessionId;
  }

  @Input() tutorId = 1;
  @Input() videoTitle = 'Video';

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

  @Output() readonly sessionIdChange = new EventEmitter<number | undefined>();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
    this.#opentokService.disconnectSession();
  }

  startTalking() {
    this.#publishing = true;
  }

  stopTalking() {
    this.#publishing = false;
  }

  close() {
    this.sessionId = undefined;
    this.sessionIdChange.emit(undefined);
    this.#modalService.dismissAll();
  }
}
