import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-centres',
    templateUrl: './form-control-centres.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlCentresComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<readonly number[]>>();
  readonly hasMultipleCentres = input.required<boolean>();

}
