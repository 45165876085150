import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-medal-3rd-third',
    templateUrl: './medal-3rd-third.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconMedal3rdThirdComponent implements IconComponent {

}
