/* eslint @typescript-eslint/no-useless-constructor : 0 */

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AnswerType } from '../../../../models';
import { QuestionControl } from '../question-control';

@Component({
    selector: 'kip-generic-text-box',
    templateUrl: './text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextBoxComponent extends QuestionControl implements OnInit {

  #maxLength: number | undefined;
  #width: number | undefined;

  get maxLength() {
    return this.#maxLength;
  }

  get width() {
    return this.#width;
  }

  get values(): AnswerType[] {
    return this.element ? [this.element.nativeElement.value] : [];
  }

  @ViewChild('element', { static: true }) element: ElementRef<HTMLInputElement> | undefined;

  ngOnInit() {
    if (this.parameters) {
      this.#maxLength = this.parameters.maxLength;
      this.#width = this.parameters.width;
    }
  }

  override focus() {
    if (this.element && this.autoFocus) {
      this.element.nativeElement.focus();
    }
  }

  focusAndSelect() {
    if (this.element) {
      this.element.nativeElement.focus();
      this.element.nativeElement.select();
    }
  }
}
