/* eslint-disable @angular-eslint/no-forward-ref */
import { ChangeDetectionStrategy, Component, forwardRef, inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SharedService } from '../../services';
import { BaseMultiple, BaseMultiplePickerComponent } from '../base-multiple-picker/base-multiple-picker.component';

@Component({
    selector: 'kip-access-keys-multiple-picker',
    templateUrl: '../base-multiple-picker/base-multiple-picker.component.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccessKeysMultiplePickerComponent),
            multi: true
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccessKeysMultiplePickerComponent extends BaseMultiplePickerComponent implements OnInit {

  readonly #sharedService = inject(SharedService);

  ngOnInit() {
    this.subscriptions.push(
      this.#sharedService.getAllAccessKeys()
        .subscribe(accessKeys => {
          this.multipleValues = accessKeys.map<BaseMultiple>(s => ({
            id: s.id, name: s.name, subGroups: s.topics, checked: this.ids.includes(s.id)
          }));
          this.changeDetectorRef.markForCheck();
        }));
  }
}
