import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition, Icons } from 'icon-lib';

@Pipe({
    name: 'noteIcon',
    standalone: false
})
export class NoteIconPipe implements PipeTransform {

    transform(internalNote: string | undefined, parentNote: string | undefined, focusNote: string | undefined): IconDefinition {

        return internalNote || parentNote || focusNote ? Icons.note.existing : Icons.note.empty;
    }
}
