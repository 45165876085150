import { Pipe, PipeTransform } from '@angular/core';
import { LessonHistoryStatus } from 'ui-common-lib';

@Pipe({
    name: 'lessonStatusIndicator',
    pure: false,
    standalone: false
})
export class LessonStatusIndicatorPipe implements PipeTransform {
  transform(lessonStatus: LessonHistoryStatus): { [className: string]: boolean } {
    /*eslint-disable @typescript-eslint/naming-convention */

    const classes: { [className: string]: boolean } = {
      'alert-success':
        lessonStatus === LessonHistoryStatus.Attended || lessonStatus === LessonHistoryStatus.Planned,
      'alert-warning': lessonStatus === LessonHistoryStatus.Deferred,
      'alert-danger': lessonStatus === LessonHistoryStatus.Forfeited
    };

    /*eslint-enable @typescript-eslint/naming-convention */

    return classes;
  }
}
