import {
  ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject } from '../models';

@Component({
    selector: 'kip-tutor-drop-in-session-subject-settings',
    templateUrl: './tutor-drop-in-session-subject-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TutorDropInSessionSubjectSettingsComponent {

  showTutorSubjects = false;

  //eslint-disable-next-line kip/no-unused-public-members
  @Input({ required: true }) dropInSessionSubjects: FormControl<Subject[] | undefined> | undefined;
  @Input({ required: true }) allSubjects: Subject[] | undefined;

  @ViewChildren('subjectCheckbox') subjectCheckboxes: QueryList<ElementRef> | undefined;

  setSubject(subject: Subject) {
    if (!this.dropInSessionSubjects?.value) {
      return;
    }

    const subjects = [...this.dropInSessionSubjects.value];

    const subjectIndex = subjects.findIndex(item => item.id === subject.id);

    if (subjectIndex > -1) {
      subjects.splice(subjectIndex, 1);
    }
    else if (subjects.filter(item => item.id === subject.id).length === 0) {
      subjects.push(subject);
    }

    this.dropInSessionSubjects.setValue(subjects);
  }

  openSubjects() {
    this.showTutorSubjects = !this.showTutorSubjects;

    // set the controls to reflect the selected options
    setTimeout(() => {
      if (this.subjectCheckboxes) for (const element of this.subjectCheckboxes) {
        if (!this.dropInSessionSubjects?.value) {
          continue;
        }
        if (this.dropInSessionSubjects.value.some(subject => subject.id === Number.parseInt(element.nativeElement.value, 10))) {
          element.nativeElement.checked = true;
        }
      }
    }, 10);
  }

}
