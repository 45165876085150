import { Pipe, PipeTransform } from '@angular/core';
import { asyncScheduler, Observable, observeOn, of } from 'rxjs';

@Pipe({
    name: 'asyncValue',
    standalone: false
})
export class AsyncValuePipe implements PipeTransform {

  transform(value: any): Observable<any> {
    return of(value).pipe(observeOn(asyncScheduler));
  }
}
