import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import { MarkdownOutput } from 'markdown-lib';
import { Subscription } from 'rxjs';

import { GenericName } from '../../models';
import { ActivityGrouping, AssociationType, GeneratedActivity, LessonPlanActivity, OrganisationActivity, OrganisationActivityFull } from '../models';
import { OrganisationActivityService } from '../services';

enum ActivityType {
  General = 1,
  Pdf = 2,
  Ai = 3
}

@Component({
    selector: 'kip-organisation-activity-edit',
    templateUrl: './organisation-activity-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationActivityEditComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #organisationActivityService = inject(OrganisationActivityService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #defaultStudentId: number | undefined;
  #organisationActivity: OrganisationActivityFull | undefined;
  #subscriptions: Subscription[] = [];

  get #organisationActivityTypeId() {
    return this.organisationActivityForm.getSafe(x => x.organisationActivityTypeId);
  }

  get #html() {
    return this.organisationActivityForm.getSafe(x => x.html);
  }

  readonly activityTypes = ActivityType;
  readonly activityGrouping = ActivityGrouping;
  readonly icons = Icons;

  activityType = ActivityType.General;

  aiQuestionsEnabled = false;
  organisations: readonly GenericName[] = [];
  subjects: readonly GenericName[] = [];
  students: readonly GenericName[] = [];
  centres: readonly GenericName[] = [];
  tutors: readonly GenericName[] = [];
  groups: readonly GenericName[] = [];
  regionIds: readonly number[] = [];
  generatedActivity: GeneratedActivity = { additionalData: '', content: [] };

  get activityId() {
    return this.#organisationActivity?.activityId ?? 0;
  }

  set activityMarkdown(value: string) {
    this.markdown.setValue(value);
  }

  get activityMarkdown() {
    return this.markdown.value;
  }

  set currentGrouping(value: ActivityGrouping) {
    if (this.#organisationActivityTypeId.value !== value) {
      this.#organisationActivityTypeId.setValue(value);

      if (value !== ActivityGrouping.Student) {
        this.studentId.setValue(null);
      }

      if (value !== ActivityGrouping.Subject) {
        this.subjectId.setValue(null);
      }

      if (value !== ActivityGrouping.Tutor) {
        this.tutorId.setValue(null);
      }

      if (value !== ActivityGrouping.Centre) {
        this.centreId.setValue(null);
      }
    }
  }

  get currentGrouping() {
    return this.#organisationActivityTypeId.value;
  }

  get pdfFile() {
    return this.organisationActivity?.pdfFile ?? '';
  }

  get pdfSolutionFile() {
    return this.organisationActivity?.pdfSolutionFile ?? '';
  }

  get isNew() {
    return this.id.value === 0;
  }

  organisationActivityForm: FormGroupTypeSafe<OrganisationActivity>;

  get markdown() {
    return this.organisationActivityForm.getSafe(x => x.markdown);
  }

  get id() {
    return this.organisationActivityForm.getSafe(x => x.id);
  }

  get studentId() {
    return this.organisationActivityForm.getSafe(x => x.studentId);
  }

  get subjectId() {
    return this.organisationActivityForm.getSafe(x => x.subjectId);
  }

  get tutorId() {
    return this.organisationActivityForm.getSafe(x => x.tutorId);
  }

  get centreId() {
    return this.organisationActivityForm.getSafe(x => x.centreId);
  }

  get name() {
    return this.organisationActivityForm.getSafe(x => x.name);
  }

  get description() {
    return this.organisationActivityForm.getSafe(x => x.description);
  }

  get isSimpleScoring() {
    return this.organisationActivityForm.getSafe(x => x.isSimpleScoring);
  }

  get isHidden() {
    return this.organisationActivityForm.getSafe(x => x.isHidden);
  }

  get isHiddenStudent() {
    return this.organisationActivityForm.getSafe(x => x.isHiddenStudent);
  }

  get repeatEachLesson() {
    return this.organisationActivityForm.getSafe(x => x.repeatEachLesson);
  }

  get manualQuestionCount() {
    return this.organisationActivityForm.getSafe(x => x.manualQuestionCount);
  }

  get organisationId() {
    return this.organisationActivityForm.getSafe(x => x.organisationId);
  }

  get warningTimeMinutes() {
    return this.organisationActivityForm.getSafe(x => x.warningTimeMinutes);
  }

  get warningTimeTutorOnly() {
    return this.organisationActivityForm.getSafe(x => x.warningTimeTutorOnly);
  }

  get maxAllowedTimeMinutes() {
    return this.organisationActivityForm.getSafe(x => x.maxAllowedTimeMinutes);
  }

  get saveButtonText(): string {
    if (!this.isNew) {
      return 'Save Changes';
    } else if (this.activityType === ActivityType.Pdf) {
      return 'Save and Continue to File Upload';
    } else if (this.activityType === ActivityType.Ai) {
      return `Save after Question Generation (${this.generatedActivity.content.length})`;
    }
    else {
      return 'Add Activity';
    }
  }

  get submitDisabled() {
    return this.organisationActivityForm.invalid || this.id.value === 0 && this.activityType === ActivityType.Ai && this.generatedActivity.content.length === 0;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input()
  set defaultStudentId(value: number | undefined) {
    if (this.#defaultStudentId !== value) {
      this.#defaultStudentId = value;
      if (value && this.organisationActivityForm) {
        this.#setStudent();
      }
    }
  }

  get defaultStudentId() {
    return this.#defaultStudentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input()
  allowCancel = false;

  @Input()
  allowHidden = true;

  @Input()
  set organisationActivity(value: OrganisationActivityFull | undefined) {
    if (this.#organisationActivity !== value) {
      this.#organisationActivity = value;
      if (value) {
        if (!value.isManual) {
          this.activityType = ActivityType.Ai;
        } else {
          this.activityType = value.pdfFile ? ActivityType.Pdf : ActivityType.General;
        }
        this.organisationActivityForm.patchSafe(value);
      }
    }
  }

  get organisationActivity() {
    return this.#organisationActivity;
  }

  @Output() readonly submitted = new EventEmitter<OrganisationActivityFull>();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */

    this.organisationActivityForm = this.#fb.group<OrganisationActivity>({
      id: new FormControl<number>(0, Validators.required),
      name: new FormControl<string | null>(null, Validators.required),
      organisationActivityTypeId: new FormControl<number>(ActivityGrouping.Generic, Validators.required),
      studentId: new FormControl<number | null>(null, this.#studentRequiredValidator),
      subjectId: new FormControl<number | null>(null, this.#subjectRequiredValidator),
      tutorId: new FormControl<number | null>(null, this.#tutorRequiredValidator),
      centreId: new FormControl<number | null>(null, this.#centreRequiredValidator),
      description: new FormControl<string | null>(null),
      markdown: new FormControl<string>(''),
      html: new FormControl<string>(''),
      isHidden: new FormControl<boolean>(false, Validators.required),
      isHiddenStudent: new FormControl<boolean>(false, Validators.required),
      repeatEachLesson: new FormControl<boolean>(false, Validators.required),
      isSimpleScoring: new FormControl<boolean>(false, Validators.required),
      warningTimeMinutes: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
      warningTimeTutorOnly: new FormControl<boolean>(false),
      maxAllowedTimeMinutes: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
      organisationId: new FormControl<number | null>(null, Validators.required),
      manualQuestionCount: new FormControl<number>(20, [Validators.min(0), Validators.max(100)])
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#organisationActivityService.getOrganisations().subscribe(value => {
        this.aiQuestionsEnabled = value.aiQuestionsEnabled;
        this.organisations = value.organisations;
        this.subjects = value.subjects;
        this.students = value.students;
        this.tutors = value.tutors;
        this.centres = value.centres;
        this.regionIds = value.regionIds;
        this.groups = value.types;
        if (this.organisations.length === 1) {
          this.organisationId.setValue(this.organisations[0].id);
        }
        this.#changeDetectorRef.markForCheck();
      }));

    if (this.#defaultStudentId) {
      this.#setStudent();
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  // eslint-disable-next-line kip/no-unused-public-members
  getSelectedAndReset() {
    const activities: LessonPlanActivity[] = [];
    if (this.#organisationActivity) {
      activities.push({
        activityId: this.#organisationActivity.activityId,
        name: this.#organisationActivity.name,
        description: this.#organisationActivity.description,
        isManual: true,
        associationType: AssociationType.Manual,
        internalNote: ''
      });
      this.#reset();
    }
    return activities;
  }

  markdownChange(change: MarkdownOutput) {
    this.markdown.setValue(change.markdown);
    this.#html.setValue(change.html);
  }

  submit() {
    if (this.organisationActivityForm.invalid) {
      markControlsAsTouched(this.organisationActivityForm);
      this.#scrollToFirstInvalidControl();
      this.#changeDetectorRef.markForCheck();
      return;
    }
    const organisationActivity = this.organisationActivityForm.value;
    if (organisationActivity.id > 0) {
      this.#subscriptions.push(
        this.#organisationActivityService.updateActivity(organisationActivity.id, organisationActivity)
          .subscribe(
            {
              next:
                value => {
                  this.submitted.emit(value);
                  this.#changeDetectorRef.markForCheck();
                },
              error: (error: unknown) => {
                this.organisationActivityForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              }
            }));

      if (this.allowCancel) {
        this.cancelled.emit();
      }
    } else {
      const activityType = this.activityType;
      this.#subscriptions.push(
        this.#organisationActivityService.newActivity(organisationActivity)
          .subscribe(
            {
              next:
                value => {
                  if (activityType === ActivityType.Ai) {
                    this.#subscriptions.push(this.#organisationActivityService.addQuestions(value.activityId, this.generatedActivity).subscribe(() => {
                      this.organisationActivity = value;
                      this.organisationActivity.additionalData = this.generatedActivity.additionalData;
                      this.activityType = activityType;
                      this.submitted.emit(value);
                      this.#changeDetectorRef.markForCheck();
                    }));
                  } else {
                    this.organisationActivity = value;
                    this.activityType = activityType;
                    this.submitted.emit(value);
                    this.#changeDetectorRef.markForCheck();
                  }
                },
              error: (error: unknown) => {
                this.organisationActivityForm.handleServerErrors(error);
                this.#changeDetectorRef.markForCheck();
              }
            }));
    }

  }

  skipUpload() {
    const element = document.querySelector('#kip-lesson-planner-tab-container') ?? window;
    element.scrollTo(0, 0);
    this.#reset();
  }

  readonly #studentRequiredValidator: ValidatorFn = (formControl: AbstractControl) => {
    if (this.organisationActivityForm && this.#organisationActivityTypeId.value === ActivityGrouping.Student) {
      return formControl.value !== null ? null : { required: true };
    }
    return null;
  };

  readonly #subjectRequiredValidator: ValidatorFn = (formControl: AbstractControl) => {
    if (this.organisationActivityForm && this.#organisationActivityTypeId.value === ActivityGrouping.Subject) {
      return formControl.value !== null ? null : { required: true };
    }
    return null;
  };

  readonly #tutorRequiredValidator: ValidatorFn = (formControl: AbstractControl) => {
    if (this.organisationActivityForm && this.#organisationActivityTypeId.value === ActivityGrouping.Tutor) {
      return formControl.value !== null ? null : { required: true };
    }
    return null;
  };

  readonly #centreRequiredValidator: ValidatorFn = (formControl: AbstractControl) => {
    if (this.organisationActivityForm && this.#organisationActivityTypeId.value === ActivityGrouping.Centre) {
      return formControl.value !== null ? null : { required: true };
    }
    return null;
  };

  #setStudent() {
    this.studentId.setValue(this.#defaultStudentId ?? null);
    this.centreId.setValue(null);
    this.tutorId.setValue(null);
    this.subjectId.setValue(null);
    this.#organisationActivityTypeId.setValue(ActivityGrouping.Student);
  }

  #reset() {
    this.#organisationActivity = undefined;
    this.id.setValue(0);
    this.name.setValue('');
    this.description.setValue('');
    this.markdown.setValue('');
    this.manualQuestionCount.setValue(20);
    this.isHidden.setValue(false);
    this.isSimpleScoring.setValue(false);
  }

  #scrollToFirstInvalidControl() {
    const form = document.querySelector('#customActivityForm');
    const invalidControls = form!.querySelectorAll('.ng-invalid');
    if (invalidControls.length > 0) {
      const firstInvalidControl = invalidControls[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
    }
  }

}
