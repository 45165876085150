import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Icons } from 'icon-lib';

import { Breadcrumb } from '../models';

@Component({
    selector: 'kip-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BreadcrumbsComponent {

  readonly icons = Icons;
  readonly breadcrumbs = input<Breadcrumb[]>([]);

}
