import { ChangeDetectionStrategy, Component } from '@angular/core';

import { WhiteboardIconBase } from './whiteboard-icon-base';

@Component({
    selector: 'kip-whiteboard-icon-pen',
    templateUrl: './pen.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WhiteboardIconPenComponent extends WhiteboardIconBase {

}
