import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { Contact } from '../models';

@Component({
    selector: 'kip-contact-card',
    templateUrl: './contact-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactCardComponent {

  readonly contact = input.required<Contact | undefined>();
  readonly relationship = input<string | undefined>();
  readonly canEdit = input(true);

  readonly isPrimaryOrEmergency = computed(() => {
    const contact = this.contact();
    if (contact) {
      return contact.isPrimary || contact.isEmergency;
    }

    return false;
  });

  readonly edit = output<Contact>();

}
