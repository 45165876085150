import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { StudentAwardSummary } from '../../models';
import { PointsData } from '../points-data';
import { pointsLevel } from '../points-level';
import { PointsTarget } from '../points-target';

interface PointsDataWithStudents extends PointsData {
  students?: StudentAwardSummary[];
}

@Component({
    selector: 'kip-points-list',
    templateUrl: './points-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PointsListComponent implements OnInit {

  #students250: StudentAwardSummary[] = [];
  #students500: StudentAwardSummary[] = [];
  #students1000: StudentAwardSummary[] = [];

  #students: StudentAwardSummary[] = [];
  #pointsList: readonly PointsDataWithStudents[] = [];

  get students250() {
    return this.#students250;
  }

  get students500() {
    return this.#students500;
  }

  get students1000() {
    return this.#students1000;
  }

  get pointsList() {
    return this.#pointsList;
  }

  @Input({ required: true }) set students(students: StudentAwardSummary[]) {
    if (this.#students !== students) {
      this.#students = students;
      this.#rebuildPoints();
      this.#matchStudents();
    }
  }

  get students() {
    return this.#students;
  }

  ngOnInit() {
    this.#rebuildPoints();
  }

  #rebuildPoints() {
    const pointsList: PointsDataWithStudents[] = [];
    for (const value of this.#enumKeys(PointsTarget)) {
      const pointsTarget = PointsTarget[value];
      const pointsData: PointsDataWithStudents = pointsLevel[pointsTarget];
      pointsData.students = [];
      pointsList.push(pointsData);
    }
    this.#pointsList = pointsList;
  }

  #matchStudents() {
    // reset

    this.#students250 = [];
    this.#students500 = [];
    this.#students1000 = [];

    for (const point of this.#pointsList) {
      point.students = [];
    }

    // match up students to points list

    for (const student of this.#students) {
      const points = student.points ?? 0;
      if (points < 250) {
        this.#students250.push(student);
      } else if (points < 500) {
        this.#students500.push(student);
      }
      else if (points < 1000) {
        this.#students1000.push(student);
      }
      for (let index = this.#pointsList.length - 1; index >= 0; index--) {
        const point = this.#pointsList[index];
        if (points > point.points) {
          point.students?.push(student);

          break;
        }
      }
    }

    // order by points

    for (const point of this.#pointsList) {
      point.students?.sort((a, b) => {
        if (a.points === b.points) {
          return 0;
        }

        return (a.points ?? 0) > (b.points ?? 0) ? 1 : -1;
      });
    }
  }

  #enumKeys<T1 extends object, T2 extends keyof T1 = keyof T1>(obj: T1): T2[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as T2[];
  }

}
