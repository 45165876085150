import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Question } from '../../models';

@Component({
    selector: 'kip-question-text',
    templateUrl: './question-text-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuestionTextComponent {

  readonly #sanitizer = inject(DomSanitizer);

  get html(): SafeHtml | undefined {
    if (this.question?.text) {
      return this.#sanitizer.bypassSecurityTrustHtml(this.question?.text);
    }
    return undefined;
  }

  @Input({ required: true }) question: Question | undefined;
}
