import 'snapsvg-cjs';

import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject,
  QueryList,
  Renderer2, ViewChild, ViewChildren
} from '@angular/core';
import * as snapsvg from 'snapsvg';

import { AnswerType, IndexedOption, QuestionMatching, QuestionOption, QuestionParametersMatching, ValidationResult } from '../../models';

declare let Snap: any;

import { QuestionLayout } from '../question-layout';

interface PositionInfo {
  x: number;
  y: number;
}

interface OptionInfo {
  option: IndexedOption;
  element: HTMLElement;
  rect: DOMRect;
}

interface MatchInfo {
  source: IndexedOption;
  target: IndexedOption;
  connector: snapsvg.Element;
}

interface MatchedOption extends IndexedOption {
  valid: ValidationResult;
}

@Component({
    selector: 'kip-generic-matching',
    templateUrl: './matching.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MatchingComponent extends QuestionLayout implements AfterViewInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #renderer = inject(Renderer2);

  #paper: snapsvg.Paper | undefined;
  #connector: snapsvg.Element | undefined;
  #source: OptionInfo | undefined;
  #target: OptionInfo | undefined;
  readonly #matches: (MatchInfo | undefined)[] = [];
  readonly #destroyers: (() => void)[] = [];
  #sourceOptions: IndexedOption[] = [];
  #targetOptions: MatchedOption[] = [];

  override question: QuestionMatching | undefined;

  get sourceOptions(): IndexedOption[] {
    return this.#sourceOptions;
  }

  get targetOptions(): MatchedOption[] {
    return this.#targetOptions;
  }

  get answers(): AnswerType[] {
    return this.#matches.map(match => match ? match.target.value : '');
  }

  override set validationResults(validationResults: ValidationResult[]) {
    this._validationResults = validationResults;
    if (this.#targetOptions && this.question && this.#targetOptions.length === validationResults.length) {
      for (let index = 0, lastIndex = validationResults.length; index < lastIndex; index++) {
        const value = this.question.answers[index].values[0];
        const targetOption = this.#targetOptions.find(s => s.value === value);
        if (targetOption) {
          targetOption.valid = validationResults[index];
        }
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  @ViewChild('container', { static: true }) containerElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('connectors', { static: true }) connectorsElement: ElementRef<SVGElement> | undefined;

  @ViewChildren('source') sourceElements: QueryList<ElementRef<HTMLElement>> | undefined;
  @ViewChildren('target') targetElements: QueryList<ElementRef<HTMLElement>> | undefined;

  ngAfterViewInit() {
    if (this.readonly && this.question) {
      let answers: readonly AnswerType[] = [];

      const actualAnswers = this.question.answers.map(s => s.values[0]);
      answers = this.studentAnswers !== undefined ? this.studentAnswers : actualAnswers;

      for (const targetOption of this.#targetOptions) {
        targetOption.valid = ValidationResult.NotKnown;
      }

      if (this.sourceElements && this.targetElements) {
        let index = 0;
        for (const sourceElement of this.sourceElements) {
          const source = sourceElement.nativeElement;
          const answer = answers[index];
          const actualAnswer = actualAnswers[index];
          const targetIndex = this.#targetOptions.findIndex(s => s.value === answer);
          if (targetIndex !== -1) {
            const targetElement = this.targetElements.toArray()[targetIndex];
            const target = targetElement.nativeElement;
            const sourceRect = source.getBoundingClientRect();
            const targetRect = target.getBoundingClientRect();
            this.#startMatch(source, sourceRect.top, sourceRect.right);
            this.#updateConnector(target, targetRect.top, targetRect.left);
            this.#endMatch();
            this.#targetOptions[targetIndex].valid = answer === actualAnswer ? ValidationResult.Correct : ValidationResult.Incorrect;
          }
          index++;
        }
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  override initialize() {

    // Build the parameters for display
    const parameters: QuestionParametersMatching = this.question?.parameters ?? {
      source: [],
      target: []
    };
    this.#sourceOptions = this.#buildOptions(parameters.source);
    this.#targetOptions = this.#buildMatchedOptions(parameters.target);

    // Create the svg canvas to render the connector lines
    // This needs to be available in read only mode for the lines

    if (this.connectorsElement) {
      this.#paper = Snap(this.connectorsElement.nativeElement);
    }

    // In readonly mode ensure the events are not bound
    if (!this.readonly) {

      if (this.containerElement) {
        // Add the event handlers to start a match
        this.#listen(this.containerElement.nativeElement, 'mousedown', (event: MouseEvent) => {
          this.#startMatch(event.target as HTMLElement, event.x, event.y);
        });

        this.#listen(this.containerElement.nativeElement, 'touchstart', (event: TouchEvent) => {
          if (event.changedTouches.length > 0) {
            const touch = event.changedTouches[0];
            this.#startMatch(touch.target as HTMLElement, touch.clientX, touch.clientY);
          }
        });

        // Add the event handlers to track the matching move (so a line is drawn)
        this.#listen(this.containerElement.nativeElement, 'mousemove', (event: MouseEvent) => {
          this.#updateConnector(event.target as HTMLElement, event.x, event.y);
        });

        this.#listen(this.containerElement.nativeElement, 'touchmove', (event: TouchEvent) => {
          if (event.changedTouches.length > 0) {
            const touch = event.changedTouches[0];
            // The event element will be the starting source element, but we need the target
            // Unfortunately, this has to be calculated based on the event coordinates
            const position = this.#resolvePosition(touch.clientX, touch.clientY);
            let element: HTMLElement | undefined;

            if (this.targetElements) {
              element = this.targetElements
                .map(target => target.nativeElement)
                .find(target => this.#comparerFunc(target, position));
            }

            this.#updateConnector(element ?? event.target as HTMLElement, touch.clientX, touch.clientY);
          }
          if (event?.preventDefault) { // not to fail unit tests
            event.preventDefault(); // to fix the issue of connector not following touch point in iPad
          }
        });
      }

      // Add the event handler to end a match
      this.#listen(document, 'mouseup', () => {

        this.#updateAndEndMatch();
      });

      this.#listen(document, 'touchend', () => {
        this.#updateAndEndMatch();
      });
    }
  }

  sourceMatched(option: IndexedOption): boolean {
    return this.#matched(option, this.#source, match => match.source);
  }

  targetMatched(option: IndexedOption): boolean {
    return this.#matched(option, this.#target, match => match.target);
  }

  override destroy() {
    if (!this.readonly) {
      if (this.#paper) {
        this.#paper.remove();
      }
      for (const destroy of this.#destroyers) {
        destroy();
      }
    }
  }

  // Add a comparer function that can be overridden for testing
  // Due to differences in how tests and 'real-time' are rendered, the logic will be different
  /* NO COVERAGE */
  readonly #comparerFunc = (target: HTMLElement, position: PositionInfo) => {
    const rect = target.getBoundingClientRect();
    return position.x >= target.offsetLeft &&
      position.y >= target.offsetTop &&
      position.x <= target.offsetLeft + rect.width &&
      position.y <= target.offsetTop + rect.height;
  };

  #listen(element: Document | HTMLElement, event: string, handler: (event: Event) => void) {
    const destroy = this.#renderer.listen(element, event, handler);
    this.#destroyers.push(destroy);
  }

  #buildMatchedOptions(options: readonly QuestionOption[]): MatchedOption[] {
    return options.map((option, index) => ({
      index: index,
      text: option.text,
      image: option.image,
      value: option.value,
      valid: ValidationResult.NotKnown
    }));
  }

  #buildOptions(options: readonly QuestionOption[]): IndexedOption[] {
    return options.map((option, index) => ({
      index: index,
      text: option.text,
      image: option.image,
      value: option.value
    }));
  }

  #matched(option: IndexedOption, info: OptionInfo | undefined, accessor: (match: MatchInfo) => IndexedOption): boolean {

    // If the source has started a match, then activate it
    // Otherwise check if it is matched
    if (info && info.option.index === option.index) {
      return true;
    }

    return !!this.#findMatch(option, accessor);
  }

  #findOption(element: HTMLElement, elements: QueryList<ElementRef<HTMLElement>> | undefined, options: IndexedOption[]): OptionInfo | undefined {
    if (elements) {
      return elements
        .map((source, index) => ({
          option: options[index],
          element: source.nativeElement,
          rect: source.nativeElement.getBoundingClientRect()
        }))
        .find(info => info.element === element || info.element.contains(element));
    }

    return undefined;
  }

  #findMatch(option: IndexedOption, accessor: (match: MatchInfo) => IndexedOption): MatchInfo | undefined {
    return this.#matches.find(match => {

      // If the match is undefined, then don't match it
      // This is if a match hasn't been selected for a source option yet
      if (match) {
        const current = accessor(match);
        return current && current.index === option.index;
      }

      return false;
    });
  }

  #resolveAnchor(option: OptionInfo, xOffset: number, yOffset: number): PositionInfo {
    return {
      x: option.element.offsetLeft + xOffset,
      y: option.element.offsetTop + option.rect.height / 2 + yOffset
    };
  }

  #resolvePosition(x: number, y: number): PositionInfo {

    // Get the container boundaries to calculate the required position info

    if (this.containerElement) {
      const rect: DOMRect = this.containerElement.nativeElement.getBoundingClientRect();
      return {
        x: x - rect.left,
        y: y - rect.top
      };
    }

    return { x: 0, y: 0 };
  }

  #startMatch(element: HTMLElement, x: number, y: number) {

    // Get the option info for the element that triggered the event
    const info = this.#findOption(element, this.sourceElements, this.sourceOptions);

    if (info) {

      // If a match is already in place for the source, clear it
      const existing = this.#findMatch(info.option, match => match.source);

      if (existing) {

        // First ensure the connector svg line is removed
        existing.connector.remove();

        this.targetOptions[existing.target.index].valid = ValidationResult.NotKnown;

        // Then set the match info to undefined
        this.#matches[existing.source.index] = undefined;
      }

      // Calculate the screen position for the connector based on the active source
      // A factor of 1 is subtracted to ensure the line looks more connected to the option
      const anchor = this.#resolveAnchor(info, info.rect.width - 1, 0);

      if (this.#paper) {
        this.#connector = this.#paper
          .line(anchor.x, anchor.y, anchor.x, anchor.y)
          .attr({
            class: 'kip-connector'
          });
      }

      // Track the start source option, which activates the connector rendering
      this.#source = info;

      // Update the connector end positions
      this.#updateConnector(element, x, y);

      this.#changeDetectorRef.markForCheck();
    }
  }

  #updateConnector(element: HTMLElement, x: number, y: number) {

    // If there is a source element active, then calculate the mouse position
    if (this.#source && this.#connector) {
      const position = this.#resolvePosition(x, y);
      this.#connector.attr({
        x2: position.x,
        y2: position.y
      });

      // Get the target matching the provided element
      this.#target = this.#findOption(element, this.targetElements, this.targetOptions);

      // If matched, snap the connector to the target anchor point
      if (this.#target) {
        const anchor = this.#resolveAnchor(this.#target, 1, 0);
        if (this.#connector) {
          this.#connector.attr({
            x2: anchor.x,
            y2: anchor.y
          });
        }
      }

      this.#changeDetectorRef.markForCheck();
    }
  }

  #endMatch() {

    if (this.#target) {
      (this.#target.option as MatchedOption).valid = ValidationResult.NotKnown;
    }

    // We are only interested in ending a match if a source and target is active
    if (this.#source && this.#target) {

      // A target can only be matched if not already associated to a source
      const existing = this.#findMatch(this.#target.option, match => match.target);

      if (!existing && this.#connector) {

        // Set the matching state for the current source and target selection
        this.#matches[this.#source.option.index] = {
          source: this.#source.option,
          target: this.#target.option,
          connector: this.#connector.clone()
        };
      }
    }

    // Remove the connector element
    if (this.#connector) {
      this.#connector.remove();
    }

    // Clear the cache variables, which will render the connectors appropriately
    this.#source = undefined;
    this.#target = undefined;

    this.#changeDetectorRef.markForCheck();
  }

  #updateAndEndMatch() {
    const hasSource = !!this.#source;
    this.#endMatch();
    if (hasSource) {
      this.sendUpdates();
    }
  }
}
