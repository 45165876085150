/* eslint-disable rxjs/no-ignored-subscription */

import { BehaviorSubject, from, Observable } from 'rxjs';

import { HttpService } from './http.service';

export abstract class HttpCacheService<T1> extends HttpService {
  private readonly cache = new Map<T1, BehaviorSubject<any | undefined>>();

  abstract loadCacheItem(key: T1): Observable<any>;

  patchCacheItem<T2>(key: T1, value: T2) {
    const subject = this.ensureCacheItem<T2>(key, value);
    subject.next(value);
  }

  refreshCacheItem(key: T1) {
    this.fetchCacheItem(key);
  }

  resolveCacheItem<T2>(key: T1): Observable<T2 | undefined> {
    const value = this.cache.get(key);

    if (value === undefined) {
      const subject = this.fetchCacheItem<T2>(key);
      return from(subject);
    } else {
      return from(value);
    }
  }

  private ensureCacheItem<T2>(key: T1, value?: T2): BehaviorSubject<T2 | undefined> {
    let subject: BehaviorSubject<T2 | undefined>;
    const match = this.cache.get(key) !== undefined;

    if (match) {
      subject = this.cache.get(key) as BehaviorSubject<T2 | undefined>;
    } else {
      subject = new BehaviorSubject<T2 | undefined>(value ?? undefined);
      this.cache.set(key, subject);
    }

    return subject;
  }

  private fetchCacheItem<T2>(key: T1): BehaviorSubject<T2 | undefined> {
    const subject = this.ensureCacheItem<T2>(key);
    this.loadCacheItem(key).subscribe(o => subject.next(o));
    return subject;
  }
}
