import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { Button } from '../models';

@Component({
    selector: 'kip-buttons',
    templateUrl: './buttons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ButtonsComponent {

  readonly buttons = input<Button[]>([]);

}
