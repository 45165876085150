import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

import { HelpNodeSelected } from '../models';

@Component({
    selector: 'kip-help-modal',
    templateUrl: './help-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HelpModalComponent {

  readonly #modalService = inject(NgbModal);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #uId = '';
  #helpNodeSelected: HelpNodeSelected | undefined;

  readonly icons = Icons;

  get helpNodeSelected() {
    return this.#helpNodeSelected;
  }

  get selectedUId() {
    if (this.#helpNodeSelected) {
      return this.#helpNodeSelected.uId;
    }

    return '';
  }

  @Input({ required: true }) set uId(value: string) {
    if (this.#uId !== value) {
      this.#uId = value.toLocaleLowerCase();
      setTimeout(() => {
        this.#modalService.open(this.modalContent, { size: 'xl', centered: true });
        this.#changeDetectorRef.markForCheck();
      }, 100);
    }
  }

  get uId() {
    return this.#uId;
  }

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

  @Output() readonly uIdChange = new EventEmitter<string>();

  nodeSelected(helpNodeSelected: HelpNodeSelected) {
    this.#helpNodeSelected = helpNodeSelected;
    this.#changeDetectorRef.markForCheck();
  }

  close() {
    this.#uId = '';
    this.uIdChange.emit('');
    this.#modalService.dismissAll();
  }
}
