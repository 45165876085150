import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';
import { commonRegex, CommonValidators, ConfirmPassword, toName } from 'ui-common-lib';

import { AccountService } from '../../../../account.service';
import { AccountContact, RegistrationResultStatus } from '../../../../models';

interface AccountContactLogin {
  readonly password: string;
  readonly username: string;
}

@Component({
    selector: 'kip-account-contact-edit-login',
    templateUrl: './account-contact-edit-login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountContactEditLoginComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #accountService = inject(AccountService);

  #resultStatus: RegistrationResultStatus | undefined;
  #accountContactToEdit: AccountContact | undefined;
  #subscriptions: Subscription[] = [];

  loginForm: FormGroupTypeSafe<AccountContactLogin & ConfirmPassword>;

  get contactName() {
    return toName(this.#accountContactToEdit?.contact);
  }

  get username() {
    return this.loginForm.getSafe(p => p.username);
  }

  get password() {
    return this.loginForm.getSafe(p => p.password);
  }

  get resultStatus() {
    return this.#resultStatus;
  }

  get resultStatuses() {
    return RegistrationResultStatus;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set accountContactToEdit(value: AccountContact | undefined) {
    this.#accountContactToEdit = value;
    if (value?.contact?.email && this.username.value === null && commonRegex.username.test(value.contact.email)) {
      this.username.setValue(value.contact.email);
    }
  }

  get accountContactToEdit() {
    return this.#accountContactToEdit;
  }

  @Input({ required: true }) accountId: number | undefined;

  /* eslint-enable kip/no-unused-public-members */

  @Input() isInline = false;

  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly submitted = new EventEmitter();

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */

    this.loginForm = this.#fb.group<AccountContactLogin & ConfirmPassword>({
      username: this.#fb.control(null, [Validators.required, Validators.pattern(commonRegex.username)]),
      password: this.#fb.control(null, [Validators.required, Validators.pattern(commonRegex.password), CommonValidators.hasUniqueCharacter]),
      confirmPassword: this.#fb.control(null, [Validators.required])
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  clearResults() {
    this.#resultStatus = undefined;
  }

  submit() {
    if (this.accountId && this.#accountContactToEdit) {
      this.#subscriptions.push(
        this.#accountService.createUserForContact(this.accountId, this.#accountContactToEdit.id, this.username.value, this.password.value).subscribe(
          {
            next: value => {
              this.#resultStatus = value.status;
              if (value.status === RegistrationResultStatus.Succeeded) {
                this.submitted.emit();
              }
              this.#changeDetectorRef.markForCheck();
            },
            error: (error: unknown) => {
              this.loginForm.handleServerErrors(error);
              this.#changeDetectorRef.markForCheck();
            }
          }));
    }
  }
}
