import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-science-whiz-boy-c',
    templateUrl: './science-whiz-boy-c.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconScienceWhizBoyCComponent implements IconComponent {

}
