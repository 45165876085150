import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-medal-2nd-second',
    templateUrl: './medal-2nd-second.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconMedal2ndSecondComponent implements IconComponent {

}
