import { Pipe, PipeTransform } from '@angular/core';

import { computerScore } from '../helpers';
@Pipe({
    name: 'computerScore',
    standalone: false
})
export class ComputerScorePipe implements PipeTransform {

  transform(correct: number | undefined, skipped: number | undefined, attempted: number | undefined, total: number | undefined,
    firstAttemptCorrectQuestions: number | undefined, secondAttemptCorrectQuestions: number | undefined): string {
    return computerScore(correct, skipped, attempted, total, firstAttemptCorrectQuestions, secondAttemptCorrectQuestions);
  }
}
