import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-computer-whiz-boy-e',
    templateUrl: './computer-whiz-boy-e.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconComputerWhizBoyEComponent implements IconComponent {

}
