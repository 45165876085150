import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-centre-picker',
    templateUrl: './centre-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CentrePickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly #centres = toSignal(this.#sharedService.getCentres(), { initialValue: [] });

  readonly centreId = input.required<FormControl<number | null>>();
  readonly organisationIdFilter = input<number | undefined>(undefined);
  readonly label = input('');
  readonly name = input.required<string>();
  readonly labelClass = input<string | undefined>(undefined);

  readonly centres = computed(() => {
    const centres = this.#centres();
    const organisationIdFilter = this.organisationIdFilter();

    return organisationIdFilter ? [...centres.filter(c => c.organisationId === organisationIdFilter)] : [...centres];
  });

  readonly centreChange = output<number>();

}
