import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Icon, SkinTone } from '../icons';

@Component({
    selector: 'kip-skin-tone-picker',
    templateUrl: './skin-tone-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SkinTonePickerComponent {

  readonly icon = Icon.ThumbsUp;
  readonly skinTones = Object.keys(SkinTone).filter(s => !Number.isNaN(Number(s))).map(s => Number.parseInt(s, 10));

  @Input() label = 'Skin tone';
  @Input() name = 'skinTone';
  @Input() disabled = false;

  @Input({ required: true }) skinToneId: FormControl<SkinTone | null | undefined> | undefined;

}
