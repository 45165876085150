/* eslint  @typescript-eslint/naming-convention : 0 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SkinTone } from 'awards-lib';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-emoji-edit',
    templateUrl: './emoji-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EmojiEditComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #edit = false;
  readonly icons = Icons;
  showEmojiPicker = false;

  @Input() skin: SkinTone = SkinTone.SkinToneA;
  @Input() name = 'emojiEdit';
  @Input({ required: true }) formControl: FormControl<string | null> | undefined;

  @Input() maxLength: number | null = null;
  @Input() initFocus = true;
  @Input() label = '';
  @Input() subLabel = '';
  @Input() placeholder = '';
  @Input() allowEmojis = true;

  @Input({ required: true }) set edit(value: boolean) {
    if (this.#edit !== value) {
      this.#edit = value;
      this.#focusInput();
      this.#changeDetectorRef.markForCheck();
    }
  }

  get edit() {
    return this.#edit;
  }

  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement> | undefined;

  @Output() readonly skinChange = new EventEmitter<SkinTone>();
  @Output() readonly enter = new EventEmitter();
  @Output() readonly focus = new EventEmitter();

  ngOnInit() {
    this.#focusInput();
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;

    return false;
  }

  emojiSelect(input: HTMLTextAreaElement, newEmoji: string) {

    const currentText = input.value;
    const currentPosition = input.selectionStart;

    if (this.formControl) {
      this.formControl.setValue(`${currentText.slice(0, currentPosition)}${newEmoji}${currentText.slice(currentPosition)}`);
    }

    this.toggleEmojiPicker();
  }

  #focusInput() {
    setTimeout(() => {
      if (this.input && this.initFocus) {
        this.input?.nativeElement.focus();
      }
    }, 100);
  }
}
