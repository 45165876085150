import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

@Component({
    selector: 'kip-load-more-wrapper',
    templateUrl: './load-more-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadMoreWrapperComponent {

    readonly showLoadMore = input.required<boolean>();
    readonly isLoadingHistory = input.required<boolean>();
    readonly loadMoreLessons = output();
}