import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';
import { Signalify } from 'ui-common-lib';

import { LessonHistoryActivity } from '../../../../models';

@Component({
    selector: 'kip-lesson-history-lesson-detail',
    templateUrl: './lesson-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentLessonHistoryLessonDetailComponent {

  readonly icons = Icons;

  readonly regionId = input(RegionId.Australia);
  readonly plannedActivities = input.required<readonly Signalify<LessonHistoryActivity>[]>();
  readonly showPreview = input.required<boolean>();
  readonly showHtml = output<Signalify<LessonHistoryActivity>>();
  readonly showVideo = output<string>();
  readonly previewActivity = output<Signalify<LessonHistoryActivity>>();
  readonly editActivityNote = output<Signalify<LessonHistoryActivity>>();

}
