import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-document-type-picker',
    templateUrl: './document-type-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DocumentTypePickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly documentTypes = toSignal(this.#sharedService.getDocumentTypes(), { initialValue: [] });
  readonly documentTypeId = input.required<FormControl<number>>();
  readonly label = input.required<string>();
  readonly name = input.required<string>();
  readonly optional = input(false);
  readonly labelClass = input<string | undefined>(undefined);

}
