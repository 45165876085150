import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'topicQuestions',
    standalone: false
})
export class TopicQuestionsPipe implements PipeTransform {
  transform(topicQuestions: Map<number, boolean> | undefined): string {

    if (topicQuestions === undefined || topicQuestions.size === 0) {
      return '';
    }

    const total = topicQuestions.size;
    const correct = [...topicQuestions.values()].filter(value => value).length;

    return ` (${correct}/${total})`;
  }
}
