import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
    selector: 'kip-monty-empty',
    templateUrl: './monty-empty.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class MontyEmptyComponent implements MontyGraphic { }
