import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';
@Component({
    selector: 'kip-monty-loading',
    templateUrl: './monty-loading.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class MontyLoadingComponent implements MontyGraphic { }
