import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
    selector: 'kip-monty-error',
    templateUrl: './monty-error.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class MontyErrorComponent implements MontyGraphic { }
