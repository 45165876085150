import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-timezone',
    templateUrl: './form-control-timezone.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlTimeZoneComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<string | null>>();

}
