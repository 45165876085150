import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
  inject,
  Input, OnChanges, OnDestroy, Output, SimpleChanges
} from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ResultComponent } from 'assessment-lib';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { DateStruct } from 'moment-extensions-lib';
import { convertAgeToNumber } from 'pipes-directives-lib';
import { Subscription } from 'rxjs';
import { AssessmentResult, ComprehensionTest, ReadingTest } from 'ui-common-lib';

import { AssessmentResultTest, InitialLessonPlanResult, NewAssessment } from '../../../models';
import { StudentService } from '../../../services';

@Component({
    selector: 'kip-student-assessment-change',
    templateUrl: './student-assessment-change.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAssessmentChangeComponent implements OnChanges, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #initialLessonPlanResult: InitialLessonPlanResult | undefined;
  #initialLessonPlanWarning = '';
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  readonly atLeastOneResultError = 'atLeastOneResultError';

  assessmentForm: FormGroupTypeSafe<NewAssessment>;
  lastAssessmentResults: readonly AssessmentResult[] = [];

  get initialLessonPlanWarning() {
    return this.#initialLessonPlanWarning;
  }

  get initialLessonPlanResult() {
    return this.#initialLessonPlanResult;
  }

  get assessmentDate() {
    if (this.assessmentForm) {
      return this.assessmentForm.getSafe(s => s.dateTime).value;
    }
    return undefined;
  }

  get gradeId() {
    if (this.assessmentForm) {
      return this.assessmentForm.getSafe(s => s.gradeId).value ?? this.grade;
    }
    return this.grade;
  }

  get assessmentResults() {
    return this.assessmentForm.getSafeArray(s => s.assessmentResults);
  }

  get action() {
    return this.assessmentId > 0 ? 'Edit' : 'Add';
  }

  get buttonText() {
    return this.assessmentId > 0 ? 'Save Changes' : 'Add Results';
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set studentId(value: number) {
    if (this.assessmentForm) {
      this.assessmentForm.getSafe(s => s.studentId).setValue(value);
    }
  }

  get studentId() {
    if (this.assessmentForm) {
      return this.assessmentForm.getSafe(s => s.studentId).value;
    }

    return 0;
  }

  @Input() set lessonId(value: number | undefined) {
    if (this.assessmentForm) {
      this.assessmentForm.getSafe(s => s.lessonId).setValue(value);
    }
  }

  get lessonId() {
    if (this.assessmentForm) {
      return this.assessmentForm.getSafe(s => s.lessonId).value;
    }

    return undefined;
  }

  @Input({ required: true }) set assessmentId(value: number) {
    if (this.assessmentForm) {
      this.assessmentForm.getSafe(s => s.assessmentId).setValue(value);
    }
  }

  get assessmentId() {
    if (this.assessmentForm) {
      return this.assessmentForm.getSafe(s => s.assessmentId).value;
    }

    return 0;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) grade: number | null | undefined;
  @Input({ required: true }) dateOfBirth: DateStruct | null | undefined;
  @Input({ required: true }) readingTest: readonly ReadingTest[] = [];
  @Input({ required: true }) comprehensionTest: readonly ComprehensionTest[] = [];

  @Output() readonly complete = new EventEmitter<number | null>();
  @Output() readonly loaded = new EventEmitter<number>();

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */
    this.assessmentForm = this.#fb.group<NewAssessment>({
      studentId: new FormControl<number>(0, Validators.required),
      assessmentId: new FormControl<number>(0, Validators.required),
      assessmentResults: this.#fb.array([]),
      lessonId: new FormControl<number | null>(null),
      gradeId: new FormControl<number | null | undefined>(null),
      dateTime: new FormControl<DateStruct | null>(null),
      goals: new FormControl<string[] | null | undefined>(null)
    }, {
      validators: [this.#atLeastOneAssessmentResult]
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['assessmentId'] || changes['lessonId']) {
      this.#load();
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onStatusChange() {
    this.#changeDetectorRef.markForCheck();
  }

  cancel() {
    this.complete.emit(null);
  }

  submit() {

    // filter out "null" assessment results
    const dateTime = this.assessmentForm.getSafe(s => s.dateTime);
    if (!dateTime.value) {
      dateTime.setValue(new Date());
    }

    const gradeId = this.assessmentForm.getSafe(s => s.gradeId);
    if (!gradeId.value) {
      gradeId.setValue(this.grade);
    }

    const assessment: NewAssessment = Object.assign({},
      this.assessmentForm.value,
      {
        assessmentResults: this.assessmentForm.value.assessmentResults.filter(s => ResultComponent.isAssessmentResultEntered(s)),
        goals: this.assessmentForm.value.goals ?? []
      });

    const assessmentResultsInitialLessonPlan: AssessmentResultTest[] =
      assessment.assessmentResults.map(s =>
      ({
        assessmentResultTypeId: s.type.id,
        assessmentResultTypeTitle: s.type.title,
        gradeId: s.gradeId,
        percentage: Math.floor(s.total !== 0 ? 100 * s.score / s.total : 0),
        readingAgeResult: s.ageResult,
        age: convertAgeToNumber(s.age)
      })
      );

    this.#subscriptions.push(
      this.#studentService.testInitialLessonPlan(this.studentId, assessmentResultsInitialLessonPlan).subscribe(results => {
        this.#initialLessonPlanResult = results;

        const titlesWithNoMatch = results.assessments.filter(s => !s.match).map(s => s.assessmentResultTypeTitle);

        this.#initialLessonPlanWarning = titlesWithNoMatch.length > 0 ? `No Initial Lesson Plan For ${titlesWithNoMatch}` : '';
        this.#changeDetectorRef.markForCheck();
        this.#subscriptions.push(
          this.#studentService.postAssessment(assessment).subscribe(assessmentId => {
            this.assessmentId = assessmentId;
            if (titlesWithNoMatch.length === 0) {
              this.complete.emit(assessmentId);
            }
            this.#changeDetectorRef.markForCheck();
          }));
      }
      ));
  }

  readonly #atLeastOneAssessmentResult: ValidatorFn = () => {
    if (this.assessmentForm && this.assessmentResults) {

      let isEntered = false;
      for (const control of this.assessmentResults.controls) {
        if (ResultComponent.isEntered(control as FormGroupTypeSafe<AssessmentResult>)) {
          isEntered = true;
        }
      }

      if (!isEntered) {

        return { [this.atLeastOneResultError]: true };
      }
    }

    return null;
  };

  #load() {
    this.lastAssessmentResults = [];
    if (this.assessmentId !== 0 || this.lessonId && this.lessonId !== 0) {
      this.#subscriptions.push(
        this.#studentService.getAssessment(this.assessmentId, this.lessonId).subscribe(assessment => {
          if (assessment) {
            this.assessmentForm.getSafe(s => s.assessmentId).setValue(assessment.assessmentId);
            this.assessmentForm.getSafe(s => s.lessonId).setValue(assessment.lessonId);
            this.assessmentForm.getSafe(s => s.dateTime).setValue(assessment.dateTime);
            this.assessmentForm.getSafe(s => s.gradeId).setValue(assessment.gradeId);
            this.lastAssessmentResults = assessment.assessmentResults;
          }
          this.loaded.emit(assessment ? assessment.assessmentId : 0);
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

}
