import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-running-speed-girl-d',
    templateUrl: './running-speed-girl-d.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconRunningSpeedGirlDComponent implements IconComponent {

}
