import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { determineOptional, FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-relationship',
    templateUrl: './form-control-relationship.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlRelationshipComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | undefined>>();

  get optional() {
    return determineOptional(this.control());
  }

}
