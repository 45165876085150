@if (currentEnrolments) {
<section class="kip-tab">
  <h2 class="kip-tab__header">Current Enrolments</h2>
  <div class="kip-tab__article-list">
    @for (currentEnrolment of currentEnrolments; track currentEnrolment) {
    <div class="kip-tab__article col-12 col-xl-6">
      <div class="h-100 kip-card kip-card-enrolment">
        <div class="kip-card__header">
          <h3 class="fw-light">{{ (currentEnrolment.weekday | dayOfWeek) + ' ' + (currentEnrolment.startTime
            | timeOfDay) }}</h3>
          @if (canEdit) {
          <a class="btn btn-link btn-link--small ms-auto" [routerLink]="['enrolment', currentEnrolment.id]">View</a>
          }
        </div>
        <div class="kip-card__body">
          <div class="row">
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Tutor</div>
              <div class="kip-info__entry">{{ currentEnrolment.tutor | name }}</div>
            </div>
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Subject</div>
              <div class="kip-info__entry">{{ currentEnrolment.subject.title }}<span
                  class="kip-badge kip-badge--primary me-1 text-uppercase">{{ currentEnrolment.category |
                  enrolmentCategory }}</span></div>
            </div>
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Centre</div>
              <div class="kip-info__entry">{{ currentEnrolment.centre.title }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Account</div>
              <div class="kip-info__entry">
                <span>
                  {{currentEnrolment.accounts[0]}}
                </span>
                @if (currentEnrolment.accounts.length > 1) {
                <span class="text-muted">
                  {{'(+' + (currentEnrolment.accounts.length -1 ) + ' more)'}}
                </span>
                }
              </div>
            </div>
            <div class="col-4 kip-info">
              <div class="kip-info__heading">{{ (currentEnrolment.startDate | starts) }}</div>
              <div class="kip-info__entry">{{ currentEnrolment.startDate | dateFromDateStruct }}</div>
            </div>
            @if (currentEnrolment.tree) {
            <div class="col-4 kip-info">
              <div class="kip-info__heading">AI Tree</div>
              <div class="kip-info__entry">{{ currentEnrolment.tree }}/{{ currentEnrolment.grade }}</div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    }
    @if (canEdit) {
    <div class="kip-tab__article">
      <a routerLink="enrolment/add" [state]="{student: studentDetail}">
        <div class="kip-card-link kip-card-enrolment-link">
          <fa-icon class="mb-3" size="3x" [icon]="icons.add.regular" />
          <div class="kip-card-link__text">Add New Enrolment</div>
        </div>
      </a>
    </div>
    }
  </div>
</section>
}
@if (enrolmentsWithNoSchedule && enrolmentsWithNoSchedule.length > 0) {
<section class="kip-tab">
  <h2 class="kip-tab__header">Enrolments With No Schedule</h2>
  <div class="kip-tab__article-list">
    @for (enrolmentWithNoSchedule of enrolmentsWithNoSchedule; track enrolmentsWithNoSchedule) {
    <div class="kip-tab__article col-12 col-xl-6">
      <div class="h-100 kip-card kip-card-enrolment">
        <div class="kip-card__header">
          @if (canEdit) {
          <a class="btn btn-link btn-link--small ms-auto"
            [routerLink]="['enrolment-no-schedule', enrolmentWithNoSchedule.id]">View</a>
          }
        </div>
        <div class="kip-card__body">
          <div class="row">
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Subject</div>
              <div class="kip-info__entry">{{ enrolmentWithNoSchedule.subject }}</div>
            </div>
            <div class="col-4 kip-info">
              <div class="kip-info__heading">Centre</div>
              <div class="kip-info__entry">{{ enrolmentWithNoSchedule.centre }}</div>
            </div>
            @if (enrolmentWithNoSchedule.startDate)
            {
            <div class="col-4 kip-info">
              <div class="kip-info__heading">{{ (enrolmentWithNoSchedule.startDate | starts) }}</div>
              <div class="kip-info__entry">{{ enrolmentWithNoSchedule.startDate | dateFromDateStruct }}</div>
            </div>
            }
            @if (enrolmentWithNoSchedule.endDate)
            {
            <div class="col-4 kip-info">
              <div class="kip-info__heading">{{ (enrolmentWithNoSchedule.endDate | ends) }}</div>
              <div class="kip-info__entry">{{ enrolmentWithNoSchedule.endDate | dateFromDateStruct }}</div>
            </div>
            }
            @if (enrolmentWithNoSchedule.tree) {
            <div class="col-4 kip-info">
              <div class="kip-info__heading">AI Tree</div>
              <div class="kip-info__entry">{{ enrolmentWithNoSchedule.tree }}/{{ enrolmentWithNoSchedule.grade }}
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</section>
}
<section class="mb-3 px-3">
  <h2 class="kip-tab__header">Previous Enrolments</h2>
  @if (!previousEnrolments) {
  <div class="text-center">Loading ...</div>
  }
  @if (previousEnrolments) {
  <kip-paged-list listType="enrolments" [initialPageSize]="resultsPerPage" [isSearchable]="false"
    [listHeaderTemplate]="tableHeader" [noResultsInSystemTemplate]="noResultsInSystem" [pageLoading]="currentLoad"
    [sortBy]="sortBy" [(filter)]="filter" (pageSelected)="loadPage($event)">
    <div class="table-responsive">
      <table class="kip-table table table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              <kip-paged-filter columnName="Start Date" id="startDateGroupOptions" [sortColumnKey]="sortColumnKey.Date"
                [(sortBy)]="sortBy" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="End Date" id="endDateGroupOptions" [sortColumnKey]="sortColumnKey.EndDate"
                [(sortBy)]="sortBy" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="Day" id="dayGroupOptions" [sortColumnKey]="sortColumnKey.Day"
                [value]="filter.weekday" [values]="filterList.daysOfWeek" [(sortBy)]="sortBy"
                (outputValue)="updateFilter({weekday:$event})" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="Time" id="timeGroupOptions" [sortColumnKey]="sortColumnKey.Time"
                [(sortBy)]="sortBy" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="Tutor" id="tutorGroupOptions" [sortColumnKey]="sortColumnKey.Tutor"
                [value]="filter.tutorId" [values]="filterList.tutors" [(sortBy)]="sortBy"
                (outputValue)="updateFilter({tutorId: $event})" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="Centre" id="centreGroupOptions" [sortColumnKey]="sortColumnKey.Centre"
                [value]="filter.centreId" [values]="filterList.centres" [(sortBy)]="sortBy"
                (outputValue)="updateCentreFilter($event)" />
            </th>
            <th scope="col">
              <kip-paged-filter columnName="Subject" id="subjectGroupOptions" [sortColumnKey]="sortColumnKey.Subject"
                [(sortBy)]="sortBy" />
            </th>
          </tr>
        </thead>
        <tbody>
          @for (enrolment of previousEnrolments.items; track enrolment) {
          <tr class="cursor-pointer">
            <td>{{ enrolment.startDate | dateFromDateStruct }}</td>
            <td>{{ enrolment.endDate | dateFromDateStruct }}</td>
            <td>{{ enrolment.weekday | dayOfWeek }}</td>
            <td>{{ enrolment.startTime | timeOfDay }}</td>
            <td>{{ enrolment.tutor | name }}</td>
            <td>{{ enrolment.centre.title }}</td>
            <td>{{ enrolment.subject.title }}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </kip-paged-list>
  }
</section>
<ng-template #tableHeader />
<ng-template #noResultsInSystem>
  <kip-no-results title="No Previous Sessions" />
</ng-template>