import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';

@Component({
    selector: 'kip-lesson-history-wrapper',
    templateUrl: './lesson-history-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LessonHistoryWrapperComponent {

    readonly hasHistory = input.required<boolean | undefined>();
    readonly isLoadingHistory = input.required<boolean>();
    readonly noResultsText = input.required<string>();
    readonly video = model.required<string>();
}