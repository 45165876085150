import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { StudentGoal } from '../../../models';
import { StudentService } from '../../../services';

@Component({
    selector: 'kip-student-goal-history',
    templateUrl: './student-goal-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentGoalHistoryComponent implements OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #studentService = inject(StudentService);

  #studentId: number | undefined;
  #enabled = false;
  #studentGoals: StudentGoal[] = [];
  #subscriptions: Subscription[] = [];

  get hasResults() {
    return this.#studentGoals.length > 0;
  }

  get studentGoals() {
    return this.#studentGoals;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.load();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true }) set enabled(value: boolean) {
    if (this.#enabled !== value) {
      this.#enabled = value;
      this.enabledChange.emit(value);
      if (value) {
        this.load();
      }
    }
  }

  get enabled() {
    return this.#enabled;
  }

  @Output() readonly enabledChange = new EventEmitter<boolean>();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggle() {
    this.enabled = !this.enabled;
  }

  /* eslint-disable kip/no-unused-public-members */

  load() {
    if (this.#studentId && this.#enabled) {
      this.#subscriptions.push(
        this.#studentService.getGoalHistoryByStudentId(this.#studentId).subscribe(studentGoals => {
          this.#studentGoals = studentGoals;
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  /* eslint-enable kip/no-unused-public-members */

}
