import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';

import { ChildType, ContentNode, PreviewActivity } from '../../models';

@Component({
    selector: 'kip-activity',
    templateUrl: './activity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ActivityComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #resetTime: Date | undefined;

  readonly icons = Icons;

  set expand(expand: boolean) {
    if (this.rootNode) {
      this.rootNode.expanded = expand;
    }
  }

  get expand() {
    if (this.rootNode) {
      return this.rootNode.expanded === undefined ? false : this.rootNode.expanded;
    }
    return false;
  }

  get subNodes() {
    return this.rootNode?.hasChildren ? this.rootNode.children : [];
  }

  get isActivity() {
    if (this.rootNode) {
      return this.rootNode.childType === ChildType.Activity;
    }
    return false;
  }

  @Input({ required: true }) allowPreview = true;

  @Input({ required: true }) singleSelection = false;
  @Input({ required: true }) showStatsAndHistory = true;

  @Input({ required: true }) rootNode: ContentNode | undefined;
  @Input({ required: true }) regionId: number = RegionId.Australia;

  // This is hack to force change detection of selected when we use reset at the parent level

  @Input({ required: true }) set resetTime(value: Date | undefined) {
    this.#resetTime = value;
    this.#changeDetectorRef.markForCheck();
  }

  get resetTime() {
    return this.#resetTime;
  }

  @Output() readonly showPreview = new EventEmitter<PreviewActivity>();

  previewActivity(node: ContentNode, ev: Event) {
    this.showPreview.emit({ id: node.childId ?? 0, name: node.name, description: node.description ?? '' });
    ev.stopPropagation();
  }

  selectActivity(node: ContentNode, ev: Event) {
    if (this.isActivity) {
      if (this.singleSelection) {
        this.previewActivity(node, ev);
      } else {
        if (this.rootNode) {
          this.rootNode.selected = !this.rootNode.selected;
        }
      }
    }
  }
}
