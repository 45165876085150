import { ChangeDetectionStrategy, Component, computed, inject, input, output, signal } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionId } from 'questions-lib';
import { ActivityNote, LessonNote, NotesForLesson, NotesForSession, SessionNote } from 'tutor-notes-lib';
import { HtmlModalComponent, Signalify } from 'ui-common-lib';

import { LessonHistory, LessonHistoryActivity } from '../../../models';

@Component({
    selector: 'kip-student-lesson-history',
    templateUrl: './student-lesson-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentLessonHistoryComponent {

  readonly #modalService = inject(NgbModal);

  readonly previewHistory = signal<Signalify<LessonHistory> | null>(null);
  readonly sessionNoteHistory = signal<Signalify<LessonHistory> | null>(null);
  readonly lessonNoteHistory = signal<Signalify<LessonHistory> | null>(null);
  readonly activityNoteHistory = signal<Signalify<LessonHistoryActivity> | null>(null);
  readonly video = signal('');
  readonly collapse = signal(false);

  readonly hasHistory = computed(() => {
    const lessonHistory = this.studentLessonHistory();
    return lessonHistory && lessonHistory.length > 0;
  });

  readonly sessionNote = computed(() => {
    const sessionNoteHistory = this.sessionNoteHistory();

    if (sessionNoteHistory) {
      const sessionNote: SessionNote = {
        sessionId: sessionNoteHistory.sessionId,
        internalNote: sessionNoteHistory.sessionInternalNote(),
        parentNote: sessionNoteHistory.sessionParentNote(),
        internalNoteRanking: sessionNoteHistory.internalNoteRanking(),
        parentNoteRanking: sessionNoteHistory.parentNoteRanking()
      };

      return sessionNote;
    }

    return null;
  });

  readonly activityNote = computed(() => {
    const activityNoteHistory = this.activityNoteHistory();

    if (activityNoteHistory) {
      const activityNote: ActivityNote = {
        lessonActivityPlanId: activityNoteHistory.id,
        internalNote: activityNoteHistory.internalNote(),
        parentNote: activityNoteHistory.parentNote(),
        activityName: activityNoteHistory.name,
        date: new Date()
      };

      return activityNote;
    }

    return null;

  });

  readonly activityNotes = computed(() => {
    const lessonNoteHistory = this.lessonNoteHistory();
    let activityNotes: ActivityNote[] = [];
    if (lessonNoteHistory) {
      activityNotes = lessonNoteHistory.activities.map(s => {
        const activityNote: ActivityNote = {
          lessonActivityPlanId: s.id,
          internalNote: s.internalNote(),
          parentNote: s.parentNote(),
          activityName: s.name,
          date: new Date()
        };

        return activityNote;
      });
    }

    return activityNotes;
  });

  readonly lessonNote = computed(() => {
    const lessonNoteHistory = this.lessonNoteHistory();

    if (lessonNoteHistory) {
      const lessonNote: LessonNote = {
        lessonId: lessonNoteHistory.lessonId,
        internalNote: lessonNoteHistory.internalNote(),
        aiNote: lessonNoteHistory.aiNote(),
        focusNote: lessonNoteHistory.focusNote(),
        parentNote: lessonNoteHistory.parentNote(),
        internalNoteRanking: lessonNoteHistory.internalNoteRanking(),
        parentNoteRanking: lessonNoteHistory.parentNoteRanking(),
        parentNoteApproved: lessonNoteHistory.parentNoteApproved() ?? false,
        studentName: ''
      };

      return lessonNote;
    }

    return null;
  });

  readonly regionId = input(RegionId.Australia);
  readonly studentId = input.required<number>();
  readonly showLoadMore = input(true);
  readonly studentLessonHistory = input<Signalify<LessonHistory>[] | undefined>(undefined);
  readonly isLoadingHistory = input(false);
  readonly loadMoreLessons = output();

  activityNoteSaved(sessionNote: NotesForSession) {
    const activityNoteHistory = this.activityNoteHistory();
    if (activityNoteHistory) {
      activityNoteHistory.internalNote.set(sessionNote.internalNote);
      activityNoteHistory.parentNote.set(sessionNote.parentNote);
      this.activityNoteHistory.set(null);
    }
  }

  sessionNoteSaved(sessionNote: NotesForSession) {
    const sessionNoteHistory = this.sessionNoteHistory();
    if (sessionNoteHistory) {
      sessionNoteHistory.sessionInternalNote.set(sessionNote.internalNote);
      sessionNoteHistory.sessionParentNote.set(sessionNote.parentNote);
      sessionNoteHistory.sessionInternalNoteRanking.set(sessionNote.internalNoteRanking);
      sessionNoteHistory.sessionParentNoteRanking.set(sessionNote.parentNoteRanking);
      this.sessionNoteHistory.set(null);
    }
    
  }

  lessonNoteSaved(lessonNote: NotesForLesson) {
    const lessonNoteHistory = this.lessonNoteHistory();
    if (lessonNoteHistory) {
      lessonNoteHistory.internalNote.set(lessonNote.internalNote);
      lessonNoteHistory.internalNoteRanking.set(lessonNote.internalNoteRanking);
      lessonNoteHistory.parentNote.set(lessonNote.parentNote);
      lessonNoteHistory.parentNoteRanking.set(lessonNote.parentNoteRanking);
      lessonNoteHistory.parentNoteApproved.set(lessonNote.parentNoteApproved);
      lessonNoteHistory.aiNote.set(lessonNote.aiNote);
      lessonNoteHistory.focusNote.set(lessonNote.focusNote);
      this.lessonNoteHistory.set(null);
    }
  }

  showHtml(activity: Signalify<LessonHistoryActivity>) {
    const modalRef = this.#modalService.open(HtmlModalComponent);
    const componentInstance = modalRef.componentInstance as HtmlModalComponent;
    componentInstance.title = activity.name;
    componentInstance.showPrint = false;
    componentInstance.fixedHeight = false;
    componentInstance.html = activity.html ?? '';
  }
}
