import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AcceptedFileType } from 'ui-common-lib';

import { OrganisationActivityService } from '../services';

@Component({
    selector: 'kip-organisation-activity-pdf',
    templateUrl: './organisation-activity-pdf.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class OrganisationActivityPdfComponent implements AfterViewInit, OnDestroy {

  readonly #organisationActivityService = inject(OrganisationActivityService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #validFileTypes: string[] = [AcceptedFileType.Pdf, AcceptedFileType.Word, AcceptedFileType.WordOpen, AcceptedFileType.MsWord];
  #pdfFile = '';
  #activityId = 0;
  #error = '';
  #subscriptions: Subscription[] = [];

  invalidFileTypePdf = '';

  get id() {
    return this.solution ? 'pdfUploaderFile' : 'pdfUploaderSolutionFile';
  }

  get validFileTypes() {
    return this.#validFileTypes;
  }

  get error() {
    return this.#error;
  }

  @Input({ required: true }) title = '';

  @Input({ required: true }) solution = false;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set activityId(value: number) {
    if (this.#activityId !== value) {
      this.#activityId = value;
      this.pdfFile = '';
    }
  }

  get activityId() {
    return this.#activityId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Input({ required: true })
  set pdfFile(value: string) {
    this.#pdfFile = value;
    this.#loadPdf();
  }

  get pdfFile() {
    return this.#pdfFile;
  }

  @ViewChild('dropAreaPdf', { static: false }) dropAreaPdf: ElementRef<HTMLElement> | undefined;
  @ViewChild('frame', { static: true }) frame: ElementRef<HTMLIFrameElement> | undefined;

  ngAfterViewInit() {

    // Prevent default drag behaviors

    for (const eventName of ['dragenter', 'dragover', 'dragleave', 'drop']) {
      if (this.dropAreaPdf) {
        this.dropAreaPdf.nativeElement.addEventListener(eventName, (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
        }, false);
      }
      document.body.addEventListener(eventName, (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
      }, false);
    }

    // Highlight drop area when item is dragged over it
    for (const eventName of ['dragenter', 'dragover']) {
      if (this.dropAreaPdf) {
        const dropAreaPdf = this.dropAreaPdf;
        dropAreaPdf.nativeElement.addEventListener(eventName, () => {
          dropAreaPdf.nativeElement.classList.add('highlight');
        }, false);
      }
    }

    for (const eventName of ['dragleave', 'drop']) {
      if (this.dropAreaPdf) {
        const dropAreaPdf = this.dropAreaPdf;
        dropAreaPdf.nativeElement.addEventListener(eventName, () => {
          dropAreaPdf.nativeElement.classList.remove('active');
        }, false);
      }
    }

    // Handle dropped files
    if (this.dropAreaPdf) {
      this.dropAreaPdf.nativeElement.addEventListener('drop', (e: DragEvent) => {
        const dt = e.dataTransfer;
        if (dt) {
          const files = dt.files;
          this.#handlePdfFiles(files[0]);
        }
      }, false);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  fileUploadChangePdf(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target.files) {
      this.#handlePdfFiles(target.files[0]);
    }
  }

  clear() {
    if (this.solution) {
      this.#subscriptions.push(
        this.#organisationActivityService.clearPdfSolution(this.#activityId).subscribe(() => {
          this.pdfFile = '';
          this.#changeDetectorRef.markForCheck();
        }));
    } else {
      this.#subscriptions.push(
        this.#organisationActivityService.clearPdf(this.#activityId).subscribe(() => {
          this.pdfFile = '';
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  #handlePdfFiles(file: File) {
    this.invalidFileTypePdf = '';
    this.#error = '';

    if (!this.#validFileTypes.includes(file.type)) {
      this.invalidFileTypePdf = `${file.type} is not an acceptable file type (only ${this.#validFileTypes.join(', ')} allowed)`;
    } else {

      if (file.type === AcceptedFileType.Pdf) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (this.frame) {
            this.frame.nativeElement.src = reader.result as string;
            this.#changeDetectorRef.markForCheck();
          }
        };
      }

      if (this.solution) {
        this.#subscriptions.push(
          this.#organisationActivityService.uploadPdfSolution(file, this.#activityId).subscribe({
            next: value => {
              this.pdfFile = value.destination;
              if (file.type !== AcceptedFileType.Pdf) {
                this.#loadPdf();
              }
              this.#changeDetectorRef.markForCheck();
            },
            error: result => {
              this.#error = result?.error ?? 'An error occurred uploading file - contact support';
              this.#changeDetectorRef.markForCheck();
            }
          }));
      } else {
        this.#subscriptions.push(
          this.#organisationActivityService.uploadPdf(file, this.#activityId).subscribe({
            next: value => {
              this.pdfFile = value.destination;
              if (file.type !== AcceptedFileType.Pdf) {
                this.#loadPdf();
              }
              this.#changeDetectorRef.markForCheck();
            },
            error: result => {
              this.#error = result?.error ?? 'An error occurred uploading file - contact support';
              this.#changeDetectorRef.markForCheck();
            }
          }));
      }
    }
  }

  #loadPdf() {
    if (this.pdfFile) {
      this.#subscriptions.push(
        this.#organisationActivityService.getPdf(this.pdfFile).subscribe({
          next: response => {
            if (this.frame) {
              this.frame.nativeElement.src = response.body ? window.URL.createObjectURL(response.body) : '';
            }
            this.#changeDetectorRef.markForCheck();
          },
          error: () => {
            alert('Cannot load pdf file');
          }
        }));
    } else if (this.frame) {
      this.frame.nativeElement.src = '';
    }
  }

}
