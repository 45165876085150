import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'kip-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProgressIndicatorComponent {

  readonly percentage = input(0);

}
