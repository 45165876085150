import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-expert-subtraction',
    templateUrl: './expert-subtraction.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconExpertSubtractionComponent implements IconComponent {

}
