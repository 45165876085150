import { Pipe, PipeTransform } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';

import { calculateAge } from '../helpers';

@Pipe({
    name: 'age',
    standalone: false
})
export class AgePipe implements PipeTransform {
  transform(date: DateStruct, effectiveDate?: Date | DateStruct | string | null  ): string {
    if (date) {
      const age = calculateAge(date, effectiveDate);

      return `${age.year} years, ${age.month} months`;
    }
    return '';
  }
}
