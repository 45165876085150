import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Icon } from './icon';
import { IconComponent } from './icon.component';
import { iconsList } from './icons-list';
import { SkinTone } from './skin-tone';

@Component({
    selector: 'kip-icon',
    templateUrl: './icon-switch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class IconSwitchComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #displayIcon: Icon | undefined;
  #skinTone: SkinTone = SkinTone.SkinToneD;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set displayIcon(value: Icon | undefined) {
    if (value !== undefined) {
      this.#displayIcon = value;
      this.#rebuildIcon();
    }
  }

  get displayIcon() {
    return this.#displayIcon;
  }

  @Input() set skinTone(value: SkinTone) {
    this.#skinTone = value;
    this.#rebuildIcon();
  }

  get skinTone() {
    return this.#skinTone;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef | undefined;

  ngOnInit() {
    this.#rebuildIcon();
  }

  #rebuildIcon() {
    if (this.content && this.displayIcon !== undefined) {
      const components = iconsList[this.displayIcon];
      this.content.clear();
      if (Array.isArray(components)) {
        try {
          const component = components[this.skinTone];
          this.content.createComponent(component);
        }
        catch {
          throw new Error(`Icon ${this.displayIcon} does not have a component for skin tone ${this.skinTone}`);
        }
      } else {
        this.content.createComponent<IconComponent>(components);
      }
      this.#changeDetectorRef.markForCheck();
    }
  }
}
