import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, model, OnDestroy, output, signal, TemplateRef, untracked, viewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { debounceTime, distinctUntilChanged, fromEvent, map, merge, Observable, Subscription, timer } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { PagedListLoadingState, PageInfo, PageListSize, PageRequest, SortBy } from '../models';

@Component({
    selector: 'kip-paged-list',
    templateUrl: './paged-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagedListComponent<TFilter = void> implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);

  #searchSubscription: Subscription | undefined;
  #loadingSubscription: Subscription | undefined;
  #slowRequestTimerSubscription: Subscription | undefined;
  readonly #loadingState = signal(PagedListLoadingState.Loading);
  readonly #pageSize = signal(PageListSize.TwentyFive);
  readonly #resultsPage = signal<PageInfo | undefined>(undefined);

  readonly icons = Icons;
  readonly pagedListLoadingState = PagedListLoadingState;

  goToPageForm!: FormGroupTypeSafe<{ pageNumber: number }>;

  get goToPageNumber() {
    return this.goToPageForm.getSafe(f => f.pageNumber);
  }

  get errors() {
    const errors = this.goToPageNumber.errors;
    return errors ? errors['pageNumberInvalid'] : '';
  }

  readonly pageLoading = input.required<Observable<PageInfo>>();
  readonly initialPageSize = input.required<PageListSize>();
  readonly searchQuery = signal<string | undefined>(undefined);
  readonly pageSize = this.#pageSize.asReadonly();
  readonly templateLoadingState = this.#loadingState.asReadonly();
  readonly listType = input.required<string>();
  readonly isSearchable = input.required<boolean>();
  readonly listHeaderTemplate = input.required<TemplateRef<any> | null>();
  readonly listOptionButtonTemplate = input<TemplateRef<any> | null>(null);
  readonly noResultsInSystemTemplate = input<TemplateRef<any> | undefined>(undefined);
  readonly filter = model.required<TFilter>();
  readonly sortBy = input.required<SortBy | undefined>();
  readonly searchInput = viewChild<ElementRef<HTMLInputElement>>('searchInput');

  readonly isLoadingComplete = computed(() => {
    return this.#loadingState() === PagedListLoadingState.Complete;
  });

  readonly currentPage = computed(() => {
    const resultsPage = this.#resultsPage();
    if (!resultsPage) {
      return 1;
    }

    return resultsPage.pageNumber;
  });

  readonly pages = computed(() => {
    // We always want to display 5 pages, with the current page in the middle.
    // e.g. with 10 pages
    // Current page = 1 - *1*,2,3,4,5
    // Current page = 6 - 4,5,*6*,7,8
    // Current page = 9 - 6,7,8,*9*,10

    const resultsPage = this.#resultsPage();
    if (!resultsPage) {
      return [];
    }

    const countButtonsEitherSide = 2;
    const maxWindowSizeEitherSide = countButtonsEitherSide * 2;
    const firstPage = 1;
    const lastPage = resultsPage.totalNumberOfPages;
    const currentPage = resultsPage.pageNumber;

    let highestPossibleStartPageToMaintainWindow = lastPage - maxWindowSizeEitherSide;

    if (resultsPage.totalNumberOfPages <= maxWindowSizeEitherSide) {
      highestPossibleStartPageToMaintainWindow = firstPage;
    }

    // Start with our lowest value.
    // Either current page - 2 to the left OR page 1 if this results in a negative number.
    let pageDisplayRangeStart = Math.max(currentPage - countButtonsEitherSide, firstPage);

    if (pageDisplayRangeStart < firstPage) {

      pageDisplayRangeStart = firstPage;

    } else if (pageDisplayRangeStart > highestPossibleStartPageToMaintainWindow) {

      // Now double check that the number we just got
      // Isn't greater than last page with 4 buttons to the left.
      // Remember we ALWAYS want 5 buttons.
      pageDisplayRangeStart = highestPossibleStartPageToMaintainWindow;
    }

    // Now our the last button to display in our range is the start button (inclusive) with 4 buttons to the right!
    // Or last page if there are less than 5 pages.
    const pageDisplayRangeEnd = Math.min(pageDisplayRangeStart + maxWindowSizeEitherSide, lastPage);

    const pageList: number[] = [];
    for (let i = pageDisplayRangeStart; i <= pageDisplayRangeEnd; i++) {
      pageList.push(i);
    }

    return pageList;
  });

  readonly totalNumberOfResults = computed(() => {
    const resultsPage = this.#resultsPage();
    if (resultsPage) {
      return resultsPage.totalNumberOfResults;
    }

    return 0;
  });

  readonly hasResults = computed(() => {
    const resultsPage = this.#resultsPage();
    if (resultsPage) {
      return resultsPage.totalNumberOfResults > 0;
    }

    return false;
  });

  readonly hasFilter = computed(() => {
    const filter = this.filter();
    return !!filter
      && !Object.values(filter).every(value => value === null || value === undefined || value === false);
  });

  readonly hasSearchQuery = computed(() => {
    return !!this.searchQuery();
  });

  readonly searchYieldNoResults = computed(() => {
    const hasResults = this.hasResults();
    const hasSearchQuery = this.hasSearchQuery();
    const hasFilter = this.hasFilter();

    return !hasResults && (hasSearchQuery || hasFilter);
  });

  readonly noResultsInSystem = computed(() => {
    const hasResults = this.hasResults();
    const hasSearchQuery = this.hasSearchQuery();
    const hasFilter = this.hasFilter();

    return !hasResults && !(hasSearchQuery || hasFilter);
  });

  readonly pageSelected = output<PageRequest<TFilter>>();

  constructor() {
    effect(() => {
      const loadingPageInfo = this.pageLoading();

      this.#cleanupLoadingSubscription();

      this.#setLoadingState(PagedListLoadingState.Loading);

      this.#loadingSubscription = loadingPageInfo.subscribe(
        {
          next: pi => {
            if (!pi) {
              this.#setLoadingState(PagedListLoadingState.Error);
            } else {
              this.#resultsPage.set(pi);
              this.#setLoadingState(PagedListLoadingState.Complete);
            }
          },
          error: () => {
            this.#setLoadingState(PagedListLoadingState.Error);
          }
        });
    });

    effect(() => {
      const initialPageSize = this.initialPageSize();
      this.#pageSize.set(initialPageSize.valueOf());
    });

    effect(() => {
      this.#setupSearchTypeahead(this.searchInput());
    });

    effect(() => {
      const performSearch = this.filter() !== undefined;
      if (performSearch) {
        untracked(() => {
          this.onSearch();
        });
      }
    });

    effect(() => {
      const sortBy = this.sortBy();
      console.debug(sortBy);
      untracked(() =>
        this.onSearch());
    });
    this.goToPageForm = this.#fb.group<{ pageNumber: number }>({
      pageNumber: new FormControl<number>(this.currentPage(), this.pageSelectionValid())
    });
  }

  ngOnDestroy() {
    this.#cleanupLoadingSubscription();
    this.#cleanupSlowRequestTimerSubscription();
    this.#cleanupSearchSubscription();
  }

  resetSearch() {
    this.searchQuery.set('');
    this.filter.set({} as TFilter);
  }

  firstPage() {
    this.onPageSelection(1);
  }

  previousPage() {
    const resultsPage = this.#resultsPage();
    if (resultsPage) {
      this.onPageSelection(resultsPage.pageNumber - 1);
    }
  }

  nextPage() {
    const resultsPage = this.#resultsPage();
    if (resultsPage) {
      this.onPageSelection(resultsPage.pageNumber + 1);
    }
  }

  lastPage() {
    const resultsPage = this.#resultsPage();
    if (resultsPage) {
      this.onPageSelection(resultsPage.totalNumberOfPages);
    }
  }

  onPageSizeChange($event: any) {
    const newPageSize = +$event.target.value;
    this.emitPageChange(1, newPageSize);
  }

  onGoToPage() {
    if (!this.goToPageForm.valid) {
      return;
    }

    this.onPageSelection(this.goToPageNumber.value);
  }

  onPageSelection(pageNumber: number) {
    this.emitPageChange(pageNumber, this.#pageSize());
  }

  retryPageLoad() {
    this.buildCleanPageRequestAndEmit({
      pageNumber: this.currentPage(),
      pageSize: this.#pageSize(),
      search: this.searchQuery() ?? '',
      filter: this.filter() ?? null,
      sortBy: this.sortBy()
    });
  }

  emitPageChange(pageNumber: number, pageSize: number) {
    const resultsPage = this.#resultsPage();
    const currentPage = this.currentPage();

    let pageNumberEmit = pageNumber;
    if (pageNumberEmit < 1) {
      pageNumberEmit = 1;
    } else if (resultsPage && pageNumberEmit > resultsPage.totalNumberOfPages) {
      pageNumberEmit = resultsPage.totalNumberOfPages;
    }

    if (pageNumberEmit === currentPage && pageSize === this.#pageSize()) {
      return;
    }

    this.#pageSize.set(pageSize);

    this.buildCleanPageRequestAndEmit({
      pageNumber: pageNumberEmit,
      pageSize: pageSize,
      search: this.searchQuery() ?? '',
      filter: this.filter() ?? null,
      sortBy: this.sortBy()
    });
  }

  pageSelectionValid(): ValidatorFn {
    return (control: AbstractControl) => {

      const resultsPage = this.#resultsPage();
      if (!resultsPage) {
        return null;
      }

      const value = control.value;
      if (value && value as number) {
        const newPageNumber = +value;

        if (newPageNumber >= 1 && newPageNumber <= resultsPage.totalNumberOfPages) {
          return null;
        }
      }

      return { 'pageNumberInvalid': `Must be a number between 1 & ${resultsPage.totalNumberOfPages}.` };
    };
  }

  onSlowRequestCancel() {
    this.#cleanupLoadingSubscription();
    this.#setLoadingState(PagedListLoadingState.Cancelled);
  }

  onSearch() {
    this.buildCleanPageRequestAndEmit({
      pageSize: this.#pageSize(),
      pageNumber: 1,
      search: this.searchQuery() ?? '',
      filter: this.filter() ?? null,
      sortBy: this.sortBy()
    });
  }

  buildCleanPageRequestAndEmit(pageRequest: PageRequest<TFilter>) {
    let newPageRequest = { pageNumber: pageRequest.pageNumber, pageSize: pageRequest.pageSize } as any;

    if (pageRequest.search) {
      newPageRequest = { ...newPageRequest, search: pageRequest.search };
    }

    if (pageRequest.filter) {
      newPageRequest = { ...newPageRequest, filter: pageRequest.filter };
    }

    if (pageRequest.sortBy) {
      newPageRequest = { ...newPageRequest, sortBy: pageRequest.sortBy };
    }

    this.pageSelected.emit(newPageRequest);
  }

  reloadPage() {
    window.location.reload();
  }

  #setupSearchTypeahead(htmlInputElement: ElementRef<HTMLInputElement> | undefined) {

    this.#cleanupSearchSubscription();

    if (htmlInputElement) {
      this.#searchSubscription =
        merge(
          fromEvent(htmlInputElement.nativeElement, 'keyup').pipe(
            map(event => (event.target as HTMLInputElement).value),
            debounceTime(1000)
          ),
          fromEvent(htmlInputElement.nativeElement, 'search').pipe(
            map(event => (event.target as HTMLInputElement).value)
          )).pipe(
            distinctUntilChanged())
          .subscribe((text: string) => {
            this.buildCleanPageRequestAndEmit({
              pageSize: this.#pageSize(),
              pageNumber: 1,
              search: text ?? '',
              filter: this.filter() ?? null,
              sortBy: this.sortBy()
            });
          });
    }
  }

  #cleanupSlowRequestTimerSubscription() {
    if (this.#slowRequestTimerSubscription) {
      this.#slowRequestTimerSubscription.unsubscribe();
      this.#slowRequestTimerSubscription = undefined;
    }
  }

  #cleanupSearchSubscription() {
    if (this.#searchSubscription) {
      this.#searchSubscription.unsubscribe();
      this.#searchSubscription = undefined;
    }
  }

  #cleanupLoadingSubscription() {
    if (this.#loadingSubscription) {
      this.#loadingSubscription.unsubscribe();
      this.#loadingSubscription = undefined;
    }
  }

  #setLoadingState(loading: PagedListLoadingState) {
    this.#loadingState.set(loading);

    this.#cleanupSlowRequestTimerSubscription();

    if (loading === PagedListLoadingState.Loading) {
      this.#slowRequestTimerSubscription = timer(ServiceEnvironment.value.slowRequestTime).subscribe(() => {
        this.#loadingState.set(PagedListLoadingState.Slow);
      });
    }
  }

}
