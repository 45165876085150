import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { AnswerType, QuestionAnalogueClock, QuestionParametersClock, ValidationResult } from '../../models';
import { ClockType, GenericClock } from '../../utilities';
import { QuestionLayout } from '../question-layout';

@Component({
    selector: 'kip-analogue-clock',
    templateUrl: './analogue-clock.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AnalogueClockComponent extends QuestionLayout {

  #genericClock: GenericClock | undefined;
  #clockChangeSubscription: Subscription | undefined;

  override question: QuestionAnalogueClock | undefined;

  get answers(): AnswerType[] {
    if (this.#genericClock) {
      return [this.hours, this.minutes];
    }
    return [];
  }

  get hours() {
    if (this.#genericClock) {
      return Math.floor(this.#genericClock.clockHours.value / 5 === 0 ? 12 : this.#genericClock.clockHours.value / 5);
    }

    return 0;
  }

  get minutes() {
    if (this.#genericClock) {
      return this.#genericClock.clockMinutes.value === 60 ? 0 : this.#genericClock.clockMinutes.value;
    }

    return 0;
  }

  override set validationResults(values: ValidationResult[]) {
    this._validationResults = values;
    if (this.element && this.#genericClock && values.length === 2) {

      const parameters: QuestionParametersClock = this.question?.parameters ?? {
        hours: {},
        minutes: {}
      };

      const hours = Object.assign({}, parameters.hours);
      hours.value = this.hours;
      hours.valid = values[0] === ValidationResult.Correct;

      const minutes = Object.assign({}, parameters.minutes);
      minutes.value = this.minutes;
      minutes.valid = values[1] === ValidationResult.Correct;

      this.#genericClock = new GenericClock(this.element,
        hours,
        minutes,
        this.readonly ? ClockType.Readonly : ClockType.Interactive);

      if (this.#clockChangeSubscription) {
        this.#clockChangeSubscription.unsubscribe();
      }
      this.#clockChangeSubscription = this.#genericClock.change.subscribe(() => this.sendUpdates());
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  @ViewChild('element', { static: true }) element: ElementRef<SVGElement> | undefined;

  override initialize() {
    // Resolve the hands config
    const parameters: QuestionParametersClock = this.question?.parameters ?? {
      hours: {},
      minutes: {}
    };

    if (this.element) {
      this.#genericClock = new GenericClock(this.element,
        parameters.hours, parameters.minutes,
        this.readonly ? ClockType.Readonly : ClockType.Interactive);
      this.#clockChangeSubscription = this.#genericClock.change.subscribe(() => this.sendUpdates());
    }
    this.displayAnswer();
  }

  override destroy() {
    if (this.#clockChangeSubscription) {
      this.#clockChangeSubscription.unsubscribe();
    }
    if (this.#genericClock) {
      this.#genericClock.destroy();
    }
  }

  displayAnswer() {
    if (this.readonly && this.question && this.element) {
      const correctHours = Number.parseInt(this.question.answers[0].values[0].toString(), 10);
      const correctMinutes = Number.parseInt(this.question.answers[1].values[0].toString(), 10);
      let hours = correctHours;
      let minutes = correctMinutes;
      if (this.studentAnswers !== undefined && this.studentAnswers.length === 2) {
        const studentHours = Number.parseInt(this.studentAnswers[0].toString(), 10);
        const studentMinutes = Number.parseInt(this.studentAnswers[1].toString(), 10);
        hours = studentHours;
        minutes = studentMinutes === 0 ? 60 : studentMinutes;
      }

      this.#genericClock = new GenericClock(this.element,
        { value: hours, valid: hours === correctHours },
        { value: minutes, valid: minutes === correctMinutes },
        this.readonly ? ClockType.Readonly : ClockType.Interactive);
    }
  }
}
