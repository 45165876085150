import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-thumbs-up-a',
    templateUrl: './thumbs-up-a.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconThumbsUpAComponent implements IconComponent {

}
