import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { WhiteboardIconBase } from './whiteboard-icon-base';

@Component({
    selector: 'kip-whiteboard-icon-color',
    templateUrl: './color.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WhiteboardIconColorComponent extends WhiteboardIconBase {

  @Input({ required: true }) color = '';
}
