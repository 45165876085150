import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentLibModule } from 'assessment-lib';
import { AwardsLibModule } from 'awards-lib';
import { EmojiLibModule } from 'emoji-lib';
import { ExploreLibModule } from 'explore-lib';
import { FormsLibModule } from 'forms-lib';
import { IconLibModule } from 'icon-lib';
import { OrganisationLibModule } from 'organisation-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { QuestionsLibModule } from 'questions-lib';
import { ServiceLibModule } from 'service-lib';
import { TreePickerLibModule } from 'tree-lib';
import { TutorNotesLibModule } from 'tutor-notes-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { KipPointsSummaryPipe, LessonStatusIndicatorPipe } from './pipes';
import { StudentAccountComponent } from './student-detail/student-account/student-account/student-account.component';
import { StudentAccountListComponent } from './student-detail/student-account/student-account-list/student-account-list.component';
import { StudentAccountSummaryComponent } from './student-detail/student-account/student-account-summary.component';
import { StudentAssessmentResultComponent } from './student-detail/student-assessment-result/student-assessment-result.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { RankingsFilterComponent } from './student-detail/student-detail-tabs/rankings-filter/rankings-filter.component';
import { StudentAssessmentComponent } from './student-detail/student-detail-tabs/student-assessment/student-assessment.component';
import {
  StudentAssessmentChangeComponent
} from './student-detail/student-detail-tabs/student-assessment/student-assessment-change.component';
import { StudentAwardsComponent } from './student-detail/student-detail-tabs/student-award/student-awards.component';
import { StudentAwardsAddComponent } from './student-detail/student-detail-tabs/student-award/student-awards-add/student-awards-add.component';
import { StudentAwardsBaseComponent } from './student-detail/student-detail-tabs/student-award/student-awards-base/student-awards-base.component';
import { StudentAwardsListComponent } from './student-detail/student-detail-tabs/student-award/student-awards-list/student-awards-list.component';
import { StudentAwardsTradeComponent } from './student-detail/student-detail-tabs/student-award/student-awards-trade/student-awards-trade.component';
import { StudentContactComponent } from './student-detail/student-detail-tabs/student-contact/student-contact.component';
import { StudentContactEditComponent } from './student-detail/student-detail-tabs/student-contact/student-contact-edit/student-contact-edit.component';
import { StudentDetailTabsComponent } from './student-detail/student-detail-tabs/student-detail-tabs.component';
import { StudentEnrolmentComponent } from './student-detail/student-detail-tabs/student-enrolment/student-enrolment.component';
import { GoalCardComponent } from './student-detail/student-detail-tabs/student-goal/goal-card/goal-card.component';
import { GoalEditComponent } from './student-detail/student-detail-tabs/student-goal/goal-edit/goal-edit.component';
import { StudentGoalComponent } from './student-detail/student-detail-tabs/student-goal/student-goal.component';
import { StudentGoalHistoryComponent } from './student-detail/student-detail-tabs/student-goal/student-goal-history.component';
import { ActivityReviewComponent } from './student-detail/student-detail-tabs/student-lesson-history/activity-review/activity-review.component';
import { StudentLessonHistoryLessonComponent } from './student-detail/student-detail-tabs/student-lesson-history/lesson/lesson.component';
import { StudentLessonHistoryLessonDetailComponent } from './student-detail/student-detail-tabs/student-lesson-history/lesson/lesson-detail.component';
import { LessonHistoryWrapperComponent } from './student-detail/student-detail-tabs/student-lesson-history/lesson-history-wrapper/lesson-history-wrapper.component';
import { LoadMoreWrapperComponent } from './student-detail/student-detail-tabs/student-lesson-history/load-more-wrapper/load-more-wrapper.component';
import {
  StudentLessonHistoryComponent
} from './student-detail/student-detail-tabs/student-lesson-history/student-lesson-history.component';
import { StudentNoteComponent } from './student-detail/student-detail-tabs/student-note/student-note.component';
import { StudentNoteEditComponent } from './student-detail/student-detail-tabs/student-note/student-note-edit/student-note-edit.component';
import { StudentNotePinnedComponent } from './student-detail/student-detail-tabs/student-note/student-note-pinned/student-note-pinned.component';
import { StudentNoteDropdownComponent } from './student-detail/student-detail-tabs/student-note-dropdown/student-note-dropdown.component';
import { StudentNoteDropdownEditComponent } from './student-detail/student-detail-tabs/student-note-dropdown/student-note-dropdown-edit/student-note-dropdown-edit.component';
import { StudentNoteDropdownGeneralComponent } from './student-detail/student-detail-tabs/student-note-dropdown/student-note-dropdown-general/student-note-dropdown-general.component';
import { StudentSettingsComponent } from './student-detail/student-detail-tabs/student-settings/student-settings.component';
import {
  StrengthCardComponent
} from './student-detail/student-detail-tabs/student-strengths/strength-card/strength-card.component';
import {
  StrengthEditComponent
} from './student-detail/student-detail-tabs/student-strengths/strength-edit/strength-edit.component';
import {
  StudentStrengthComponent
} from './student-detail/student-detail-tabs/student-strengths/student-strength.component';
import {
  StudentStrengthHistoryComponent
} from './student-detail/student-detail-tabs/student-strengths/student-strength-history.component';
import { StudentWeaknessComponent } from './student-detail/student-detail-tabs/student-weaknesses/student-weakness.component';
import { StudentWeaknessHistoryComponent } from './student-detail/student-detail-tabs/student-weaknesses/student-weakness-history.component';
import { WeaknessCardComponent } from './student-detail/student-detail-tabs/student-weaknesses/weakness-card/weakness-card.component';
import { WeaknessEditComponent } from './student-detail/student-detail-tabs/student-weaknesses/weakness-edit/weakness-edit.component';
import { StudentSummaryComponent } from './student-detail/student-summary/student-summary.component';
import { StudentEditComponent } from './student-edit/student-edit.component';
import { StudentEditBaseComponent } from './student-edit-base/student-edit-base.component';
import { StudentEditPasswordComponent } from './student-edit-password/student-edit-password.component';
import { StudentEditPasswordBaseComponent } from './student-edit-password-base/student-edit-password-base.component';
import { StudentListComponent } from './student-list/student-list.component';
import { StudentPhotoComponent } from './student-photo/student-photo.component';

@NgModule({
  declarations: [
    ActivityReviewComponent,
    GoalCardComponent,
    GoalEditComponent,
    KipPointsSummaryPipe,
    LessonHistoryWrapperComponent,
    LessonStatusIndicatorPipe,
    LoadMoreWrapperComponent,
    RankingsFilterComponent,
    StrengthCardComponent,
    StrengthEditComponent,
    StudentAccountComponent,
    StudentAccountListComponent,
    StudentAccountSummaryComponent,
    StudentAssessmentChangeComponent,
    StudentAssessmentComponent,
    StudentAssessmentResultComponent,
    StudentAwardsAddComponent,
    StudentAwardsBaseComponent,
    StudentAwardsComponent,
    StudentAwardsListComponent,
    StudentAwardsTradeComponent,
    StudentContactComponent,
    StudentContactEditComponent,
    StudentDetailComponent,
    StudentDetailTabsComponent,
    StudentEditBaseComponent,
    StudentEditComponent,
    StudentEditPasswordBaseComponent,
    StudentEditPasswordComponent,
    StudentEnrolmentComponent,
    StudentGoalComponent,
    StudentGoalHistoryComponent,
    StudentLessonHistoryComponent,
    StudentLessonHistoryLessonComponent,
    StudentLessonHistoryLessonDetailComponent,
    StudentListComponent,
    StudentNoteComponent,
    StudentNoteDropdownComponent,
    StudentNoteDropdownEditComponent,
    StudentNoteDropdownGeneralComponent,
    StudentNoteEditComponent,
    StudentNotePinnedComponent,
    StudentPhotoComponent,
    StudentSettingsComponent,
    StudentStrengthComponent,
    StudentStrengthHistoryComponent,
    StudentSummaryComponent,
    StudentWeaknessComponent,
    StudentWeaknessHistoryComponent,
    WeaknessCardComponent,
    WeaknessEditComponent
  ],
  imports: [
    AssessmentLibModule,
    AwardsLibModule,
    CommonModule,
    EmojiLibModule,
    ExploreLibModule,
    FormsLibModule,
    FormsModule,
    IconLibModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    OrganisationLibModule,
    PipesDirectivesLibModule,
    QuestionsLibModule,
    ReactiveFormsModule,
    RouterModule,
    ServiceLibModule,
    TreePickerLibModule,
    TutorNotesLibModule,
    UiCommonLibModule
  ],
  exports: [
    ActivityReviewComponent,
    KipPointsSummaryPipe,
    LessonHistoryWrapperComponent,
    LessonStatusIndicatorPipe,
    LoadMoreWrapperComponent,
    RankingsFilterComponent,
    StudentAccountComponent,
    StudentAccountListComponent,
    StudentAccountSummaryComponent,
    StudentAssessmentChangeComponent,
    StudentAssessmentComponent,
    StudentAssessmentResultComponent,
    StudentAwardsAddComponent,
    StudentAwardsBaseComponent,
    StudentAwardsComponent,
    StudentAwardsListComponent,
    StudentAwardsTradeComponent,
    StudentContactComponent,
    StudentContactEditComponent,
    StudentDetailComponent,
    StudentDetailTabsComponent,
    StudentEditBaseComponent,
    StudentEditComponent,
    StudentEditPasswordBaseComponent,
    StudentEditPasswordComponent,
    StudentEnrolmentComponent,
    StudentGoalComponent,
    StudentGoalHistoryComponent,
    StudentLessonHistoryComponent,
    StudentLessonHistoryLessonComponent,
    StudentLessonHistoryLessonDetailComponent,
    StudentListComponent,
    StudentNoteComponent,
    StudentNoteDropdownComponent,
    StudentNoteDropdownEditComponent,
    StudentNoteDropdownGeneralComponent,
    StudentNoteEditComponent,
    StudentNotePinnedComponent,
    StudentPhotoComponent,
    StudentSettingsComponent,
    StudentStrengthComponent,
    StudentStrengthHistoryComponent,
    StudentSummaryComponent,
    StudentWeaknessComponent,
    StudentWeaknessHistoryComponent
  ]
})
export class StudentCommonLibModule { }
