import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-currency',
    templateUrl: './form-control-currency.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlCurrencyComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<string>>();
  readonly readonly = input(false);

}
