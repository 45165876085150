import { ChangeDetectionStrategy, Component } from '@angular/core';

import { RegionFlag } from './region-flag';

@Component({
    selector: 'kip-region-flag-nz',
    templateUrl: './region-flag-nz.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RegionFlagNzComponent implements RegionFlag {

}
