import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as MarkdownIt from 'markdown-it';

declare let texmath: any;
declare let katex: any;

@Component({
    selector: 'kip-message-parser',
    templateUrl: './message-parser.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MessageParserComponent {

  #isAward = false;
  #isEmoji = false;
  #isStandard = false;
  #message = '';
  readonly #md: MarkdownIt;

  get isAward() {
    return this.#isAward;
  }

  get isEmoji() {
    return this.#isEmoji;
  }

  get isStandard() {
    return this.#isStandard;
  }

  @Input() warning = false;

  @Input({ required: true })
  set message(value: string) {
    if (this.#message !== value) {
      if (value.includes('award_')) {
        this.#isAward = true;
      } else if (value.includes(':')) {
        this.#isEmoji = true;
      } else {
        this.#isStandard = true;
      }

      let result = this.#md.render(value);

      const regex = new RegExp(/<span class="katex-mathml"><math.+?<\/math><\/span>/g);
      result = result.replace(regex, '');
      result = result.replace(/\n/g, '<br/>');
      this.#message = result;
    }
  }

  get message() {
    return this.#message;
  }

  constructor() {
    this.#md = (window as any).markdownit({ breaks: true, html: true });
    this.#md.use((window as any).markdownitSup as MarkdownIt.PluginSimple);
    this.#md.use((window as any).markdownitSub as MarkdownIt.PluginSimple);
    this.#md.use(texmath.use(katex));
  }
}
