import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { serverError } from '../helpers/typed-form';

@Pipe({
    name: 'isFormControlInvalid',
    pure: false,
    standalone: false
})
export class FormControlInvalidPipe implements PipeTransform {

  transform(formControl: FormArray | FormControl | FormGroup): boolean {
    return !formControl.valid && (formControl.touched || formControl.dirty || formControl.hasError(serverError));
  }
}
