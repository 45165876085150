import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { generateBreadcrumbs, RouteParams, studentsRoute } from 'ui-common-lib';

import { StudentDetail, StudentRankingDetails, StudentSummary } from '../models';
import { StudentService } from '../services';

@Component({
    selector: 'kip-student-detail',
    templateUrl: './student-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentDetailComponent implements OnInit, OnDestroy {

  readonly #router = inject(Router);
  readonly #studentService = inject(StudentService);

  #subscriptions: Subscription[] = [];

  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly route = inject(ActivatedRoute);

  breadcrumbs = generateBreadcrumbs(1, [studentsRoute]);

  studentDetail: StudentDetail | undefined;
  studentRanking: StudentRankingDetails | undefined;
  studentSummary: StudentSummary | undefined;

  get student() {
    return this.studentDetail ? this.studentDetail.student : undefined;
  }

  get results() {
    return this.studentDetail ? this.studentDetail.assessmentResult : undefined;
  }

  get accounts() {
    return this.studentDetail ? this.studentDetail.accounts : [];
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.route.paramMap.subscribe(paramMap => {
        const studentId = +(paramMap.get(RouteParams.StudentId) ?? 0);
        if (studentId) {
          this.#subscriptions.push(
            this.#studentService.getStudentById(studentId)
              .subscribe(studentDetails => {
                this.initStudentDetails(studentDetails);
                this.changeDetectorRef.markForCheck();
              }),
            this.#studentService
              .resolveCacheItem<StudentSummary>(studentId)
              .subscribe(o => {
                this.studentSummary = o;
                this.changeDetectorRef.markForCheck();
              }),
            this.#studentService.getStudentRankingById(studentId)
              .subscribe(ranking => {
                this.studentRanking = ranking;
                this.changeDetectorRef.markForCheck();
              }));
        }
      }));

    this.performInitActions();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  edit() {
    this.#router.navigate([`students/${this.studentDetail?.student.id ?? 0}/edit`]);
  }

  /* eslint-disable kip/no-unused-public-members */

  performInitActions() {
    // override for parent logic
  }

  initStudentDetails(studentDetail: StudentDetail) {
    this.studentDetail = studentDetail;
    this.changeDetectorRef.detectChanges();
  }

  /* eslint-enable kip/no-unused-public-members */
}
