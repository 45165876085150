import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { HelpActivityDetail, HelpNodeSelected } from '../models';
import { HelpService } from '../services';

@Component({
    selector: 'kip-help-view',
    templateUrl: './help-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HelpViewComponent implements OnDestroy {

  readonly #helpService = inject(HelpService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #helpNodeSelected: HelpNodeSelected | undefined;
  #subscriptions: Subscription[] = [];

  @Input() helpActivity: HelpActivityDetail | undefined;
  @Input({ required: true }) set helpNodeSelected(value: HelpNodeSelected | undefined) {
    this.#helpNodeSelected = value;
    this.helpActivity = undefined;
  }

  get helpNodeSelected() {
    return this.#helpNodeSelected;
  }

  @Output() readonly quit = new EventEmitter();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  start(activityId: number) {
    this.#subscriptions.push(
      this.#helpService.startActivity(activityId).subscribe(value => {
        this.helpActivity = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }
}
