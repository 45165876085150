import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-tree',
    templateUrl: './form-control-tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlTreeComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<number | null>>();
  readonly regionId = input.required<FormControl<number | null>>();
  readonly subjectId = input(0);
  readonly description = input('');

}
