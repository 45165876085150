import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EnrolmentSessionSummary, LessonType, OccurrenceStatus } from '../../models';

@Component({
    selector: 'kip-enrolment-session',
    templateUrl: './enrolment-session.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnrolmentSessionComponent {
  readonly lessonType = LessonType;

  @Input({ required: true }) enrolmentSessions: EnrolmentSessionSummary[] = [];
  @Input({ required: true }) listOnly = true;

  @Output() readonly enrolmentSessionSelected = new EventEmitter<EnrolmentSessionSummary[]>();

  change(enrolmentSession: EnrolmentSessionSummary) {
    if (this.canUpdateStatus(enrolmentSession)) {
      enrolmentSession.checked = !enrolmentSession.checked;
      this.enrolmentSessionSelected.emit(this.enrolmentSessions.filter(x => x.checked));
    }
  }

  canUpdateStatus(enrolmentSession: EnrolmentSessionSummary) {
    return (enrolmentSession.status === OccurrenceStatus.Ready ||
      enrolmentSession.status === OccurrenceStatus.Reschedule) && enrolmentSession.lessonType !== this.lessonType.HomeMakeUp;
  }

  toggle(e: Event) {
    e.preventDefault();
  }
}
