import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Icons } from 'icon-lib';
import { finalize, Subject, Subscription, tap } from 'rxjs';
import {
  fileUploadCompletionHandler,
  fileUploadingProgressHandler
} from 'service-lib';

import { ActivitySummary, ActivityUploaderParams, FilesService } from '../shared';

enum UploadStatus {
  Waiting = 0,
  Uploading = 1,
  Processing = 2,
  Error = 3,
  Success = 4
}
@Component({
    selector: 'kip-upload-activity-files-public',
    templateUrl: './upload-activity-files-public.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UploadActivityFilesPublicComponent implements OnInit, OnDestroy {

  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #filesService = inject(FilesService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #activitySummary: ActivitySummary | undefined;
  #sending = false;
  #result = '';
  #lessonId?: number;
  #activityGuid = '';
  readonly #progress = new Subject<number>();
  #isUploadingFinished = false;
  #isLoadingLessonInfo = true;
  #hasError = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly uploadStatus = UploadStatus;

  currentUploadStatus = UploadStatus.Waiting;

  files: readonly File[] = [];

  get isLoadingLessonInfo() {
    return this.#isLoadingLessonInfo;
  }

  get hasError() {
    return this.#hasError;
  }

  get result() {
    return this.#result;
  }

  get activitySummary() {
    return this.#activitySummary;
  }

  get isLoading() {
    return this.#sending;
  }

  get progress() {
    return this.#progress;
  }

  get isUploadingFinished() {
    return this.#isUploadingFinished;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#activatedRoute.queryParamMap.subscribe(queryParam => {
        this.#lessonId = +queryParam.get(ActivityUploaderParams.LessonId)!;
        this.#activityGuid = queryParam.get(ActivityUploaderParams.ActivityGuid)!;

        this.#subscriptions.push(
          this.#filesService.getActivitySummary(this.#lessonId, this.#activityGuid)
            .pipe(finalize(() => this.#isLoadingLessonInfo = false))
            .subscribe(result => {
              this.#activitySummary = result;
              this.#changeDetectorRef.markForCheck();
            }));
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  send() {
    this.#result = 'Uploading please wait...';
    this.currentUploadStatus = UploadStatus.Uploading;
    this.#changeDetectorRef.markForCheck();
    this.#sending = true;
    this.#subscriptions.push(
      this.#filesService.uploadActivityFiles(this.#lessonId!, this.#activityGuid, this.files)
        .pipe(
          finalize(() => {
            this.#sending = false;
            this.#changeDetectorRef.markForCheck();
          }),
          tap(response => this.#progress.next(fileUploadingProgressHandler(response as HttpProgressEvent)))
        )
        .subscribe(
          {
            next: result => {
              this.#isUploadingFinished = fileUploadCompletionHandler(result as HttpProgressEvent, this.#isUploadingFinished);
              if (this.#isUploadingFinished) {
                this.currentUploadStatus = UploadStatus.Processing;
                this.#result = 'Processing please wait...';
              }

              if (result.type === HttpEventType.Response) {
                this.#result = `File${this.files.length > 1 ? 's' : ''} added to activity!`;
                this.currentUploadStatus = UploadStatus.Success;
                this.#isUploadingFinished = true;
                this.files = [];
                this.#changeDetectorRef.markForCheck();
              }
            }, error: () => {
              this.#result = 'Upload failed!';
              this.currentUploadStatus = UploadStatus.Error;
              this.#hasError = true;
              this.#isUploadingFinished = false;
              this.#changeDetectorRef.markForCheck();
            }
          }));
  }

  onNewUploading() {
    this.files = [];
    this.#result = '';
    this.#isUploadingFinished = false;
    this.#hasError = false;
    this.#sending = false;
    this.currentUploadStatus = UploadStatus.Waiting;
  }
}
