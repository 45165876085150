/* eslint-disable @typescript-eslint/consistent-return */

export enum StudentRoutes {
  Lesson = 'lesson',
  Lobby = 'lobby',
  Homework = 'homework',
  Settings = 'settings',
  Awards = 'awards',
  Results = 'results',
  Enrolment = 'enrolment',
  Dashboard = '',
  Student = 'student'
}

export enum StudentRouteParams {
  LessonId = 'lessonId',
  LessonActivityPlanId = 'lessonActivityPlanId',
  QuestionIndex = 'questionIndex'
}

export enum StudentQueryParams {
  AllowBypass = 'allowBypass',
  Redirect = 'redirect'
}

export enum DashboardRoute {
  Homework = 1,
  Lesson = 2,
  Lobby = 3
}

export function gotoLesson(lessonId: number, lessonActivityPlanId?: number, questionIndex?: number) {
  let baseRoute = `/${StudentRoutes.Student}/${StudentRoutes.Lesson}/${lessonId}`;
  if (lessonActivityPlanId !== undefined) {
    baseRoute = `${baseRoute}/${lessonActivityPlanId}`;
  }

  if (questionIndex !== undefined) {
    baseRoute = `${baseRoute}/${questionIndex}`;
  }

  return baseRoute;
}

export function gotoLessonResults(lessonId: number) {
  return `/${StudentRoutes.Student}/${StudentRoutes.Lesson}/${lessonId}/${StudentRoutes.Results}`;
}

export function gotoLessonEnrolment(lessonId: number) {
  return `/${StudentRoutes.Student}/${StudentRoutes.Lesson}/${lessonId}/${StudentRoutes.Enrolment}`;
}

export function gotoLobby(lessonId: number) {
  return `/${StudentRoutes.Student}/${StudentRoutes.Lobby}/${lessonId}`;
}

export function gotoHomework(lessonId: number, lessonActivityPlanId?: number, questionIndex?: number) {
  let baseRoute = `/${StudentRoutes.Student}/${StudentRoutes.Homework}/${lessonId}`;
  if (lessonActivityPlanId !== undefined) {
    baseRoute = `${baseRoute}/${lessonActivityPlanId}`;
  }

  if (questionIndex !== undefined) {
    baseRoute = `${baseRoute}/${questionIndex}`;
  }

  return baseRoute;
}

export function urlIsHomework(url: string) {
  return url.includes(StudentRoutes.Homework);
}

export function gotoLessonOrHomework(lessonId: number, lessonActivityPlanId?: number, questionIndex?: number) {
  if (!urlIsHomework(window.location.pathname)) {
    return gotoLesson(lessonId, lessonActivityPlanId, questionIndex);
  }

  return gotoHomework(lessonId, lessonActivityPlanId, questionIndex);
}

export function navigateDashboard(dashboardRoute: DashboardRoute, lessonId: number) {
  switch (dashboardRoute) {
    case DashboardRoute.Lesson:
      return gotoLesson(lessonId);
    case DashboardRoute.Homework:
      return gotoHomework(lessonId);
    case DashboardRoute.Lobby:
      return gotoLobby(lessonId);
  }
}

export function gotoStudentDashboard() {
  return `/${StudentRoutes.Student}`;
}

export function gotoAwards() {
  return `/${StudentRoutes.Student}/${StudentRoutes.Awards}`;
}

export function gotoStudentSettings() {
  return `/${StudentRoutes.Student}/${StudentRoutes.Settings}`;
}

