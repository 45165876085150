import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'kip-form-group-wrapper',
    templateUrl: './form-group-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormGroupWrapperComponent implements OnInit, OnDestroy {

  #changeDetectorRef = inject(ChangeDetectorRef);
  #subscriptions: Subscription[] = [];
  #oldFormValues = '';

  #submitted = false;

  readonly icons = Icons;

  get submitted() {
    return this.#submitted;
  }

  @Input() class = '';
  @Input({ required: true }) formGroup!: FormGroup;
  @Input() showCancel: boolean | string = false;
  @Input() cancelClass = 'btn btn-link';
  @Input() submitVisible = true;
  @Input() submitText = 'Submit';
  @Input() submitDisabled = false;
  @Input() submitClass = 'btn btn-primary me-2';
  @Input() invalidText = 'Please review the form and enter all required details.';
  @Input() autoSave = false;
  @Input() submitFullWidth = false;
  @Input() saveDate: Date | undefined;
  @Input() saveClass = 'btn btn-primary me-2 btn-success';

  @Output() readonly submittedAndValidated = new EventEmitter();
  @Output() readonly cancelled = new EventEmitter();
  @Output() readonly saveDateChange = new EventEmitter<Date | undefined>();

  ngOnInit() {
    this.#subscriptions.push(this.formGroup.valueChanges.subscribe(() => {
      const newFormValues = JSON.stringify(this.formGroup.value);
      if (newFormValues !== this.#oldFormValues) {
        this.#oldFormValues = newFormValues;
        this.saveDate = undefined;
        this.saveDateChange.emit(undefined);
        if (this.autoSave) {
          this.submit();
        }
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    this.#submitted = true;
    this.formGroup.markAllAsTouched();
    this.#updateValueAndValidityRecursively(this.formGroup);
    this.#changeDetectorRef.detectChanges();

    if (!this.formGroup.invalid) {
      this.submittedAndValidated.emit();
    }
  }

  #updateValueAndValidityRecursively(control: AbstractControl): void {
    if (control instanceof FormGroup || control instanceof FormArray) {
      const controls = Object.values(control.controls);
      for (const childControl of controls) {
        this.#updateValueAndValidityRecursively(childControl);
      }
    }
    else {
      control.updateValueAndValidity();
    }
  }
}
