import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-grade-picker',
    templateUrl: './grade-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GradePickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly grades = toSignal(this.#sharedService.getGrades(), { initialValue: [] });
  readonly gradeId = input.required<FormControl<number | null>>();
  readonly label = input('');
  readonly labelExtra = input('');
  readonly name = input('');
  readonly labelClass = input<string | undefined>(undefined);
  readonly optional = input(false);

}
