import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negativeValue',
    standalone: false
})
export class NegativeValuePipe implements PipeTransform {

  transform(value: number): any {
    if (value === undefined) {
      return 0;
    }

    if (value < 0) {
      return `(${Math.abs(value).toFixed(2)})`;
    }

    return value.toFixed(2);
  }
}
