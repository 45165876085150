import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { buffer, debounceTime } from 'rxjs/operators';

import { AnswerType, DrillType, QuestionDrills, QuestionParametersDrills, Region, ValidationResult } from '../../models';
import { iterateBy, sortBy } from '../../utilities';
import { QuestionLayout } from '../question-layout';
import { AnswerBoxComponent, AnswerBoxEntry, AnswerNavigation } from './controls/answer-box/answer-box.component';

enum InputDirection {
  Unknown = 0,
  LeftToRight = 1,
  RightToLeft = 2
}

@Component({
    selector: 'kip-question-drill',
    templateUrl: './drill.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DrillComponent extends QuestionLayout implements AfterViewInit, OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #moneySign = '$';
  #decimalSign = '.';
  #money: boolean | undefined;
  #type: number | undefined;
  #values: readonly number[] = [];
  #answer = '';
  #decimals = 0;
  #remainderLength = 0;
  #maxLength = 0;
  #valuesBlock: string[][] = [];
  #answerBoxEntries: AnswerBoxEntry[] = [];
  #buffer: Subject<AnswerNavigation> | undefined;
  #bufferSubscription: Subscription | undefined;
  #inputDirection = InputDirection.RightToLeft;

  override question: QuestionDrills | undefined;

  override set region(value: Region | undefined) {
    this._region = value;

    if (value) {
      this.#moneySign = value.currencySwap.primaryCurrencySymbol;
      this.#decimalSign = value.currencySwap.decimalSymbol;
    } else {
      this.#moneySign = '$';
      this.#decimalSign = '.';
    }
  }

  override get region() {
    return this._region;
  }

  get answers(): AnswerType[] {
    let answer = '';
    if (this.inputs) {
      for (const inputItem of this.inputs) {
        answer += inputItem.value;
      }
    }
    return [answer];
  }

  get valuesBlock() {
    return this.#valuesBlock;
  }

  get answerBoxEntries() {
    return this.#answerBoxEntries;
  }

  get isDivision() {
    return this.#type === DrillType.Division;
  }

  override set validationResults(values: ValidationResult[]) {
    if (this.inputs) {
      for (const inputItem of this.inputs) {
        inputItem.setValidationResult();
      }
      this._validationResults = values;
      if (this.#inputDirection !== InputDirection.Unknown) {
        this.#focusFirstEmpty();
      }
      this.#changeDetectorRef.markForCheck();
    }
  }

  override get validationResults() {
    return this._validationResults;
  }

  @ViewChildren(AnswerBoxComponent) inputs: QueryList<AnswerBoxComponent> | undefined;

  ngAfterViewInit() {
    if (this.isDivision) {
      this.#inputDirection = InputDirection.LeftToRight;
    }

    window.setTimeout(() => this.validationResults = []);
  }

  override ngOnDestroy() {
    this.#bufferSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  override initialize() {
    this.#money = false;
    this.#type = DrillType.Addition;
    this.#values = [];

    const parameters: QuestionParametersDrills = this.question?.parameters ?? {
      money: false,
      type: DrillType.Addition,
      values: []
    };

    this.#money = parameters.money;
    this.#type = parameters.type;
    this.#values = parameters.values;

    this.#answer = (this.question?.answers[0].values[0] ?? '').toString();

    this.#determineRemainderPlacesInAnswer();
    this.#calculateDecimalPlaces();
    this.#determineMaxLength();
    this.#buildValuesBlock();
    this.#buildAnswerBoxEntries();
    this.#buffer = new Subject<AnswerNavigation>();
    this.#changeDetectorRef.markForCheck();
  }

  onChange() {
    this.sendUpdates();
  }

  override incomplete() {
    this.#focusFirstEmpty();
  }

  onKeyUpIndex(navigation: AnswerNavigation) {

    if (!this.#bufferSubscription) {
      this.#initBuffer();
    }

    if (this.#inputDirection === InputDirection.Unknown) {
      this.#setInputDirection(navigation.index);
    }

    this.#buffer?.next(navigation);
  }

  onSubmit() {
    this.submit();
  }

  #focusFirstEmpty() {
    const inputs = this.#getSortedAnswerBoxInputs();
    if (inputs && inputs.length > 0) {
      const firstEmptyInput = inputs.find(x => x.value === '');
      if (firstEmptyInput) {
        firstEmptyInput.focus();
      } else {
        inputs[0].focus();
      }
    }
  }

  #processInputBuffer(items: AnswerNavigation[]) {

    const inputs = this.#getSortedAnswerBoxInputs();

    if (inputs && items) {

      const startIndex = inputs.findIndex(o => o.answerBoxEntry !== undefined && o.answerBoxEntry.index === items[0].index);
      let current: AnswerBoxComponent | undefined;
      let index = startIndex;

      for (const o of items) {
        current = inputs[index];
        current.setValue(o.eventKey);
        index = index + 1 === inputs.length ? 0 : index + 1;
      }

      if (inputs.length > 1) {

        const rest = [...iterateBy<AnswerBoxComponent>({ start: current })(inputs)];

        const indexOf = rest.findIndex(o => o.value === '');

        const nextInput = rest[indexOf === -1 ? 1 : indexOf];
        nextInput.focus();
      }
    }
  }

  #getSortedAnswerBoxInputs() {
    if (this.inputs) {

      // eslint-disable-next-line no-inner-declarations
      function sortAnswerBoxComponents(inputDirection: InputDirection) {

        const compareFn = sortBy('index', inputDirection === InputDirection.LeftToRight ? 'asc' : 'desc');

        return (left: AnswerBoxComponent, right: AnswerBoxComponent) => {
          if (left.answerBoxEntry && right.answerBoxEntry) {
            return compareFn(left.answerBoxEntry, right.answerBoxEntry);
          }
          return 0;
        };
      }

      return this.inputs.toArray()
        .filter(s => s.answerBoxEntry !== undefined && s.answerBoxEntry.correctValue !== null)
        .sort(sortAnswerBoxComponents(this.#inputDirection));
    }
    return [];
  }

  #initBuffer() {

    if (!this.#buffer) {
      this.#buffer = new Subject<AnswerNavigation>();
    }

    if (this.#buffer && !this.#bufferSubscription) {

      // debounce incoming keyup events
      const delay = fromEvent(document, 'keyup').pipe(
        debounceTime(100)
      );

      // when final debounced keyup event comes through, flush the buffer
      const subscription = this.#buffer.pipe(
        buffer(delay)
      ).subscribe({
        next: o => {
          this.#processInputBuffer(o);

          if (this.#bufferSubscription) {
            this.#bufferSubscription?.unsubscribe();
            this.#bufferSubscription = undefined;
          }
        }
      });

      this.#bufferSubscription = subscription;
    }
  }

  #setInputDirection(index: number) {

    if (this.#inputDirection === InputDirection.Unknown && this.inputs) {
      const matches = this.inputs.filter(s => s.answerBoxEntry !== undefined && s.answerBoxEntry.correctValue !== null)
        .map(o => o.answerBoxEntry!)
        .sort((left, right) => left.index < right.index ? -1 : left.index === right.index ? 0 : 1);

      const ordinal = matches.findIndex(o => o.index === index);
      const direction = matches.length - 1 - ordinal >= Math.ceil(matches.length / 2) ?
        InputDirection.LeftToRight : InputDirection.RightToLeft;

      // update tab indexes
      if (direction === InputDirection.RightToLeft) {
        for (const [matchesIndex, o] of matches.entries()) {
          o.tabIndex = matches.length - matchesIndex % matches.length;
        }
      }

      this.#inputDirection = direction;
    }
  }

  #determineMaxLength() {

    // determine the max length of the elements
    let maxLength = this.#answer?.toString().length;

    for (const value of this.#values) {
      const length = value.toFixed(this.#decimals).length;
      if (length > maxLength) {
        maxLength = length;
      }
    }

    // leave space for +, - or x
    if (this.#type === DrillType.Addition || this.#type === DrillType.Subtraction || this.#type === DrillType.Multiplication) {
      maxLength++;
    }

    // leave space for $
    if (this.#money) {
      maxLength++;
    }

    // leave space for remainder
    if (this.#remainderLength > 0) {
      maxLength++;
    }

    this.#maxLength = maxLength;
  }

  #buildAnswerBoxEntries() {
    // make entry boxes
    let selectedIndex = 0;
    const answerBoxEntries: AnswerBoxEntry[] = [];
    for (let index = 0, lastIndex = this.#maxLength + 1; index < lastIndex; index++) {
      answerBoxEntries.push({
        correctValue: null,
        displayAnswer: null,
        displayText: '',
        index: index,
        returnText: ''
      });
    }
    const offset = answerBoxEntries.length - this.#answer.length;
    // Storing the correct answers in another array to compare

    const correctAnswers = [...this.#answer];
    let array: string[] = [];
    if (this.studentAnswers === undefined) {
      array = correctAnswers;
    } else {
      // Storing the student's latest answer
      if (this.readonly && this.question && this.studentAnswers.length <= this.#maxLength) {
        array = [...this.studentAnswers[0].toString()];
      }
    }
    for (const valueArray of array) {
      if (selectedIndex + offset <= answerBoxEntries.length - 1) {
        if (valueArray === '.' || valueArray === 'r') {
          // no entry allowed, allow for different decimal formats
          answerBoxEntries[selectedIndex + offset].returnText = valueArray;
          answerBoxEntries[selectedIndex + offset].displayText = this.#replaceDecimal(valueArray);
        } else {
          // entry allowed

          answerBoxEntries[selectedIndex + offset].correctValue = Number.parseInt(correctAnswers[selectedIndex], 10);
          if (this.studentAnswers !== undefined) {
            if (this.readonly && this.question && this.studentAnswers.length <= this.#maxLength) {
              answerBoxEntries[selectedIndex + offset].displayAnswer = Number.parseInt(valueArray, 10);
            }
          } else {
            answerBoxEntries[selectedIndex + offset].displayAnswer = Number.parseInt(correctAnswers[selectedIndex], 10);
          }
        }
      }
      selectedIndex++;
    }
    // add money field if required
    if (this.#money) {
      answerBoxEntries[1].displayText = this.#moneySign;
    }

    this.#answerBoxEntries = answerBoxEntries;
  }

  #replaceDecimal(value: string) {
    return value === '.' ? this.#decimalSign : value;
  }

  #buildValuesBlock() {
    const valuesBlock: string[][] = [];
    let index = 0;
    for (const value of this.#values) {

      let decimals = this.#decimals;

      // don't display decimals for multiplier or divider symbol row

      if ((this.#type === DrillType.Multiplication || this.#type === DrillType.Division) && index === 1) {
        decimals = 0;
      }

      // get value to correct decimal places

      let valueToDisplay = value.toFixed(decimals);

      // leave space for remainders in division

      if (this.#type === DrillType.Division && index === 0 && this.#remainderLength > 0) {
        valueToDisplay = valueToDisplay.padEnd(valueToDisplay.length + this.#remainderLength);
      }

      // output each row

      // this maxlength check doesn't look correct for division questions
      // HACK - ensure offset can't be less than 0
      const valueBlock = Array.from<string>({ length: this.#maxLength + 1 });
      const array = [...valueToDisplay];
      let offset = valueBlock.length - array.length;
      if (offset < 0) {
        offset = 0;
      }
      let i = 0;
      for (const valueArray of array) {
        valueBlock[i + offset] = this.#replaceDecimal(valueArray);
        i++;
      }

      // add in money sign

      if (this.#money && !((this.#type === DrillType.Multiplication || this.#type === DrillType.Division) && index === 1)) {
        valueBlock[1] = this.#moneySign;
      }

      // add in symbols

      if (index === this.#values.length - 1 && this.#type === DrillType.Addition) {
        valueBlock[0] = '+';
      }
      if (index === this.#values.length - 1 && this.#type === DrillType.Subtraction) {
        valueBlock[0] = '-';
      }
      if (index === this.#values.length - 1 && this.#type === DrillType.Multiplication) {
        valueBlock[0] = 'x';
      }
      index++;
      valuesBlock.push(valueBlock);
    }

    this.#valuesBlock = valuesBlock;
  }

  #determineRemainderPlacesInAnswer() {
    const rIndex = (this.#answer ?? '').toString().indexOf('r');
    this.#remainderLength = rIndex !== -1 ? (this.#answer ?? '').toString().length - rIndex : 0;
  }

  #calculateDecimalPlaces() {
    let maxDecimalPlaces = 0;
    for (const value of this.#values) {
      const decimalPlaces = this.#countDecimals(value);
      if (decimalPlaces > maxDecimalPlaces) {
        maxDecimalPlaces = decimalPlaces;
      }
    }

    this.#decimals = maxDecimalPlaces;
  }

  #countDecimals(value: number) {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length;
  }
}
