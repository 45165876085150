import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
    selector: 'kip-points-badge',
    templateUrl: './kip-points-badge.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class KipPointsBadgeComponent {

}
