import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'startsInFromOffset',
    standalone: false
})
export class StartsInFromOffsetPipe implements PipeTransform {

  readonly #sanitizer = inject(DomSanitizer);

  transform(value: string, durationMinutes: number, isAssessment: boolean, shorten = false, useHumanizedDates = false, isHomework = false): Observable<SafeHtml | string> {

    const unitOfTimeMinute = 'minute';
    // Use a timer to auto increment starts in value
    const date = moment(value).startOf(unitOfTimeMinute);

    const lessonOrAssessment = isAssessment ? 'Assessment' : 'Lesson';

    return timer(0, 1000).pipe(
      map(() => {

        // Resolve the time diff between now and the start time
        const now = moment.utc().startOf(unitOfTimeMinute);
        const minutes = date.diff(now, unitOfTimeMinute);
        const duration = moment.duration(minutes, 'minutes');
        const humanize = duration.humanize();
        const inProgress = 'NOW';
        const completed = 'Lesson completed';

        if (shorten) {
          if (minutes === 0) {
            return this.#sanitizer.bypassSecurityTrustHtml(inProgress);
          }

          if (minutes <= -1 * durationMinutes) {
            return this.#sanitizer.bypassSecurityTrustHtml(completed);
          }

          // if the target date isn't the same as the current date
          // show the month and day instead of the amount of hours
          // until the target date.
          const localNow = now.local();
          if (useHumanizedDates && (date.year() !== localNow.year() || date.month() !== localNow.month() || date.date() !== localNow.date())) {
            return date.format('ddd<br>MMM Do');
          }

          const prefix = isHomework ? 'Due in ' : 'Starts in<br>';

          return minutes > 0 ? `${prefix}${humanize}` : inProgress;
        }

        if (minutes === 0) {
          return `${lessonOrAssessment} starting now`;
        }

        if (minutes <= -1 * durationMinutes) {
          return `${lessonOrAssessment} Completed`;
        }

        return minutes > 0 ? `${lessonOrAssessment} starts in ${humanize}` : `${lessonOrAssessment} started ${humanize} ago`;
      })
    );
  }
}
