import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe, markControlsAsTouched } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { Address, LoadingState, StripeData } from '../models';
import { AddressPickerComponent } from '../pickers/address-picker/address-picker.component';

declare let Stripe: stripe.StripeStatic;

interface CreditCardSummary {
  stripeElement: string;
  nameOnCard: string;
  billingAddress: Address;
}

@Component({
    selector: 'kip-credit-card',
    templateUrl: './credit-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreditCardComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  readonly #stripeOptions: stripe.elements.ElementsOptions = {
    style: {
      base: {
        lineHeight: '24px',
        fontFamily: 'inherit',
        fontWeight: '400',
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontSize: '16px'
      }
    },
    hidePostalCode: true
  };

  creditCardForm!: FormGroupTypeSafe<CreditCardSummary>;

  readonly icons = Icons;
  readonly cardSetupError = 'cardSetupError';
  readonly cardValidationError = 'cardValidationError';

  card: stripe.elements.Element | undefined;
  stripe: stripe.Stripe | undefined;
  // This is needed as in the event binding we need to ref 'this'
  cardValidationHandler: stripe.elements.handler = this.#onChange.bind(this);
  useAlternateBillingAddress = false;

  get billingAddress() {
    return this.creditCardForm.getSafeForm(x => x.billingAddress);
  }

  get nameOnCard() {
    return this.creditCardForm.getSafe(x => x.nameOnCard);
  }

  get stripeElement() {
    return this.creditCardForm.getSafe(x => x.stripeElement);
  }

  @Input()
  accountAddress: Address | undefined;

  @Input()
  accountName = '';

  @Input({ required: true })
  stripeAccountInfo: StripeData | undefined;

  @Input()
  submitExternal = false;

  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef<HTMLDivElement> | undefined;

  @Output()
  readonly setupIntent = new EventEmitter<string>();

  @Output()
  readonly loadingState = new EventEmitter<LoadingState>();

  @Output()
  readonly validityChange = new EventEmitter<boolean>();

  ngOnInit() {
    this.loadingState.emit(LoadingState.Loading);

    const billingAddressForm = AddressPickerComponent.buildDefaultControls(this.#fb);
    /*eslint-disable @typescript-eslint/unbound-method */
    this.creditCardForm = this.#fb.group<CreditCardSummary>({
      stripeElement: new FormControl<string | null>(null, Validators.required),
      nameOnCard: new FormControl<string | null>(null, Validators.required),
      billingAddress: billingAddressForm
    });
    /*eslint-enable @typescript-eslint/unbound-method */

    this.billingAddress.patchValue(this.accountAddress ?? {});
    this.nameOnCard.setValue(this.accountName);

    if (!this.accountAddress) {
      this.useAlternateBillingAddress = true;
    }

    this.#subscriptions.push(
      this.creditCardForm.statusChanges.subscribe(result => {
        this.validityChange.emit(result === 'VALID');
      }));

    this.#initializeStripe();
    this.loadingState.emit(LoadingState.Complete);
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  changeUseAlternateBillingAddress() {
    this.useAlternateBillingAddress = !this.useAlternateBillingAddress;
    if (this.useAlternateBillingAddress) {
      this.billingAddress.reset();
    } else {
      if (this.accountAddress) {
        this.billingAddress.patchValue(this.accountAddress);
      }
    }
  }

  validatePaymentDetails() {
    if (this.creditCardForm.invalid) {
      markControlsAsTouched(this.creditCardForm);
      return;
    }

    this.loadingState.emit(LoadingState.Loading);
    const creditCard = this.creditCardForm.value;

    if (this.stripeAccountInfo && this.stripe && this.card) {

      /*eslint-disable @typescript-eslint/naming-convention */
      const confirmCardSetupOption = {
        payment_method: {
          card: this.card,
          billing_details: {
            name: creditCard.nameOnCard,
            address: this.#createStripeAddress(creditCard.billingAddress)
          }
        }
      };
      /*eslint-enable @typescript-eslint/naming-convention */

      this.stripe.confirmCardSetup(this.stripeAccountInfo.clientSecret, confirmCardSetupOption)
        .then(result => {
          this.loadingState.emit(LoadingState.Complete);
          if (result.error) {
            this.creditCardForm.setErrors({ [this.cardSetupError]: result.error.message });
          } else {
            this.setupIntent.emit(result.setupIntent?.payment_method ?? '');
          }
          this.#changeDetectorRef.markForCheck();
        });
    }
  }

  #initializeStripe() {
    if (this.stripeAccountInfo) {
      this.stripe = Stripe(ServiceEnvironment.value.stripePublishableApiKey,
        {
          stripeAccount: this.stripeAccountInfo.stripeAccount
        });

      const elements = this.stripe.elements();
      this.card = elements.create('card', this.#stripeOptions);
      this.card.mount(this.cardInfo?.nativeElement);
      this.card.addEventListener('change', this.cardValidationHandler);
    }
  }

  #onChange(event: stripe.elements.ElementChangeResponse) {
    if (event.empty) {
      this.stripeElement.setErrors({ [this.cardValidationError]: 'Please provide valid card details.' });
    }
    if (event.error) {
      this.stripeElement.setErrors({ [this.cardValidationError]: event.error.message });
    }
    if (event.complete) {
      this.stripeElement.setErrors(null);
    }
    this.stripeElement.markAsTouched();
    this.creditCardForm.setErrors(null);
  }

  /*eslint-disable @typescript-eslint/naming-convention */
  #createStripeAddress(billingAddress: Address): stripe.BillingDetailsAddress {
    return {
      line1: billingAddress.addressLine1,
      line2: billingAddress.addressLine2 ?? '',
      postal_code: billingAddress.postalCode,
      city: billingAddress.locality,
      state: billingAddress.dependentLocality || '',
      country: billingAddress.countryCode || 'au' // TODO: this is a hack until our address component is rebuilt.
    };
  }
  /*eslint-enable @typescript-eslint/naming-convention */
}
