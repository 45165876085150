import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateStruct } from 'moment-extensions-lib';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { allowedDaysHomework } from '../constants';

@Pipe({
    name: 'homeworkDueInDate',
    standalone: false
})
export class HomeWorkDueInDatePipe implements PipeTransform {
  transform(lessonDate: DateStruct, lessonTime: string): Observable<string> {

    return timer(0, 1000).pipe(
      map(() => {
        const dueTime = moment().toMoment(lessonDate, lessonTime).add(allowedDaysHomework, 'day').startOf('minute');
        return dueTime.format('Do MMM');
      })
    );
  }
}
