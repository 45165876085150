import { Pipe, PipeTransform } from '@angular/core';
import { Grade } from 'ui-common-lib';

@Pipe({
    name: 'gradeLevel',
    standalone: false
})
export class GradeLevelPipe implements PipeTransform {
  transform(gradeId: number | null | undefined, grades: Map<number, Grade> | undefined): string {

    if (gradeId === null) {
      return '?';
    }
    const grade = gradeId && grades ? grades.get(gradeId) : undefined;
    return grade ? grade.shortCode : '';
  }
}
