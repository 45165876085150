import { Pipe, PipeTransform } from '@angular/core';

import { PercentageResultPipe } from './percentage-result.pipe';

@Pipe({
    name: 'percentageResultAlertOpenClass',
    standalone: false
})
export class PercentageResultAlertOpenClassPipe implements PipeTransform {

  transform(percentage: number | null | undefined) {
    return PercentageResultPipe.prototype.transform(percentage, 'alert-success', 'alert-warning', 'alert-danger', 'alert-secondary');
  }
}
