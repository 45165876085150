import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { DateStruct } from 'moment-extensions-lib';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AssessmentResultType,
  Button,
  ComprehensionTest, ConfirmDialogComponent, defaultEmptyPage, PagedList, PageInfo, PageListSize,
  PageRequest, ProfileService, ReadingTest, SharedService
} from 'ui-common-lib';

import { AssessmentFilter, ResultSummary, ResultType } from '../../../models';
import { StudentService } from '../../../services';

@Component({
    selector: 'kip-student-assessment',
    templateUrl: './student-assessment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAssessmentComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #studentService = inject(StudentService);
  readonly #sharedService = inject(SharedService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #assessmentIdFromLesson: number | null = null;
  #buttons: Button[] = [];
  #assessmentResultTypes: AssessmentResultType[] = [];
  #studentId: number | undefined;
  #canEditOrDelete = false;
  #canEdit = false;
  #readingTest: ReadingTest[] = [];
  #comprehensionTest: ComprehensionTest[] = [];
  #subscriptions: Subscription[] = [];

  readonly resultsPerPage = PageListSize.TwentyFive;
  readonly icons = Icons;

  filter: AssessmentFilter = {
    assessmentResultTypeId: null
  };

  currentPageRequest: PageRequest<AssessmentFilter> = {
    pageNumber: 1,
    pageSize: this.resultsPerPage
  };

  assessmentResults: PagedList<ResultSummary> = defaultEmptyPage();
  assessmentId: number | null = null;
  assessmentResultId: number | null = null;
  editing = false;

  resultTypeSpelling: ResultType = ResultType.Spelling;

  currentLoad: Observable<PageInfo> = new Observable<PageInfo>();

  get buttons() {
    return this.#buttons;
  }

  get canEdit() {
    return this.#canEdit;
  }

  get canEditOrDelete() {
    return this.#canEditOrDelete;
  }

  get readingTest() {
    return this.#readingTest;
  }

  get comprehensionTest() {
    return this.#comprehensionTest;
  }

  get assessmentResultTypes() {
    return this.#assessmentResultTypes;
  }

  @Input() dateOfBirth: DateStruct | null | undefined;
  @Input() lessonId: number | undefined;
  @Input({ required: true }) gradeId: number | null | undefined;

  @ViewChild('confirm', { static: true }) confirm: ConfirmDialogComponent | undefined;

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.init();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @Input()
  set assessmentIdFromLesson(id: number | null) {
    this.#assessmentIdFromLesson = id;
    if (id) {
      this.assessmentId = id;
      this.editing = true;
    }
  }

  get assessmentIdFromLesson() {
    return this.#assessmentIdFromLesson;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#sharedService.getAssessmentResultTypes().subscribe(values => {
        this.#assessmentResultTypes = values;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#profileService.getCanUpdateStudentOrHasTutorRole().subscribe(value => {
        this.#canEdit = value;
        const buttons: Button[] = [];
        if (value) {
          buttons.push({ text: 'Add Assessment Results', action: () => { this.navigateNew(); } });
        }
        this.#buttons = buttons;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#profileService.getCanUpdateStudentButNotParent().subscribe(value => {
        this.#canEditOrDelete = value;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#sharedService.getReadingTests().subscribe(value => {
        this.#readingTest = value;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#sharedService.getComprehensionTests().subscribe(value => {
        this.#comprehensionTest = value;
        this.#changeDetectorRef.markForCheck();
      }));
    this.init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  init() {
    this.loadPage(this.currentPageRequest);
  }

  navigateNew() {
    this.navigate(0);
  }

  navigate(assessmentId: number) {
    if (this.canEdit || assessmentId === 0) {
      this.assessmentId = assessmentId;
      this.editing = true;
    }
  }

  updateAssessmentResultTypeId(number: number | null) {
    this.filter = { ... this.filter, assessmentResultTypeId: number };
  }

  deleteAssessmentResultDialog(assessmentResultId: number) {
    if (this.confirm) {
      this.assessmentResultId = assessmentResultId;
      this.confirm.open();
    }
  }

  deleteAssessmentResultConfirm() {
    if (this.assessmentResultId) {
      this.#subscriptions.push(
        this.#studentService.deleteAssessmentResult(this.assessmentResultId)
          .subscribe(
            {
              next: () => {
                console.log(this.assessmentResults);
              },
              error: (error: unknown) => console.log(error),
              complete: () => {
                this.loadPage(this.currentPageRequest);
                this.#changeDetectorRef.markForCheck();
              }
            }));
    }
  }

  onComplete(result: number | null) {
    this.editing = false;
    if (result) {
      this.loadPage({
        pageNumber: 1,
        pageSize: this.resultsPerPage
      });
    }
  }

  loadPage(pageToFetch: PageRequest<AssessmentFilter>) {
    this.currentPageRequest = pageToFetch;
    if (this.studentId) {
      this.currentLoad = this.#studentService.queryAssessmentsByStudentId(this.studentId, pageToFetch)
        .pipe(
          tap(assessmentResults => {
            this.assessmentResults = assessmentResults;
            this.#changeDetectorRef.markForCheck();
          }),
          map(assessmentResults => assessmentResults.pageInfo)
        );
    }
  }
}
