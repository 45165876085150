import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
  EventEmitter, HostListener, inject,
  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService, Role, UserProfile } from 'ui-common-lib';

import { QuestionNavigatorItem, QuestionNavigatorSelected } from '../models';
@Component({
    selector: 'kip-question-navigator',
    templateUrl: './navigator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuestionNavigatorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #scrollable = false;
  #profile: UserProfile | undefined;
  #isTutor = false;
  #items: readonly QuestionNavigatorItem[] = [];
  #step = 1;
  #itemHeight = 0;
  #offsetIndex = 0;
  #activeIndex = 0;
  #isUserAction = false;
  #subscriptions: Subscription[] = [];

  readonly isUpScrollDisabled = false;
  readonly isDownScrollDisabled = false;

  get #clientHeight(): number | undefined {
    return this.container?.nativeElement?.clientHeight;
  }

  get #scrollHeight(): number | undefined {
    return this.container?.nativeElement?.scrollHeight;
  }

  readonly icons = Icons;

  get isTutor(): boolean {
    return this.#isTutor;
  }

  get scrollable(): boolean {
    return this.#scrollable;
  }

  @Input() showCorrect = true;
  @Input() disabled = false;
  @Input() blockSkipped = false;
  @Input() whiteboardQuestionId: number | undefined;
  @Input() activeQuestionIndex: number | undefined;
  @Input({ required: true }) set items(value: readonly QuestionNavigatorItem[]) {
    if (this.#items !== value) {
      const itemCount = this.#items.length;
      this.#items = value;
      if (itemCount !== value.length) {
        this.render();
      }
    }
  }

  get items() {
    return this.#items;
  }

  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef<HTMLUListElement> | undefined;

  @Output() readonly navigate = new EventEmitter<QuestionNavigatorSelected>();

  ngOnChanges(changes: SimpleChanges) {
    if (!this.#isUserAction) {
      if (changes.activeQuestionId) {
        this.#centerSelected();
      } else if (changes.items && !this.items.some(item => item.icon?.iconName === 'user-edit')) {
        this.ngAfterViewInit();
        this.#centerSelected();
      }
    }
    this.#isUserAction = false;
  }

  ngOnInit() {
    this.#subscriptions.push(
      this.#router.events.subscribe(event => {
        if (event instanceof NavigationStart && event.navigationTrigger === 'popstate' && this.activeQuestionIndex === 0) {
          this.#router.navigate(['../../'], { relativeTo: this.#route });
        }
      }),
      this.#profileService
        .getUserProfile()
        .subscribe(profile => {
          this.#profile = profile;
          this.#isTutor = this.#isInRole(Role.Tutor) || this.#isInRole(Role.CentreManager);
          this.#changeDetectorRef.markForCheck();
        }));
  }

  ngAfterViewInit() {
    // HACK ALERT: Check until the navigation area settles then calculate heights
    this.render();
    let currentHeight = this.#clientHeight;
    let settleCount = 0;
    const interval = window.setInterval(() => {
      if (currentHeight === this.#clientHeight && settleCount++ >= 10) {
        this.render();
        this.#changeDetectorRef.markForCheck();
        window.clearInterval(interval);
      }
      currentHeight = this.#clientHeight;
    }, 100);
    this.#changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  click(item: QuestionNavigatorItem, whiteboard: boolean) {
    if (!this.disabled && (!item.skipped || !this.blockSkipped)) {
      this.#isUserAction = true;
      this.navigate.emit({ id: item.id, whiteboard: whiteboard, index: this.items.indexOf(item) });
    }
  }

  @HostListener('window:resize')
  render() {
    if (this.#scrollHeight && this.#clientHeight) {
      this.#scrollable = this.#scrollHeight > this.#clientHeight;
      if (this.#scrollable && this.items.length > 0 && this.container && this.container.nativeElement.children.length > 1) {
        const spacerHeight = this.container.nativeElement.children[0].clientHeight;
        const buttonHeight = this.container.nativeElement.children[1].clientHeight;
        this.#itemHeight = spacerHeight + buttonHeight;
        this.#step = Math.floor(this.#clientHeight / this.#itemHeight);
        this.#activeIndex = -1;
        this.#centerSelected();
      }
    } else {
      this.#scrollable = false;
    }
    this.#changeDetectorRef.detectChanges();
  }

  scrollUp() {
    this.#scrollToIndex(this.#offsetIndex - this.#step);
  }

  scrollDown() {
    this.#scrollToIndex(this.#offsetIndex + this.#step);
  }

  trackBy(_index: number, item: QuestionNavigatorItem) {
    return item.id;
  }

  #centerSelected() {
    const activeItem = this.activeQuestionIndex !== undefined ? this.items[this.activeQuestionIndex] : this.items.find(item => !!item.icon);
    if (activeItem) {
      this.#centerActiveItem(activeItem);
    }
  }

  #centerActiveItem(activeItem: QuestionNavigatorItem) {
    const targetIndex = this.items.indexOf(activeItem);
    if (this.#activeIndex !== targetIndex && this.#clientHeight) {
      this.#activeIndex = targetIndex;
      this.#scrollToIndex(targetIndex - Math.floor(this.#clientHeight / this.#itemHeight / 2));
    }
  }

  #scrollToIndex(index: number) {
    let startIndex = index;
    if (startIndex < 0) {
      startIndex = 0;
    } else if (startIndex > this.items.length - this.#step) {
      startIndex = this.items.length - this.#step;
    }
    if (startIndex !== this.#offsetIndex) {
      this.#offsetIndex = startIndex;
      if (this.container) {
        this.container.nativeElement.scrollTop = startIndex * this.#itemHeight;
      }
    }
  }

  #isInRole(role: string) {
    if (this.#profile) {
      return this.#profile.roles.includes(role);
    }

    return false;
  }
}
