/* eslint-disable @angular-eslint/no-forward-ref, kip/decorators-out-of-order-before-constructor */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface BaseMultiple {
  id: number | string;
  name: string;
  checked: boolean;
  subGroups?: readonly string[];
}

@Component({
    selector: 'kip-base-multiple-picker',
    templateUrl: './base-multiple-picker.component.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseMultiplePickerComponent),
            multi: true
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BaseMultiplePickerComponent implements ControlValueAccessor, OnDestroy {

  protected ids: (number | string)[] = [];
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected subscriptions: Subscription[] = [];

  multipleValues: BaseMultiple[] = [];

  get values(): (number | string)[] {
    return this.multipleValues.filter(s => s.checked).map(s => s.id);
  }

  @Input() optional = false;
  @Input() disabled = false;
  @Input({ required: true }) name: string | undefined = '';
  @Input({ required: true }) label: string | undefined = '';
  @Input() labelClass: string | undefined;

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  onChange = (_ids: (number | string)[]) => {
    // wire up as required
  };
  onTouched = () => {
    // wire up as required
  };

  registerOnChange(fn: (ids: number[]) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(ids: (number | string)[]) {
    if (this.values !== ids) {
      this.ids = ids;
      for (const multipleValue of this.multipleValues) {
        multipleValue.checked = ids.includes(multipleValue.id);
      }
      this.changeDetectorRef.markForCheck();
      this.onChange(ids);
    }
  }

  onCheckChange() {
    this.writeValue(this.values);
    this.onTouched();
  }

}
