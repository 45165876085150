import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Observable, Subscription } from 'rxjs';

import { WhiteboardGraphOptions } from '../models';
import { GraphToolService } from './services';

@Component({
    selector: 'kip-graph-options-modal',
    templateUrl: './whiteboard-toolbar-graph-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WhiteboardToolbarGraphModalComponent implements OnInit, OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #modalService = inject(NgbModal);
  readonly #graphToolService = inject(GraphToolService);

  #subscriptions: Subscription[] = [];

  openGraphToolOptions$: Observable<void> | undefined;
  readonly icons = Icons;

  toolbarGraphForm!: FormGroupTypeSafe<WhiteboardGraphOptions>;
  tutorId: number | undefined;

  get xMax() {
    return this.toolbarGraphForm.getSafe(x => x.xMax);
  }

  get xMin() {
    return this.toolbarGraphForm.getSafe(x => x.xMin);
  }

  get yMax() {
    return this.toolbarGraphForm.getSafe(x => x.yMax);
  }

  get yMin() {
    return this.toolbarGraphForm.getSafe(x => x.yMin);
  }

  @ViewChild('graphToolModal', { static: false }) graphToolModal: TemplateRef<any> | undefined;

  ngOnInit() {
    this.openGraphToolOptions$ = this.#graphToolService.openWhiteboardGraphOptionModal.asObservable();

    this.#subscriptions.push(this.openGraphToolOptions$.subscribe(() => {
      this.open();
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  resetValidity(control: FormControl<number>) {
    control.updateValueAndValidity();
  }

  submit() {
    if (this.toolbarGraphForm.invalid) {
      return;
    }

    this.#modalService.dismissAll();
    this.#graphToolService.emitWhiteboardGraphOptionsSubmitted({
      xMax: this.toolbarGraphForm.value.xMax,
      xMin: this.toolbarGraphForm.value.xMin,
      yMax: this.toolbarGraphForm.value.yMax,
      yMin: this.toolbarGraphForm.value.yMin
    });
  }

  setupForm() {
    /*eslint-disable @typescript-eslint/unbound-method */
    const xMaxFormControl = new FormControl<number>(100, [Validators.required]);
    const xMinFormControl = new FormControl<number>(0, [Validators.required]);
    const yMaxFormControl = new FormControl<number>(100, [Validators.required]);
    const yMinFormControl = new FormControl<number>(0, [Validators.required]);

    xMaxFormControl.addValidators(this.#customValidator(xMinFormControl, true));
    xMinFormControl.addValidators(this.#customValidator(xMaxFormControl, false));

    yMaxFormControl.addValidators(this.#customValidator(yMinFormControl, true));
    yMinFormControl.addValidators(this.#customValidator(yMaxFormControl, false));
    /*eslint-enable @typescript-eslint/unbound-method */

    this.toolbarGraphForm = this.#fb.group<WhiteboardGraphOptions>({
      xMax: xMaxFormControl,
      xMin: xMinFormControl,
      yMax: yMaxFormControl,
      yMin: yMinFormControl
    });
  }

  close() {
    this.#modalService.dismissAll();
  }

  open() {
    this.setupForm();
    this.#modalService.open(this.graphToolModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      modalDialogClass: 'kip-modal-themed theme-aurora bg-none'
    });
  }

  #hasMoreThanTwoDecimalPlaces(number: number | null) {
    if (number === null) {
      return false;
    }
    const decimalCount = (number.toString().split('.')[1] || '').length;
    return decimalCount > 2;
  }

  // custom validation method to restrict:
  // * the yMax is greater than the yMin (or yMin < yMax)
  // * the xMax is greater than the xMin (or xMin < xMax)
  // * the values are no longer than 2 decimal places
  #customValidator(relativeControl: AbstractControl, shouldBeGreaterThan: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validation: any[] = [];

      if (!control.value && control.value !== 0) {
        validation.push({ required: true });
      }

      if (shouldBeGreaterThan) {
        const maxValue = control.value;
        const minValue = relativeControl.value;

        const rangeValidation = maxValue < minValue ? { invalidRange: true } : null;
        validation.push(rangeValidation);
      } else {
        const maxValue = relativeControl.value;
        const minValue = control.value;

        const rangeValidation = maxValue < minValue ? { invalidRange: true } : null;
        validation.push(rangeValidation);
      }

      if (this.#hasMoreThanTwoDecimalPlaces(control.value)) {
        validation.push({ precisionInvalid: true });
      }

      return validation.reduce((result, currentObject) => {
        return { ...result, ...currentObject };
      });
    };
  }

}
