import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { SkinTone } from '../icons';
import { Award } from '../models';
import { AwardsService } from '../services';

@Component({
    selector: 'kip-awards-filtered-list',
    templateUrl: './awards-filtered-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AwardsFilteredListComponent implements OnInit, OnDestroy {

  readonly #awardsService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #keyword = '';
  #awards: Award[] = [];
  #filteredIcons: Award[] = [];
  #subscriptions: Subscription[] = [];

  readonly skinTone = SkinTone.SkinToneA;

  get filteredIcons() {
    return this.#filteredIcons;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set keyword(value: string) {
    this.#keyword = value;
    this.#setFilteredIcons(value);
  }

  get keyword() {
    return this.#keyword;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Output()
  readonly awardPicked = new EventEmitter<Award>();

  ngOnInit() {
    this.#subscriptions.push(
      this.#awardsService.getAwards().subscribe(awards => {
        this.#awards = awards;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  pick(value: Award) {
    this.awardPicked.emit(value);
  }

  #setFilteredIcons(value = '') {
    this.#filteredIcons = this.#filterIcons(value);
  }

  #filterIcons(keyword: string) {
    if (keyword === '') {
      return [];
    }

    return this.#awards.filter((icon: Award) =>
      icon.label.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
      icon.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
    );
  }
}
