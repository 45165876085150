import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { serverError } from '../helpers/typed-form';

@Component({
    selector: 'kip-form-error',
    templateUrl: './form-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormErrorComponent implements OnDestroy {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #formGroup: FormGroup | undefined;
  #subscriptions: Subscription[] = [];

  get errors() {

    if (this.formGroup) {
      if (this.formGroup.hasError(serverError)) {
        return this.formGroup.getError(serverError) as string[];
      }
      const errors: string[] = [];

      // assumes form group errors are always strings

      for (const error in this.formGroup.errors) {
        errors.push(this.formGroup.getError(error) as string);
      }
      return errors;
    }

    return [];
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set formGroup(value: FormGroup | undefined) {
    this.#formGroup = value;
    if (value) {
      this.#subscriptions.push(
        value.statusChanges.subscribe(() => {
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }

  get formGroup() {
    return this.#formGroup;
  }

  /* eslint-enable kip/no-unused-public-members */

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

}
