import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noteText',
    standalone: false
})
export class NoteTextPipe implements PipeTransform {

    transform(internalNote: string | undefined, parentNote: string | undefined, focusNote: string | undefined, text: string): string {

        return `${internalNote || parentNote || focusNote ? 'Edit' : 'Add'} ${text}`;
    }
}
