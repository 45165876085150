import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';
import { commonRegex, CommonValidators, ConfirmPassword, Student } from 'ui-common-lib';

import { StudentPasswordReset } from '../models';
import { StudentService } from '../services';

@Component({
    selector: 'kip-student-edit-password-base',
    templateUrl: './student-edit-password-base.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentEditPasswordBaseComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #studentId: number | undefined;
  #student: Student | undefined;
  #subscriptions: Subscription[] = [];

  get id() {
    return this.#student?.id ?? 0;
  }

  get username() {
    return this.#student?.username ?? '';
  }

  get password() {
    return this.passwordForm.getSafe(p => p.password);
  }

  @Input() passwordForm!: FormGroupTypeSafe<ConfirmPassword & StudentPasswordReset>;
  @Input() showHeader = true;

  /* eslint-disable kip/no-unused-public-members */

  @Input() set studentId(value: number | undefined) {
    if (this.#studentId !== value) {

      this.#studentId = value;
      if (value) {
        this.#subscriptions.push(
          this.#studentService.getStudentInfoById(value)
            .subscribe(student => {
              this.#student = student;
              this.#changeDetectorRef.markForCheck();
            }));
      }
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @Input() set student(value: Student | undefined) {
    if (this.#student !== value) {
      this.#student = value;
    }
  }

  get student() {
    return this.#student;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly submitted = new EventEmitter<string>();
  @Output() readonly cancelled = new EventEmitter();

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */

    this.passwordForm = this.#fb.group<ConfirmPassword & StudentPasswordReset>({
      password: new FormControl<string | null>(null, [Validators.required, Validators.pattern(commonRegex.password), CommonValidators.hasUniqueCharacter]),
      confirmPassword: new FormControl<string | null>(null, Validators.required)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    const password = this.password.value;
    this.#subscriptions.push(
      this.#studentService.resetPassword(this.id, this.passwordForm.value).subscribe(
        {
          next: value => {
            if (value === null) {
              this.submitted.emit(password);
            }
          },
          error: (error: unknown) => {
            this.passwordForm.handleServerErrors(error);
            this.#changeDetectorRef.markForCheck();
          }
        }));
  }
}
