import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { derivedAsync } from 'ngxtension/derived-async';

import { Feature, FeatureFlagService } from './feature-flag.service';

@Component({
    selector: 'kip-feature-item',
    templateUrl: './feature-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FeatureItemComponent {

  readonly #featureFlagService = inject(FeatureFlagService);

  readonly route = input.required<string>();
  readonly text = input.required<string>();
  readonly featureFlag = input.required<Feature>();
  readonly isFeatureEnabled = derivedAsync(() => {
    return this.#featureFlagService.isFeatureEnabled(this.featureFlag());
  }, { initialValue: false });

}
