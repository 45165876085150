import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { StudentActivityData } from 'explore-lib';
import { RegionId } from 'questions-lib';
import { Signalify } from 'ui-common-lib';

import { LessonHistory, LessonHistoryActivity } from '../../../../models';

@Component({
    selector: 'kip-activity-review',
    templateUrl: './activity-review.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ActivityReviewComponent {

  readonly previewHistory = input.required<Signalify<LessonHistory> | null>();
  readonly studentId = input.required<number | undefined>();
  readonly regionId = input.required<RegionId>();
  readonly studentData = computed(() => {
    const value = this.previewHistory();
    let studentData: StudentActivityData | undefined;
    if (!value) {
      studentData = undefined;
    } else {
      const lessonHistoryActivity: Signalify<LessonHistoryActivity> = value.activities.length > 0 ? value.activities[0] : value.homework[0];
      studentData = {
        lessonGuid: value.lessonGuid,
        activityGuid: lessonHistoryActivity.activityGuid,
        name: lessonHistoryActivity.name,
        activityId: lessonHistoryActivity.activityId,
        whiteboardPages: lessonHistoryActivity.whiteboardPages,
        files: lessonHistoryActivity.files,
        pdfFile: lessonHistoryActivity.pdfFile,
        adHocPdfFile: lessonHistoryActivity.adHocPdfFile,
        lessonActivityPlanId: lessonHistoryActivity.id
      };
    }
    return studentData;
  });
  readonly clear = output();

}
