import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { SharedService } from '../../services';

@Component({
    selector: 'kip-region-picker',
    templateUrl: './region-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RegionPickerComponent {

  readonly #sharedService = inject(SharedService);

  readonly regions = toSignal(this.#sharedService.getRegions(), { initialValue: [] });
  readonly regionId = input.required<FormControl<number | null>>();
  readonly label = input.required<string>();
  readonly description = input('');
  readonly name = input.required<string>();
  readonly labelClass = input<string | undefined>(undefined);

}
