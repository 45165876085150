import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

import { StudentRankingDetails, StudentSummary } from '../../models';

@Component({
    selector: 'kip-student-summary',
    templateUrl: './student-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentSummaryComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #canEdit = false;
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get canEdit() {
    return this.#canEdit;
  }

  @Input({ required: true }) student: StudentSummary | undefined;
  @Input() ranking: StudentRankingDetails | undefined;

  @Output() readonly edit = new EventEmitter();

  ngOnInit() {
    this.#init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #init() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }

}
