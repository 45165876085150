import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { Tree } from '../models';
import { TreePickerService } from '../services';

@Component({
    selector: 'kip-tree-progress',
    templateUrl: './tree-progress.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreeProgressComponent implements OnDestroy {

  readonly #treePickerService = inject(TreePickerService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #studentId = 0;
  #trees: Tree[] = [];
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get trees() {
    return this.#trees;
  }

  @Input() set studentId(value: number) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#subscriptions.push(this.#treePickerService.getProgress(value).subscribe(trees => {
        this.#trees = trees;
        this.#changeDetectorRef.markForCheck();
      }
      ));
    }
  }

  get studentId() {
    return this.#studentId;
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }
}