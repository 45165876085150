import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-graduated-girl-e',
    templateUrl: './graduated-girl-e.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconGraduatedGirlEComponent implements IconComponent {

}
