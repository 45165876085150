
import { CdkDrag } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';
/*
  Original code taken from https://www.calculator.net/calculator.html and heavily modified to fit the needs of the project.
*/

enum CalculatorAction {

  // row 1

  Sin = 'sin',
  Cos = 'cos',
  Tan = 'tan',

  // row 2

  Asin = 'asin',
  Acos = 'acos',
  Atan = 'atan',
  Pi = 'pi',
  E = 'e',

  // row 3

  Pow = 'pow',
  XCubed = 'XCubed',
  XSquared = 'XSquared',
  EToX = 'EToX',
  TenToX = 'TenToX',

  // row 4

  APow = 'APow',
  CubeRoot = 'CubeRoot',
  SquareRoot = 'SquareRoot',
  Ln = 'ln',
  Log = 'log',

  // row 5

  OpenBracket = '(',
  CloseBracket = ')',
  OneOverX = '1/x',
  Percent = 'Percent',
  N = 'n!',

  // row 6

  Number7 = '7',
  Number8 = '8',
  Number9 = '9',
  Plus = '+',
  MemorySave = 'MS',

  // row 7

  Number4 = '4',
  Number5 = '5',
  Number6 = '6',
  Minus = '-',
  MemoryPlus = 'M+',

  // row 8

  Number1 = '1',
  Number2 = '2',
  Number3 = '3',
  Multiply = '*',
  MemoryMinus = 'M-',

  // row 9

  Number0 = '0',
  Decimal = '.',
  Exp = 'exp',
  Divide = '/',
  MemoryRecall = 'MR',

  // row 10

  PlusMinus = '+/-',
  Random = 'Random',
  Clear = 'Clear',
  Equals = '=',
  MemoryClear = 'MC',

  // Empty

  Empty = 'empty'
}

enum DegreesRadians {
  Degree = 'Degree',
  Radians = 'Radians'
}

interface StackItem {
  value: number;
  calculatorAction: CalculatorAction;
  vg: number;
}

@Component({
    selector: 'kip-calculator',
    styleUrl: './calculator.component.scss',
    templateUrl: './calculator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalculatorComponent implements OnInit {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #uI: StackItem[] = [];
  #hj = 0;
  readonly #totalDigits = 12;
  readonly #pareSize = 12;
  #value = 0;
  #valueIsNan = false;
  #memory = 0;
  #level = 0;
  #entered = true;
  #decimal = 0;
  #fixed = 0;
  #exponent = false;
  #digits = 0;
  #result: string | undefined = '0';
  readonly #debug = true;

  readonly degreeOrRadians = DegreesRadians;
  readonly calculatorAction = CalculatorAction;
  readonly icons = Icons;

  degreeRadians = DegreesRadians.Degree;
  showScienceCalculator = false;

  get result() {
    return this.#valueIsNan ? 'NAN' : this.#result;
  }

  @Input({ required: true }) regionId: RegionId = RegionId.Australia;

  @ViewChild(CdkDrag, { static: true }) dragElem?: CdkDrag;

  ngOnInit() {
    const ui: StackItem[] = [];
    for (let i = 0; i < this.#pareSize; ++i) {
      {
        ui.push({ value: 0, calculatorAction: CalculatorAction.Empty, vg: 0 });
      }
    }
    this.#uI = ui;
  }

  performAction(calculatorAction: CalculatorAction) {
    if (this.#debug) {
      console.log(calculatorAction);
    }
    let handled = this.#performFunc(calculatorAction);
    if (!handled) {
      handled = this.#performNumberInput(calculatorAction);
      if (!handled) {
        handled = this.#performOption(calculatorAction);
        if (!handled) {

          /* eslint-disable @typescript-eslint/switch-exhaustiveness-check */

          switch (calculatorAction) {
            case CalculatorAction.OpenBracket:
              this.#bracketOpen();
              break;
            case CalculatorAction.CloseBracket:
              this.#bracketClose();
              break;
            case CalculatorAction.Exp:
              this.#exp();
              break;
            case CalculatorAction.Decimal:
              if (this.#entered) {
                this.#value = 0;
                this.#digits = 1;
              }
              this.#entered = false;
              if (this.#decimal === 0 && this.#value === 0 && this.#digits === 0) {
                this.#digits = 1;
              }
              if (this.#decimal === 0) {
                this.#decimal = 1;
              }
              this.#refresh();
              break;
            case CalculatorAction.PlusMinus:
              if (this.#exponent) {
                this.#hj = -this.#hj;
              } else {
                this.#value = -this.#value;
              }
              this.#refresh();
              break;
            case CalculatorAction.Clear:
              this.#level = 0;
              this.#exponent = false;
              this.#value = 0;
              this.#valueIsNan = false;
              this.#enter();
              this.#refresh();
              break;
            case CalculatorAction.Equals:
              this.#enter();
              while (this.#level > 0) {
                this.#evaluate();
              }
              this.#refresh();
              break;
          }

          /* eslint-enable @typescript-eslint/switch-exhaustiveness-check */
        }
      }
    }
  }

  #push(value: number, calculatorAction: CalculatorAction, vg: number) {
    if (this.#level === this.#pareSize) {
      return false;
    }
    for (let i = this.#level; i > 0; --i) {
      this.#uI[i].value = this.#uI[i - 1].value;
      this.#uI[i].calculatorAction = this.#uI[i - 1].calculatorAction;
      this.#uI[i].vg = this.#uI[i - 1].vg;
    }
    this.#uI[0].value = value;
    this.#uI[0].calculatorAction = calculatorAction;
    this.#uI[0].vg = vg;
    ++this.#level;
    return true;
  }

  #pop() {
    if (this.#level === 0) {
      return false;
    }
    for (let i = 0; i < this.#level; ++i) {
      this.#uI[i].value = this.#uI[i + 1].value;
      this.#uI[i].calculatorAction = this.#uI[i + 1].calculatorAction;
      this.#uI[i].vg = this.#uI[i + 1].vg;
    }
    --this.#level;
    return true;
  }

  #format(inputValue: number) {
    let valueToFormat = inputValue;
    let E = `${valueToFormat}`;
    if (E.includes('N') || valueToFormat === 2 * valueToFormat && valueToFormat === 1 + valueToFormat) {
      return 'Error ';
    }
    let G = E.indexOf('e');
    if (G >= 0) {
      const A = E.slice(G + 1, E.length);
      if (G > 11) {
        G = 11;
      }
      E = E.slice(0, Math.max(0, G));
      if (!E.includes('.')) {
        E += '.';
      } else {
        let j = E.length - 1;
        while (j >= 0 && E.charAt(j) === '0') {
          --j;
        }
        E = E.slice(0, Math.max(0, j + 1));
      }
      E += ` ${A}`;
    } else {
      let J = false;
      if (valueToFormat < 0) {
        valueToFormat = -valueToFormat;
        J = true;
      }
      let C = Math.floor(valueToFormat);
      const K = valueToFormat - C;
      let D = this.#totalDigits - `${C}`.length - 1;
      if (!this.#entered && this.#fixed > 0) {
        D = this.#fixed;
      }
      let F: number | string = ' 1000000000000000000'.slice(1, D + 2);
      F = F === '' || F === ' ' ? 1 : Number.parseInt(F, 10);
      const B = Math.floor(K * F + 0.5);
      C = Math.floor(Math.floor(valueToFormat * F + 0.5) / F);
      E = J ? `-${C}` : `${C}`;
      let H = `00000000000000${B}`;
      H = H.slice(H.length - D, H.length);
      G = H.length - 1;
      if (this.#entered || this.#fixed === 0) {
        while (G >= 0 && H.charAt(G) === '0') {
          --G;
        }
        H = H.slice(0, Math.max(0, G + 1));
      }
      if (G >= 0) {
        E += `.${H}`;
      }
    }
    return E;
  }

  #refresh() {
    let formattedValue = this.#format(this.#value);
    if (this.#exponent) {
      formattedValue += this.#hj < 0 ? ` ${this.#hj}` : ` +${this.#hj}`;
    }
    if (!formattedValue.includes('.') && formattedValue !== 'Error ') {
      formattedValue += this.#entered || this.#decimal > 0 ? '.' : ' ';
    }
    this.#result = '' === formattedValue ? ' ' : formattedValue;
    this.#changeDetectorRef.markForCheck();
  }

  #evaluate() {
    if (this.#level === 0) {
      return false;
    }
    const calculatorAction = this.#uI[0].calculatorAction;
    const value = this.#uI[0].value;

    /* eslint-disable @typescript-eslint/switch-exhaustiveness-check */

    switch (calculatorAction) {
      case CalculatorAction.Plus:
        this.#value = Number.parseFloat(value.toString()) + this.#value;
        break;
      case CalculatorAction.Minus:
        this.#value = value - this.#value;
        break;
      case CalculatorAction.Multiply:
        this.#value = value * this.#value;
        break;
      case CalculatorAction.Divide:
        this.#value = value / this.#value;
        break;
      case CalculatorAction.Pow:
        this.#value = Math.pow(value, this.#value);
        break;
      case CalculatorAction.APow:
        this.#value = Math.pow(value, 1 / this.#value);
        break;
    }

    /* eslint-enable @typescript-eslint/switch-exhaustiveness-check */

    this.#pop();
    return calculatorAction !== CalculatorAction.OpenBracket;
  }

  #bracketOpen() {
    this.#enter();
    this.#valueIsNan = !this.#push(0, CalculatorAction.OpenBracket, 0);
    this.#refresh();
  }

  #bracketClose() {
    this.#enter();
    while (this.#evaluate()) {
      // loop
    }
    this.#refresh();
  }

  #performOption(calculatorAction: CalculatorAction) {

    let vg: number | undefined;
    switch (calculatorAction) {
      case CalculatorAction.Plus:
        vg = 1;
        break;
      case CalculatorAction.Minus:
        vg = 1;
        break;
      case CalculatorAction.Multiply:
        vg = 2;
        break;
      case CalculatorAction.Divide:
        vg = 2;
        break;
      case CalculatorAction.Pow:
        vg = 3;
        break;
      case CalculatorAction.APow:
        vg = 3;
        break;
      default:
        return false;
    }

    this.#enter();
    if (this.#level > 0 && vg <= this.#uI[0].vg) {
      this.#evaluate();
    }
    this.#valueIsNan = !this.#push(this.#value, calculatorAction, vg);
    this.#refresh();

    return true;
  }

  #enter() {
    if (this.#exponent) {
      this.#value = this.#value * Math.exp(this.#hj * Math.LN10);
    }
    this.#entered = true;
    this.#exponent = false;
    this.#decimal = 0;
    this.#fixed = 0;
  }

  #performNumberInput(calculatorAction: CalculatorAction) {

    let numberEntered: number | undefined;
    switch (calculatorAction) {
      case CalculatorAction.Number0:
        numberEntered = 0;
        break;
      case CalculatorAction.Number1:
        numberEntered = 1;
        break;
      case CalculatorAction.Number2:
        numberEntered = 2;
        break;
      case CalculatorAction.Number3:
        numberEntered = 3;
        break;
      case CalculatorAction.Number4:
        numberEntered = 4;
        break;
      case CalculatorAction.Number5:
        numberEntered = 5;
        break;
      case CalculatorAction.Number6:
        numberEntered = 6;
        break;
      case CalculatorAction.Number7:
        numberEntered = 7;
        break;
      case CalculatorAction.Number8:
        numberEntered = 8;
        break;
      case CalculatorAction.Number9:
        numberEntered = 9;
        break;
      default:
        return false;
    }

    if (this.#entered) {
      this.#value = 0;
      this.#digits = 0;
      this.#entered = false;
    }

    if (numberEntered === 0 && this.#digits === 0) {
      this.#refresh();
      return true;
    }

    if (this.#exponent) {
      if (this.#hj < 0) {
        numberEntered = -numberEntered;
      }
      if (this.#digits < 3) {
        this.#hj = this.#hj * 10 + numberEntered;
        ++this.#digits;
        this.#refresh();
      }
      return true;
    }
    if (this.#value < 0) {
      numberEntered = -numberEntered;
    }

    if (this.#digits < this.#totalDigits - 1) {
      ++this.#digits;
      if (this.#decimal > 0) {
        this.#decimal = this.#decimal * 10;
        this.#value = this.#value + numberEntered / this.#decimal;
        ++this.#fixed;
      } else {
        this.#value = this.#value * 10 + numberEntered;
      }
    }

    this.#refresh();

    return true;
  }

  #exp() {
    if (this.#entered || this.#exponent) {
      return;
    }
    this.#exponent = true;
    this.#hj = 0;
    this.#digits = 0;
    this.#decimal = 0;
    this.#refresh();
  }

  #performFunc(calculatorAction: CalculatorAction) {

    let handled = false;

    /* eslint-disable @typescript-eslint/switch-exhaustiveness-check */

    switch (calculatorAction) {
      case CalculatorAction.OneOverX:
        this.#value = 1 / this.#value;
        handled = true;
        break;
      case CalculatorAction.Percent:
        this.#value = this.#value / 100;
        handled = true;
        break;
      case CalculatorAction.N:
        if (this.#value < 0 || this.#value > 200 || this.#value !== Math.round(this.#value)) {
          this.#valueIsNan = true;
        } else {
          this.#valueIsNan = false;
          let n = 1;
          for (let multiplier = 1; multiplier <= this.#value; ++multiplier) {
            n *= multiplier;
          }
          this.#value = n;
        }
        handled = true;
        break;
      case CalculatorAction.MemoryRecall:
        this.#value = this.#memory;
        handled = true;
        break;
      case CalculatorAction.MemoryPlus:
        this.#memory += this.#value;
        handled = true;
        break;
      case CalculatorAction.MemorySave:
        this.#memory = this.#value;
        handled = true;
        break;
      case CalculatorAction.MemoryClear:
        this.#memory = 0;
        handled = true;
        break;
      case CalculatorAction.MemoryMinus:
        this.#memory -= this.#value;
        handled = true;
        break;
      case CalculatorAction.Asin:
        this.#value = this.degreeRadians === DegreesRadians.Degree ? Math.asin(this.#value) * 180 / Math.PI : Math.asin(this.#value);
        handled = true;
        break;
      case CalculatorAction.Acos:
        this.#value = this.degreeRadians === DegreesRadians.Degree ? Math.acos(this.#value) * 180 / Math.PI : Math.acos(this.#value);
        handled = true;
        break;
      case CalculatorAction.Atan:
        this.#value = this.degreeRadians === DegreesRadians.Degree ? Math.atan(this.#value) * 180 / Math.PI : Math.atan(this.#value);
        handled = true;
        break;
      case CalculatorAction.E:
        this.#value = Math.E;
        handled = true;
        break;
      case CalculatorAction.EToX:
        this.#value = Math.pow(Math.E, this.#value);
        handled = true;
        break;
      case CalculatorAction.TenToX:
        this.#value = Math.pow(10, this.#value);
        handled = true;
        break;
      case CalculatorAction.XCubed:
        this.#value = this.#value * this.#value * this.#value;
        handled = true;
        break;
      case CalculatorAction.CubeRoot:
        this.#value = Math.pow(this.#value, 1 / 3);
        handled = true;
        break;
      case CalculatorAction.XSquared:
        this.#value = this.#value * this.#value;
        handled = true;
        break;
      case CalculatorAction.Sin:
        this.#value = this.degreeRadians === DegreesRadians.Degree ? Math.sin(this.#value / 180 * Math.PI) : Math.sin(this.#value);
        handled = true;
        break;
      case CalculatorAction.Cos:
        if (this.degreeRadians === DegreesRadians.Degree) {
          let calculateValue = this.#value % 360;
          if (calculateValue < 0) {
            calculateValue = calculateValue + 360;
          }
          if (calculateValue === 90) {
            this.#value = 0;
          } else {
            this.#value = calculateValue === 270 ? 0 : Math.cos(this.#value / 180 * Math.PI);
          }
        } else {
          let calculatedValue = this.#value * 180 / Math.PI % 360;
          if (calculatedValue < 0) {
            calculatedValue = calculatedValue + 360;
          }
          this.#value = Math.abs(calculatedValue - 90) < 1e-10 || Math.abs(calculatedValue - 270) < 1e-10 ? 0 : Math.cos(this.#value);
        }
        handled = true;
        break;
      case CalculatorAction.Tan:
        this.#value = this.degreeRadians === DegreesRadians.Degree ? Math.tan(this.#value / 180 * Math.PI) : Math.tan(this.#value);
        handled = true;
        break;
      case CalculatorAction.Log:
        this.#value = Math.log(this.#value) / Math.LN10;
        handled = true;
        break;
      case CalculatorAction.Ln:
        this.#value = Math.log(this.#value);
        handled = true;
        break;
      case CalculatorAction.SquareRoot:
        this.#value = Math.sqrt(this.#value);
        handled = true;
        break;
      case CalculatorAction.Pi:
        this.#value = Math.PI;
        handled = true;
        break;
      case CalculatorAction.Random:
        this.#value = Math.random();
        handled = true;
        break;
    }

    if (handled) {
      this.#enter();
    }

    /* eslint-enable @typescript-eslint/switch-exhaustiveness-check */

    this.#refresh();

    return handled;
  }
}
