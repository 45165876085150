import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { BundleCurrency, BundleGrade, BundleSelected, BundleSelector, BundleSpecial } from '../../models';
import { LessonEnrolmentService } from '../../services';

@Component({
    selector: 'kip-enrolment-view-bundles',
    templateUrl: './enrolment-view-bundles.component.html',
    styleUrl: './enrolment-view-bundles.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnrolmentViewBundlesComponent implements OnInit, OnDestroy {

  readonly #lessonEnrolmentService = inject(LessonEnrolmentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #centreId = 1;
  #gradeId: number | null = null;
  #preferredCurrencyId = 1;
  #bundleSelected: BundleSelected = {};
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  bundleSelector: BundleSelector = { bundleGrades: [], bundleTypes: [], specials: [] };

  @Input({ required: true }) selectView = true;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set centreId(value: number) {
    if (this.#centreId !== value) {
      this.#centreId = value;
      this.#loadBundleSelector();
    }
  }

  get centreId() {
    return this.#centreId;
  }

  @Input() set gradeId(value: number | null) {
    if (this.#gradeId !== value) {
      this.#gradeId = value;
      this.#selectedPreferredBundle();
    }
  }

  get gradeId() {
    return this.#gradeId;
  }

  @Input() set preferredCurrencyId(value: number) {
    if (this.#preferredCurrencyId !== value) {
      this.#preferredCurrencyId = value;
      this.#selectedPreferredBundle();
    }
  }

  get preferredCurrencyId() {
    return this.#preferredCurrencyId;
  }

  @Input({ required: true }) set bundleSelected(value: BundleSelected) {
    if (this.#bundleSelected !== value) {
      this.#bundleSelected = value;
      this.#selectedBasedOnSelection();
    }
  }

  get bundleSelected() {
    return this.#bundleSelected;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly bundleSelectedChange = new EventEmitter<BundleSelected>();

  ngOnInit() {
    this.#loadBundleSelector();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  selectSpecial(bundleSpecial: BundleSpecial) {
    this.#resetSelection();
    bundleSpecial.selected = true;

    let index = 0;
    for (const bundleType of this.bundleSelector.bundleTypes) {

      bundleType.price = bundleSpecial.prices[index] ?? 0;
      bundleType.currencyCode = bundleSpecial.currencyCode ?? '';
      bundleType.currencySymbol = bundleSpecial.symbol ?? '';
      bundleType.currencyDecimal = bundleSpecial.decimal ?? '';
      index++;
    }
    this.bundleSelectedChange.emit({ special: { bundleSpecialId: bundleSpecial.id } });
  }

  selectCurrency(bundleGrade: BundleGrade, bundleCurrency: BundleCurrency) {
    this.#resetSelection();
    bundleCurrency.selected = true;

    for (const bundleType of this.bundleSelector.bundleTypes) {
      const price = bundleCurrency.prices.find(p => p.id === bundleType.id);
      if (price) {
        bundleType.price = price.price ?? 0;
        bundleType.currencyCode = bundleCurrency.code ?? '';
        bundleType.currencySymbol = bundleCurrency.symbol ?? '';
        bundleType.currencyDecimal = bundleCurrency.decimal ?? '';
      }
    }
    this.bundleSelectedChange.emit({ standard: { bundleGradeId: bundleGrade.id, bundleCurrencyId: bundleCurrency.id } });
  }

  #selectedBasedOnSelection() {
    if (this.bundleSelected?.standard) {
      let breakLoop = false;
      for (const bundleGrade of this.bundleSelector.bundleGrades) {
        if (bundleGrade.id === this.bundleSelected.standard.bundleGradeId) {
          for (const bundleCurrency of bundleGrade.currencies) {
            if (bundleCurrency.id === this.bundleSelected.standard.bundleCurrencyId) {
              this.selectCurrency(bundleGrade, bundleCurrency);
              breakLoop = true;
              break;
            }
          }
          if (breakLoop) {
            break;
          }
        }
      }
    }
    else if (this.bundleSelected?.special) {
      for (const special of this.bundleSelector.specials) {
        if (special.id === this.bundleSelected.special.bundleSpecialId) {
          this.selectSpecial(special);
          break;
        }
      }
    }
  }

  #selectedPreferredBundle() {
    const gradeId = this.gradeId ?? 0;
    const bundleGrade = this.bundleSelector.bundleGrades.find(b => b.gradeIdFrom <= gradeId && b.gradeIdTo >= gradeId);
    if (bundleGrade) {
      const bundleCurrency = bundleGrade.currencies.find(c => c.id === this.preferredCurrencyId);
      if (bundleCurrency) {
        bundleCurrency.selected = true;
        if (this.selectView) {
          this.bundleSelectedChange.emit({ standard: { bundleGradeId: bundleGrade.id, bundleCurrencyId: bundleCurrency.id } });
        }
      }
    }
  }

  #loadBundleSelector() {
    this.#subscriptions.push(
      this.#lessonEnrolmentService.getBundleSelector(this.centreId).subscribe(value => {
        this.bundleSelector = value;
        this.#resetSelection();
        this.#selectedPreferredBundle();
        this.#selectedBasedOnSelection();

        this.#changeDetectorRef.markForCheck();
      }));
  }

  #resetSelection() {
    for (const bundleGrade of this.bundleSelector.bundleGrades) {
      for (const currency of bundleGrade.currencies) {
        currency.selected = false;
      }
    }
    for (const special of this.bundleSelector.specials) {
      special.selected = false;
    }
  }

}
