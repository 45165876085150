import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-climbing-skill-e',
    templateUrl: './climbing-skill-e.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconClimbingSkillEComponent implements IconComponent {

}
