import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeOffsetIanaTimeZoneName } from 'moment-extensions-lib';

import { dateFromOffset } from '../helpers';

@Pipe({
    name: 'dateFromOffset',
    standalone: false
})
export class DateFromOffsetPipe implements PipeTransform {

  transform(value: DateTimeOffsetIanaTimeZoneName, format?: string): string {
    return dateFromOffset(value, format);
  }
}
