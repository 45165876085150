import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output
} from '@angular/core';
import { Icons } from 'icon-lib';

import { UploadedFileModel } from '../models';

@Component({
    selector: 'kip-uploaded-file-list',
    templateUrl: './uploaded-file-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UploadedFileListComponent {

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #ngZone = inject(NgZone);

  #fileList: UploadedFileModel[] = [];
  #progress = 0;

  readonly icons = Icons;
  editInputId = 'file-upload-edit-input';

  @Input({ required: true }) isUploading = false;
  @Input() hasError = false;
  @Input() isUploaded = false;
  @Input({ required: true }) set fileList(value: UploadedFileModel[]) {
    this.#fileList = value;

    // Uploading percentage breakpoints per each file
    for (const [index, item] of this.fileList.entries()) {
      item.percentage = 100 / value.length * (index + 1);
      item.perItemPercentage = 100 / value.length;
    }
    this.#changeDetectorRef.detectChanges();
  }
  get fileList() {
    return this.#fileList;
  }

  @Input({ required: true }) set progress(percentage: number | null) {
    this.#progress = percentage ?? 0;
    if (typeof percentage === 'number' && this.isUploading) {
      if (!this.fileList[this.fileList.length - 1]?.isUploading) {
        for (const item of this.fileList) {
          item.isUploading = true;
          item.editMode = false;
        }
      }

      for (const [index, item] of this.fileList.entries()) {
        item.isUploading = false;
        item.isUploaded = true;

        // Count upload percentage per each file
        if (percentage < item.percentage!) {
          item.isUploading = true;
          item.isUploaded = false;
          const percentageDifference = item.percentage! - item.perItemPercentage! * (index || 1);
          item.progress = Math.abs(percentage - percentageDifference) * 100 / item.percentage!;
          break;
        }
      }
    }
  }

  get progress() {
    return this.#progress;
  }

  @Output() readonly edit = new EventEmitter<UploadedFileModel>();
  @Output() readonly delete = new EventEmitter<UploadedFileModel>();
  @Output() readonly selectedFile = new EventEmitter<UploadedFileModel>();

  onOpenEditMode(item: UploadedFileModel) {
    if (this.isUploading) {
      return;
    }

    for (const img of this.fileList) {
      img.editMode = false;
    }
    item.editMode = true;

    if (item.editMode) {
      this.#ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          const input: HTMLInputElement = document.querySelector(`#${this.editInputId}`)!;
          input.focus();
        });
      });
    }
    this.#changeDetectorRef.detectChanges();
  }

  onEdit(item: UploadedFileModel) {
    item.editMode = false;
    this.edit.emit(item);
    this.#changeDetectorRef.detectChanges();
  }

  onRemove(file: UploadedFileModel) {
    if (this.isUploading) {
      return;
    }

    this.delete.emit(file);
  }
}
