import { ChangeDetectionStrategy, Component, computed, inject, input, model, OnDestroy, output } from '@angular/core';
import { Icons } from 'icon-lib';
import { RegionId } from 'questions-lib';
import { Subscription } from 'rxjs';
import { StudentService } from 'student-common-lib';
import { LessonHistoryStatus, LessonType, Signalify } from 'ui-common-lib';

import { LessonHistory, LessonHistoryActivity } from '../../../../models';

@Component({
    selector: 'kip-lesson-history-lesson',
    templateUrl: './lesson.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentLessonHistoryLessonComponent implements OnDestroy {

  readonly #studentService = inject(StudentService);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  readonly hasActivities = computed(() => {
    const history = this.history();
    return history?.activities && history.activities.length > 0;
  });

  readonly lessonStatus = computed(() => {
    const history = this.history();
    return LessonHistoryStatus[history?.lessonStatus() ?? LessonHistoryStatus.Attended];
  });

  readonly isAttended = computed(() => {
    const history = this.history();
    return history?.lessonStatus() === LessonHistoryStatus.Attended;
  });

  readonly isDeferred = computed(() => {
    const history = this.history();
    return history?.lessonStatus() === LessonHistoryStatus.Deferred;
  });

  readonly isMakeUp = computed(() => {
    const history = this.history();
    return history?.lessonType() === LessonType.HomeMakeUp;
  });

  readonly isDIYCompatible = computed(() => {
    const history = this.history();
    return history?.isDIYCompatible;
  });

  readonly collapse = model(true);
  readonly studentId = input.required<number | undefined>();
  readonly regionId = input.required<RegionId>();
  readonly singleMode = input(false);
  readonly history = model.required<Signalify<LessonHistory> | undefined>();
  readonly showHtml = output<Signalify<LessonHistoryActivity>>();
  readonly showVideo = output<string>();
  readonly previewHistory = output<Signalify<LessonHistory> | null>();
  readonly editSessionNote = output<Signalify<LessonHistory>>();
  readonly editLessonNote = output<Signalify<LessonHistory>>();
  readonly editActivityNote = output<Signalify<LessonHistoryActivity>>();
  readonly hideHistory = output();

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggleOrHideHistory() {
    if (this.singleMode()) {
      this.hideHistory.emit();
    } else {
      this.collapse.update(v => !v);
    }
  }

  outputHistory(activity: Signalify<LessonHistoryActivity>, isHomework: boolean) {
    const history = this.history();
    if (history) {
      const activities: Signalify<LessonHistoryActivity>[] = [];
      const homework: Signalify<LessonHistoryActivity>[] = [];
      if (isHomework) {
        homework.push(activity);
      } else {
        activities.push(activity);
      }
      const lessonHistory: Signalify<LessonHistory> = {
        ...history,
        activities: activities,
        homework: homework
      };
      this.previewHistory.emit(lessonHistory);
    }
  }

  markAttended() {
    const history = this.history();
    const studentId = this.studentId();

    if (history && studentId) {
      this.#subscriptions.push(
        this.#studentService.postLessonStatus(studentId, {
          lessonGuid: history.lessonGuid,
          status: LessonHistoryStatus.Attended
        }).subscribe({
          next: () => {
            history.lessonStatus.set(LessonHistoryStatus.Attended);
          },
          error: (error: unknown) => console.error(error)
        })
      );
    }
  }

  markDeferred() {
    const history = this.history();
    const studentId = this.studentId();

    if (history && studentId) {
      this.#subscriptions.push(
        this.#studentService.postLessonStatus(studentId, {
          lessonGuid: history.lessonGuid,
          status: LessonHistoryStatus.Deferred
        }).subscribe({
          next: () => {
            history.lessonStatus.set(LessonHistoryStatus.Deferred);
          },
          error: (error: unknown) => console.error(error)
        })
      );
    }
  }

  convertToDiy() {
    const history = this.history();
    const studentId = this.studentId();

    if (history && studentId) {
      this.#subscriptions.push(
        this.#studentService.postConvertToDIY(studentId, history.lessonId).subscribe({
          next: () => {
            history.lessonType.set(LessonType.HomeMakeUp);
          },
          error: (error: unknown) => console.error(error)
        })
      );
    }
  }
}
