import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-checkbox',
    templateUrl: './form-control-checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlCheckboxComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<boolean>>();
  readonly headerLabel = input('');
  readonly disabled = input(false);

}
