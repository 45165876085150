import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-santa-claus-d',
    templateUrl: './santa-claus-d.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconSantaClausDComponent implements IconComponent {

}
