import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-graduated-girl-f',
    templateUrl: './graduated-girl-f.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconGraduatedGirlFComponent implements IconComponent {

}
