import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'openTokClass',
    standalone: false
})
export class OpenTokClassPipe implements PipeTransform {

  transform(tutorId: number | undefined, studentId: number | undefined, observerId: number | undefined): string {
    if (tutorId) {
      return 'open-tok-tutor';
    }

    if (observerId) {
      return 'open-tok-observer';
    }

    if (studentId) {
      return `open-tok-student-${studentId}`;
    }
    return '';
  }
}
