import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  inject,
  Input, OnDestroy,
  OnInit, Output
} from '@angular/core';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ActivityNote, AIResponse, LessonNote, NotesForLesson, NotesForSession, SessionNote } from 'tutor-notes-lib';
import { LoadingState, ProfileService } from 'ui-common-lib';

import { AssessmentNote, GeneralNote, StudentNote } from '../../../models';
import { StudentService } from '../../../services';

enum NoteViewingMode {
  Lesson = 1,
  Session = 2,
  Assessment = 3,
  General = 4
}

enum EditState {
  None = 0,
  EditingLesson = 1,
  EditingSession = 2,
  EditingGeneral = 3
}

@Component({
    selector: 'kip-student-note-dropdown',
    templateUrl: './student-note-dropdown.component.html',
    styleUrl: './student-note-dropdown.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentNoteDropdownComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  #activityNotes: ActivityNote[] | undefined;
  #studentId: number | undefined;
  #loadingSubscription: Subscription | undefined;
  #studentNoteToEdit: StudentNote | undefined;
  #loadingState: LoadingState = LoadingState.Loading;
  #sessionNote: SessionNote | undefined;
  #lessonNote: LessonNote | undefined;
  #generalNotes: GeneralNote[] = [];
  #canEdit = false;
  #currentEditState = EditState.None;

  readonly defaultDateFormat = 'ddd, D MMMM YYYY';
  readonly icons = Icons;
  readonly notesViewingMode = NoteViewingMode;
  readonly editState = EditState;

  ranking: number | null = null;
  currentNoteViewingMode = NoteViewingMode.Lesson;

  get canEdit() {
    return this.#canEdit;
  }

  get loadingState() {
    return this.#loadingState;
  }

  get studentNotes() {
    return this.ranking === null ? this.#generalNotes : this.#generalNotes.filter(sn =>
      sn.lessonNote?.internalSessionNote?.note.ranking === this.ranking ||
      sn.lessonNote?.internalLessonNote?.note.ranking === this.ranking ||
      sn.lessonNote?.startLessonNote?.note.ranking === this.ranking ||
      sn.lessonNote?.finishLessonNote?.note.ranking === this.ranking
    );
  }

  get allNotes() {
    return this.#generalNotes;
  }

  get isLoadingComplete() {
    return this.loadingState === LoadingState.Complete;
  }

  get hasResults() {
    return this.studentNotes && this.studentNotes.length > 0;
  }

  get showAssessmentNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.Assessment;
  }

  get showLessonNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.Lesson;
  }

  get showSessionNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.Session;
  }

  get showGeneralNotes() {
    return this.currentNoteViewingMode === NoteViewingMode.General;
  }

  get studentNoteToEdit() {
    return this.#studentNoteToEdit;
  }

  get visibleNotes() {
    if (this.studentNotes) {
      switch (this.currentNoteViewingMode) {
        case NoteViewingMode.Lesson:
          return this.lessonNotes;
        case NoteViewingMode.Session:
          return this.sessionNotes;
        case NoteViewingMode.General:
          return this.generalNotes;
        case NoteViewingMode.Assessment:
          return this.assessmentNotes;
      }
    }

    return [];
  }

  get assessmentNotes() {
    return this.studentNotes.filter(x => x.assessmentNote && x.assessmentNote.resultNotes.length > 0);
  }

  get sessionNotes() {
    return this.studentNotes.filter(x => x.lessonNote);
  }

  get lessonNotes() {
    return this.studentNotes.filter(x => x.lessonNote);
  }

  get generalNotes() {
    return this.studentNotes.filter(x => x.note && !x.note?.note?.pinned);
  }

  get pinnedStudentNotes() {
    if (this.#generalNotes) {
      return this.#generalNotes.filter(x => x.note?.note?.pinned);
    }
    return [];
  }

  get sessionNote() {
    return this.#sessionNote;
  }

  get lessonNote() {
    return this.#lessonNote;
  }

  get activityNotes() {
    return this.#activityNotes;
  }

  get editing() {
    return this.#currentEditState !== EditState.None;
  }

  get currentEditState() {
    return this.#currentEditState;
  }

  @Input({ required: true }) givenName = '';
  @Input({ required: true }) lessonId: number | undefined;
  @Input({ required: true }) sessionId: number | undefined;

  @Input({ required: true })
  set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.refresh();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  @Output() readonly lessonNoteUpdated = new EventEmitter<LessonNote>();
  @Output() readonly sessionNoteUpdated = new EventEmitter<SessionNote>();
  @Output() readonly generalNoteUpdated = new EventEmitter();

  ngOnInit() {
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }));

    this.refresh();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  add() {
    this.editGeneralNote();
  }

  cancelled() {
    this.#studentNoteToEdit = undefined;
    this.#currentEditState = EditState.None;
  }

  submitted() {
    this.refresh();
    this.generalNoteUpdated.emit();
  }

  cancel() {
    if (this.#loadingSubscription) {
      this.#loadingSubscription.unsubscribe();
      this.#loadingSubscription = undefined;
    }
  }

  retry() {
    this.cancel();
    this.refresh();
  }

  assessmentNoteCount(assessmentNote: AssessmentNote) {
    return assessmentNote.resultNotes.filter(x => x.internalNote || x.parentNote).length;
  }

  clearEditState() {
    this.#currentEditState = EditState.None;
  }

  editParentNote() {
    this.clearEditState();
    this.#currentEditState = this.showLessonNotes ? EditState.EditingLesson : EditState.EditingSession;
  }

  editInternalNote() {
    this.clearEditState();
    this.#currentEditState = this.showLessonNotes ? EditState.EditingLesson : EditState.EditingSession;
  }

  editGeneralNote(studentNote?: StudentNote) {
    if (this.canEdit) {
      this.clearEditState();
      this.#currentEditState = EditState.EditingGeneral;
      this.#studentNoteToEdit = studentNote;
      this.#changeDetectorRef.markForCheck();
    }
  }

  cancelledLessonEditing(response: AIResponse | undefined) {
    if (this.#lessonNote && response) {
      this.#lessonNote.parentNote = response.response;
      this.#lessonNote.aiNote = response.promptText;
      this.#lessonNote.parentNoteApproved = response.approved;

      this.lessonNoteUpdated.emit(this.#lessonNote);
      this.refresh();
      this.#changeDetectorRef.markForCheck();
    }
    this.clearEditState();
  }

  updateLessonNote(update: NotesForLesson) {
    if (this.#lessonNote) {
      this.#lessonNote.internalNote = update.internalNote;
      this.#lessonNote.parentNote = update.parentNote;
      this.#lessonNote.internalNoteRanking = update.internalNoteRanking;
      this.#lessonNote.parentNoteRanking = update.parentNoteRanking;
      this.#lessonNote.focusNote = update.focusNote;
      this.#lessonNote.aiNote = update.aiNote;
      this.#lessonNote.parentNoteApproved = true;

      this.lessonNoteUpdated.emit(this.#lessonNote);
      this.refresh();
      this.#changeDetectorRef.markForCheck();
    }
  }

  updateSessionNote(update: NotesForSession) {
    if (this.#sessionNote) {
      this.#sessionNote.internalNote = update.internalNote;
      this.#sessionNote.parentNote = update.parentNote;
      this.#sessionNote.internalNoteRanking = update.internalNoteRanking;
      this.#sessionNote.parentNoteRanking = update.parentNoteRanking;

      this.sessionNoteUpdated.emit(this.#sessionNote);
      this.refresh();
      this.#changeDetectorRef.markForCheck();
    }
  }

  refresh() {
    this.#studentNoteToEdit = undefined;
    this.#currentEditState = EditState.None;
    if (this.#studentId) {
      this.#loadingState = LoadingState.Loading;
      this.#subscriptions.push(
        this.#studentService.getNotesByStudentId(this.#studentId).subscribe(
          {
            next: studentNotes => {
              this.#loadingState = LoadingState.Complete;
              this.#createDummyNoteIfRequired(studentNotes);
              this.#setGeneralNotes(studentNotes);
              this.#changeDetectorRef.markForCheck();
            },
            error: () => {
              this.#loadingState = LoadingState.Error;
              this.#changeDetectorRef.markForCheck();
            }
          }));
    }
  }

  #createDummyNoteIfRequired(studentNotes: GeneralNote[]) {
    if (this.lessonId && this.sessionId) {
      let lessonNote = studentNotes.find(x => x.lessonNote?.lessonId === this.lessonId && x.lessonNote?.sessionId === this.sessionId);

      const dummyDate = new Date();

      if (!lessonNote) {
        lessonNote = {
          date: dummyDate,
          lessonNote: {
            lessonId: this.lessonId,
            sessionId: this.sessionId,
            subject: 'Current',
            date: dummyDate,
            tutor: '',
            internalLessonNote: undefined,
            parentLessonNote: undefined,
            internalActivityNotes: []
          }
        };
        studentNotes.unshift(lessonNote);
      }

      this.#lessonNote = {
        lessonId: this.lessonId,
        studentName: this.givenName,
        date: lessonNote.date,
        internalNote: lessonNote.lessonNote?.internalLessonNote?.note?.description ?? '',
        parentNote: lessonNote.lessonNote?.parentLessonNote?.note?.description ?? '',
        parentNoteApproved: false,
        focusNote: lessonNote.lessonNote?.lessonFocusNote?.note?.description ?? '',
        aiNote: lessonNote.lessonNote?.lessonAINote?.note?.description ?? '',
        internalNoteRanking: lessonNote.lessonNote?.internalLessonNote?.note.ranking ?? null,
        parentNoteRanking: lessonNote.lessonNote?.parentLessonNote?.note.ranking ?? null
      };

      const internalSessionNote = lessonNote.lessonNote?.internalSessionNote;
      const parentSessionNote = lessonNote.lessonNote?.parentSessionNote;

      let internalNote = '';
      let internalNoteRanking = null;
      let parentNote = '';
      let parentNoteRanking = null;

      if (internalSessionNote) {
        internalNote = internalSessionNote?.note?.description ?? '';
        internalNoteRanking = internalSessionNote?.note.ranking ?? null;
      }

      if (parentSessionNote) {
        parentNote = parentSessionNote?.note?.description ?? '';
        parentNoteRanking = parentSessionNote?.note.ranking ?? null;
      }

      this.#activityNotes = lessonNote.lessonNote?.internalActivityNotes ? lessonNote?.lessonNote?.internalActivityNotes.map(a => ({
        lessonActivityPlanId: a.lessonActivityPlanId,
        activityName: a.activityName,
        date: undefined,
        internalNote: a.note.note.description,
        parentNote: ''
      })) : undefined;

      this.#sessionNote = {
        sessionId: this.sessionId,
        date: undefined,
        internalNote: internalNote,
        parentNote: parentNote,
        internalNoteRanking: internalNoteRanking,
        parentNoteRanking: parentNoteRanking
      };
    }
  }

  #setGeneralNotes(studentNotes: GeneralNote[]) {
    this.#generalNotes = studentNotes;
    this.#changeDetectorRef.markForCheck();
  }

}
