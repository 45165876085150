
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-unlocked-level',
    templateUrl: './unlocked-level.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconUnlockedLevelComponent implements IconComponent {

}
