import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  ElementRef, EventEmitter, inject,
  Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AcceptedFileType } from 'ui-common-lib';

import { StudentService } from '../services';

@Component({
    selector: 'kip-student-photo',
    templateUrl: './student-photo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class StudentPhotoComponent implements AfterViewInit, OnDestroy {

  readonly #studentService = inject(StudentService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #subscriptions: Subscription[] = [];
  readonly #validFileTypes: string[] = [AcceptedFileType.Jpg, AcceptedFileType.Jpeg];
  #studentId = 0;

  get validFileTypes() {
    return this.#validFileTypes;
  }

  invalidFileTypeImage = '';

  @Input() preview = true;

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set studentId(value: number) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#loadImage();
    }
  }

  get studentId() {
    return this.#studentId;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild('dropAreaStudentImage', { static: false }) dropAreaStudentImage: ElementRef<HTMLElement> | undefined;
  @ViewChild('img', { static: false }) img: ElementRef<HTMLImageElement> | undefined;

  @Output() readonly imageChange = new EventEmitter<Blob>();

  ngAfterViewInit() {

    // Prevent default drag behaviors

    for (const eventName of ['dragenter', 'dragover', 'dragleave', 'drop']) {
      if (this.dropAreaStudentImage) {
        this.dropAreaStudentImage.nativeElement.addEventListener(eventName, (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
        }, false);
      }
      document.body.addEventListener(eventName, (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
      }, false);
    }

    // Highlight drop area when item is dragged over it
    for (const eventName of ['dragenter', 'dragover']) {
      if (this.dropAreaStudentImage) {
        const dropAreaPdf = this.dropAreaStudentImage;
        dropAreaPdf.nativeElement.addEventListener(eventName, () => {
          dropAreaPdf.nativeElement.classList.add('highlight');
        }, false);
      }
    }

    for (const eventName of ['dragleave', 'drop']) {
      if (this.dropAreaStudentImage) {
        const dropAreaPdf = this.dropAreaStudentImage;
        dropAreaPdf.nativeElement.addEventListener(eventName, () => {
          dropAreaPdf.nativeElement.classList.remove('active');
        }, false);
      }
    }

    // Handle dropped files
    if (this.dropAreaStudentImage) {
      this.dropAreaStudentImage.nativeElement.addEventListener('drop', (e: DragEvent) => {
        const dt = e.dataTransfer;
        if (dt) {
          const files = dt.files;
          this.#handleFiles(files[0]);
        }
      }, false);
    }
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  fileUploadChange(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target.files) {
      this.#handleFiles(target.files[0]);
    }
  }

  clear() {
    this.#subscriptions.push(
      this.#studentService.clearStudentPhoto(this.#studentId).subscribe(() => {
        this.#loadImage();
      }));
  }

  #handleFiles(file: File) {
    this.invalidFileTypeImage = '';

    if (!this.#validFileTypes.includes(file.type)) {
      this.invalidFileTypeImage = `${file.type} is not an acceptable file type (only ${this.#validFileTypes.join(', ')} allowed)`;
    } else {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (this.img) {
          this.img.nativeElement.src = reader.result as string;
        }
      };

      this.#subscriptions.push(
        this.#studentService.uploadStudentPhoto(file, this.#studentId).subscribe(() => {
          this.#loadImage();
          this.imageChange.emit(file as Blob);
        }));
    }
  }

  #loadImage() {
    this.#subscriptions.push(
      this.#studentService.getStudentPhoto(this.#studentId).subscribe(
        {
          next: response => {
            if (this.img) {
              this.img.nativeElement.src = response.body ? window.URL.createObjectURL(response.body) : '';
              this.#changeDetectorRef.markForCheck();
            }
          }, error: () => {
            alert('Cannot load image file');
          }
        }));
  }

}
