import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, inject, NgModule, provideAppInitializer } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule, provideGoogleAnalytics, provideGoogleAnalyticsRouter } from '@hakimio/ngx-google-analytics';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterState, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import {
  AbstractSecurityStorage,
  AuthModule as OidcAuthModule, StsConfigLoader
} from 'angular-auth-oidc-client';
import { AuthLibModule, AuthStorage } from 'auth-lib';
import { IconLibModule } from 'icon-lib';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { QuestionsLibModule } from 'questions-lib';
import { UiCommonLibModule } from 'ui-common-lib';
import { WhiteboardModule } from 'whiteboard-lib';

import { environment } from '../environments';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { appInitializerFn, initFn } from './app-initializer';
import { ClasslinkInstantLoginRedirectComponent } from './classlink-instant-login-redirect/classlink-instant-login-redirect.component';
import { CleverInstantLoginRedirectComponent } from './clever-instant-login-redirect/clever-instant-login-redirect.component';
import {
  CreateAdHocActivityPublicComponent
} from './create-ad-hoc-activity-public/create-ad-hoc-activity-public.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MergedRouterStateSerializer } from './route-extensions/merged-route-serializer';
import { ConfigService, SharedModule } from './shared';
import { reducers } from './store';
import { AppGlobalFeatureName } from './store/state';
import { UploadActivityFilesPublicComponent } from './upload-activity-files-public/upload-activity-files-public.component';
import { UploadPublicComponent } from './upload-public/upload-public.component';

@NgModule({
  declarations: [
    AppComponent,
    ClasslinkInstantLoginRedirectComponent,
    CleverInstantLoginRedirectComponent,
    CreateAdHocActivityPublicComponent,
    ForbiddenComponent,
    UploadActivityFilesPublicComponent,
    UploadPublicComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    WhiteboardModule.forRoot(),
    StoreModule.forRoot({
      router: routerReducer
    }),
    StoreModule.forFeature(AppGlobalFeatureName, reducers),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Full
    }),
    StoreDevtoolsModule.instrument(),
    FormsModule,
    IconLibModule,
    NgbDropdownModule,
    NgxGoogleAnalyticsModule,
    AuthLibModule.forRoot(),
    OidcAuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: appInitializerFn,
        deps: [ConfigService]
      }
    }),
    QuestionsLibModule,
    SharedModule,
    UiCommonLibModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
    provideAppInitializer(() => {

      const returnFunc = initFn(inject(ConfigService));

      return returnFunc();
    }),
    provideGoogleAnalytics(environment.googleTag),
    provideGoogleAnalyticsRouter(),
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: AbstractSecurityStorage,
      useClass: AuthStorage
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
