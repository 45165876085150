import { CdkDrag } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DeviceDetectorService, OrientationType } from 'device-information-lib';
import { Icons } from 'icon-lib';
import { KeyboardLayouts, KeyboardType, SpecialKeys } from 'questions-lib';
import { Subscription } from 'rxjs';
import Keyboard from 'simple-keyboard';
import { WhiteboardService } from 'whiteboard-lib';

import { KeyboardService } from '../services';

const radioType = 'radio';

const buttonMap = new Map([
  [SpecialKeys.Space, ' '],
  [SpecialKeys.Enter, '\n'],
  [SpecialKeys.Left, ''],
  [SpecialKeys.Right, ''],
  [SpecialKeys.Backspace, '']
]);

@Component({
    selector: 'kip-keyboard',
    templateUrl: './keyboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class KeyboardComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly #deviceService = inject(DeviceDetectorService);
  readonly #whiteboardService = inject(WhiteboardService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #isTablet = false;
  #chatSubmitButton: HTMLButtonElement | null = null;
  #submitButton: HTMLButtonElement | null = null;
  #eventTarget: HTMLInputElement | HTMLTextAreaElement | null = null;
  #chatTarget: HTMLInputElement | null = null;
  #inputTargets: HTMLInputElement[] = [];
  #appendInputSelectors: Date | undefined;
  #keyboard: Keyboard | undefined;
  readonly #inputClass = '.kip-input';
  readonly #chatClass = '.kip-chat-input';
  readonly #chatSubmitButtonClass = '.kip-chat-input__send-button';
  readonly #submitButtonClass = '.kip-submit';
  readonly #selectedClass = 'kip-keyboard-selected';
  readonly #keyboardClass = 'simple-keyboard';
  #isDocked = true;
  #useQwerty = false;
  #portraitEvent?: MediaQueryList;
  #keyboardHidden = true;
  #canvasTextArea: HTMLTextAreaElement | null = null;
  #subscriptions: Subscription[] = [];
  #orientation = this.#deviceService.orientation as OrientationType;
  readonly icons = Icons;

  get keyboardHidden() {
    KeyboardService.isDockedAndVisible = this.#isDocked && !this.#keyboardHidden;
    return this.#keyboardHidden;
  }

  get keyboardClass() {
    return this.#keyboardClass;
  }

  get isTablet() {
    return this.#isTablet;
  }

  get isDocked() {
    KeyboardService.isDockedAndVisible = this.#isDocked && !this.#keyboardHidden;
    return this.#isDocked;
  }

  // Needs some sort of trigger to indicate it needs to find new elements to bind to

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true }) set appendInputSelectors(value: Date | undefined) {
    if (this.#appendInputSelectors !== value) {
      this.#appendInputSelectors = value;
      this.#bind();
    }
  }

  get appendInputSelectors() {
    return this.#appendInputSelectors;
  }

  @Input({ required: true }) set useQwerty(value: boolean) {
    this.#useQwerty = value;
    this.#updateQwerty();
  }

  get useQwerty() {
    this.#changeDetectorRef.markForCheck();
    return this.#useQwerty;
  }

  /* eslint-enable kip/no-unused-public-members */

  @ViewChild(CdkDrag, { static: true }) dragElem?: CdkDrag;

  ngOnInit() {
    this.#subscriptions.push(
      KeyboardService.canvasActiveTexArea$.subscribe(textArea => {
        if (textArea) {
          this.#blockDeviceDefaultKeyboard(textArea);
        }

        this.#updateKeyboardType(textArea);
        this.#canvasTextArea = textArea;
        this.#keyboardHidden = false;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#whiteboardService.toolbarOpenState$.subscribe(value => {
        if (value) {
          this.#updateKeyboardType();
        }

        this.#keyboardHidden = !this.#whiteboardService.isTextBoxSelected;
        this.#changeDetectorRef.markForCheck();
      }),
      this.#whiteboardService.textBoxState$.subscribe(value => {
        this.#keyboardHidden = !value;
        this.#changeDetectorRef.markForCheck();
      }),
      KeyboardService.checkInputsAndTextAreas$.subscribe(isDocked => {
        if (isDocked) {
          this.#isDocked = false;
          this.dockKeyboard();
        } else {
          this.undockKeyboard();
        }

        this.#bind();
      }),
      KeyboardService.hideKeyboard$.subscribe(() => {
        this.#keyboardHidden = true;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  ngAfterViewInit() {
    this.#isTablet = this.#deviceService.isTablet();

    if (!this.#keyboard && document.querySelector(`.${this.#keyboardClass}`)) {

      this.#keyboard = new Keyboard({
        layoutName: KeyboardType.AlphabeticalLowercase,
        tabCharOnTab: false,
        layout: KeyboardLayouts,
        display: {
          [SpecialKeys.Backspace]: '&nbsp;',
          [SpecialKeys.Uppercase]: '&nbsp;',
          [SpecialKeys.Space]: '&nbsp;',
          [SpecialKeys.Enter]: 'enter',
          [SpecialKeys.Numbers]: '.%123',
          [SpecialKeys.Lowercase]: '&nbsp;',
          [SpecialKeys.Abc]: 'ABC',
          [SpecialKeys.Symbols]: '#+=',
          [SpecialKeys.Left]: '&nbsp;',
          [SpecialKeys.Right]: '&nbsp;',
          [SpecialKeys.Close]: '&nbsp;',
          [SpecialKeys.Open]: '&nbsp;'
        },
        onChange: input => this.#onChange(input),
        onKeyPress: (button: string, e?: MouseEvent) => this.#onKeyPress(button, e),
        theme: 'kip-keyboard-theme',
        buttonTheme: [
          {
            class: 'hg-standardBtn',
            buttons: SpecialKeys.Space
          }, {
            class: 'hg-gradientDarkButton',
            buttons: SpecialKeys.Lowercase
          },
          {
            class: 'hg-gradientButton',
            // eslint-disable-next-line max-len
            buttons: `${SpecialKeys.Open} ${SpecialKeys.Close} ${SpecialKeys.Abc} ${SpecialKeys.Symbols} ${SpecialKeys.Numbers} ${SpecialKeys.Uppercase} ${SpecialKeys.Enter} ${SpecialKeys.Backspace} ${SpecialKeys.Left} ${SpecialKeys.Right}`
          }]
      });

      this.#bind();
    }

    this.#updateQwerty();

    this.#portraitEvent = window.matchMedia('(orientation: portrait)');
    this.#portraitEvent.addEventListener('change', this.#onOrientationchange);

    this.#changeOrientation(this.#deviceService.orientation === OrientationType.Portrait);
    this.#changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.#portraitEvent?.removeEventListener('change', this.#onOrientationchange);
    for (const item of KeyboardService.focusedInputs) {
      item.element.setAttribute('inputmode', item.inputMode);
    }
    KeyboardService.focusedInputs = [];
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  undockKeyboard() {
    this.#disableWhiteboardButton();
    this.#isDocked = false;
    this.#changeDetectorRef.markForCheck();
    KeyboardService.updateKeyboardPosition();
  }

  dockKeyboard() {
    this.dragElem?.reset();

    // If button pressed while in docked mode, hide keyboard
    if (this.#isDocked || this.#orientation === OrientationType.Landscape) {
      this.#keyboardHidden = true;
      if (this.#whiteboardService.isTextBoxSelected) {
        this.#whiteboardService.hideKeyboard();
      }
      this.#disableWhiteboardButton();
    } else {
      // Dock keyboard
      this.#isDocked = true;
    }

    this.#changeDetectorRef.markForCheck();
    KeyboardService.updateKeyboardPosition();
  }

  #disableWhiteboardButton() {
    // Disable whiteboard toggle buttons for a short time, as it triggers on iPad when the dock button is pressed
    const whiteboardToggleButtons = document.querySelectorAll<HTMLElement>('.kip-whiteboard-button');
    for (const b of whiteboardToggleButtons) {
      b.classList.add('pointer-events-none');

      setTimeout(() => {
        b.classList.remove('pointer-events-none');
      }, 1000);
    }
  }

  // finds the input elements and listens for focus events

  #bind() {
    setTimeout(() => {
      if (this.#isTablet) {
        this.#submitButton = document.querySelector(this.#submitButtonClass);
        this.#chatSubmitButton = document.querySelector(this.#chatSubmitButtonClass);
        this.#inputTargets = [...document.querySelectorAll<HTMLInputElement>(this.#inputClass)];
        this.#chatTarget = document.querySelector(this.#chatClass);
        const elements = this.#chatTarget ? [...this.#inputTargets, this.#chatTarget] : [...this.#inputTargets];

        if (elements.length > 0) {
          for (const element of elements) {
            if (element) {
              this.#blockDeviceDefaultKeyboard(element);
            }

            if (document.activeElement === element || element.checked) {
              this.#onFocus(element);
            }

            if (element.type === radioType) {
              element.addEventListener('change', this.#onChangeHandler);
            } else {
              element.addEventListener('focus', this.#onFocusHandler);
            }
          }
        }
      }

      this.#changeDetectorRef.markForCheck();
    }, 100);
  }

  readonly #onChangeHandler = (event: InputEvent) => {
    const target = event.target as HTMLInputElement | null;
    this.#onFocus(target);
  };

  readonly #onFocusHandler = (event: InputEvent) => {
    const target = event.target as HTMLInputElement | null;
    this.#onFocus(target);
  };

  // finds the next input target and moves to it
  // if no more targets moves to the first in the list

  #next() {
    if (this.#eventTarget) {
      const index = this.#inputTargets.indexOf(this.#eventTarget as HTMLInputElement);
      if (index !== -1) {
        let newTarget = this.#inputTargets[0];
        if (index < this.#inputTargets.length - 1) {
          newTarget = this.#inputTargets[index + 1];
        }

        if (newTarget.type === radioType) {

          // to work with angular, you need to find the label for the radio and click it

          const nextSibling = newTarget.nextSibling;
          if (nextSibling) {
            (nextSibling as HTMLElement).click();
          }
        } else {
          newTarget.focus();
        }
      }
    }
    this.#changeDetectorRef.markForCheck();
  }

  // finds the previous input target and moves to it
  // if no more targets moved to the last in the list

  #previous() {
    if (this.#eventTarget) {
      const index = this.#inputTargets.indexOf(this.#eventTarget as HTMLInputElement);
      if (index !== -1) {
        let newTarget = this.#inputTargets[this.#inputTargets.length - 1];
        if (index > 0) {
          newTarget = this.#inputTargets[index - 1];
        }

        if (newTarget.type === radioType) {

          // to work with angular, you need to find the label for the radio and click it

          const nextSibling = newTarget.nextSibling;
          if (nextSibling) {
            (nextSibling as HTMLElement).click();
          }
        } else {
          newTarget.focus();
        }
      }
    }
    this.#changeDetectorRef.markForCheck();
  }

  // on focus, it applies class to component and removes class from all other components
  // tells the keyboard that this is the current input and determines the keyboard to use based on data-keyboard
  // attribute if it exists

  #onFocus(target: HTMLInputElement | null) {
    KeyboardService.updateKeyboardPosition();
    KeyboardService.isChatTextAreaFocused = target === KeyboardService.focusedTextAre;
    if (this.#keyboard) {

      this.#keyboardHidden = false;

      if (target && target.type === radioType && !target.checked) {
        return;
      }

      this.#eventTarget = target;

      if (this.#canvasTextArea) {
        this.#canvasTextArea = null;
      }

      for (const inputTarget of this.#inputTargets) {
        inputTarget.classList.remove(this.#selectedClass);
      }

      if (this.#chatTarget) {
        this.#chatTarget.classList.remove(this.#selectedClass);
      }

      if (target) {

        if (!target.classList.contains(this.#selectedClass)) {
          target.classList.add(this.#selectedClass);
        }

        this.#updateKeyboardType(target);
        this.#keyboard.setInput(target.value);
      }
    }

    this.#changeDetectorRef.markForCheck();
  }

  #updateKeyboardType(target?: HTMLInputElement | HTMLTextAreaElement) {
    let layoutName = target?.getAttribute('data-keyboard') ?? KeyboardType.AlphabeticalLowercase;

    if (layoutName === KeyboardType.AlphabeticalLowercase && this.useQwerty) {
      layoutName = KeyboardType.QwertyLowercase;
    }

    if (layoutName === KeyboardType.AlphabeticalUppercase && this.useQwerty) {
      layoutName = KeyboardType.QwertyUppercase;
    }

    const currentLayout = this.#keyboard?.options.layoutName;

    if (layoutName !== currentLayout) {
      this.#keyboard?.setOptions({
        layoutName: layoutName
      });
    }

    this.#changeDetectorRef.markForCheck();
  }

  readonly #onChange = (input: string) => {
    // when string changes, update the input target
    // for max length 1 fields (for example in drills completely ignore and update via key press)
    if (this.#eventTarget && this.#eventTarget.maxLength !== 1 && !this.#canvasTextArea) {
      this.#eventTarget.value = input;
    }
    this.#changeDetectorRef.markForCheck();
  };

  #spliceForStrings(stringValue: string, index: number, count: number, add: string) {
    const array = [...stringValue];
    array.splice(index, count, add);
    return array.join('');
  }

  #setAsTextAreaValue(initialText: string, textArea: HTMLTextAreaElement) {
    let selectionStart = textArea.selectionStart;
    let value = buttonMap.get(initialText as SpecialKeys);
    let count = 0;
    let step = 0;
    const e = new InputEvent('input', {
      bubbles: true,
      cancelable: true
    });

    if (initialText !== SpecialKeys.Backspace && initialText !== SpecialKeys.Left) {
      ++step;
    } else if (initialText === SpecialKeys.Left) {
      --step;
    }

    if (initialText === SpecialKeys.Backspace) {
      --selectionStart;
      count = 1;
    }

    if (value === undefined) {
      value = initialText;
    }

    textArea.value = this.#spliceForStrings(textArea.value, selectionStart, count, value);
    textArea.focus();
    textArea.setSelectionRange(selectionStart + step, selectionStart + step);
    textArea.dispatchEvent(e);
    this.#changeDetectorRef.markForCheck();
  }

  readonly #onKeyPress = (button: string, e?: MouseEvent) => {
    e?.preventDefault();

    if (
      this.#canvasTextArea
      && button !== SpecialKeys.Uppercase
      && button !== SpecialKeys.Lowercase
      && button !== SpecialKeys.Open
      && button !== SpecialKeys.Close
      && button !== SpecialKeys.Abc
      && button !== SpecialKeys.Numbers
      && button !== SpecialKeys.Symbols
    ) {
      this.#setAsTextAreaValue(button, this.#canvasTextArea);
    }

    if (this.#keyboard) {
      switch (button) {
        case SpecialKeys.Uppercase:
          this.#keyboard.setOptions({
            layoutName: this.useQwerty ? KeyboardType.QwertyUppercase : KeyboardType.AlphabeticalUppercase
          });
          return;
        case SpecialKeys.Numbers:
          this.#keyboard.setOptions({
            layoutName: KeyboardType.Numbers as string
          });
          return;
        case SpecialKeys.Lowercase:
          this.#keyboard.setOptions({
            layoutName: this.useQwerty ? KeyboardType.QwertyLowercase : KeyboardType.AlphabeticalLowercase
          });
          return;
        case SpecialKeys.Abc:
          this.#keyboard.setOptions({
            layoutName: this.useQwerty ? KeyboardType.QwertyLowercase : KeyboardType.AlphabeticalLowercase
          });
          return;
        case SpecialKeys.Symbols:
          this.#keyboard.setOptions({
            layoutName: KeyboardType.Symbols as string
          });
          return;
        case SpecialKeys.Left:
          if (!this.#canvasTextArea) {
            this.#previous();
          }

          return;
        case SpecialKeys.Right:
          if (!this.#canvasTextArea) {
            this.#next();
            return;
          }

          return;
        case SpecialKeys.Open:
          this.undockKeyboard();
          return;
        case SpecialKeys.Close:
          this.dockKeyboard();
          return;
        case SpecialKeys.Enter:
          if (this.#eventTarget && !this.#canvasTextArea) {
            if (this.#eventTarget === this.#chatTarget) {
              // TODO - look at change detection in chat as it is not picking up model change
              this.#chatSubmitButton?.click();
            } else {
              this.#submitButton?.click();
            }
          }
          return;
      }
    }

    // if max length 1, just completely overwrite whatever was there before

    if (this.#eventTarget && this.#eventTarget.maxLength === 1 && !this.#canvasTextArea) {
      this.#eventTarget.value = button;
      this.#previous();
    }

    this.#changeDetectorRef.markForCheck();
  };

  #updateQwerty() {
    if (this.#keyboard) {
      const currentLayout = this.#keyboard.options.layoutName;

      if (currentLayout === KeyboardType.AlphabeticalLowercase && this.#useQwerty) {
        this.#keyboard.setOptions({
          layoutName: KeyboardType.QwertyLowercase
        });
      } else if (currentLayout === KeyboardType.AlphabeticalUppercase && this.#useQwerty) {
        this.#keyboard.setOptions({
          layoutName: KeyboardType.QwertyUppercase
        });
      } else if (currentLayout === KeyboardType.QwertyLowercase && !this.#useQwerty) {
        this.#keyboard.setOptions({
          layoutName: KeyboardType.AlphabeticalLowercase
        });
      } else if (currentLayout === KeyboardType.QwertyUppercase && !this.#useQwerty) {
        this.#keyboard.setOptions({
          layoutName: KeyboardType.AlphabeticalUppercase
        });
      }
    }
  }

  readonly #onOrientationchange = (event: MediaQueryListEvent) => this.#changeOrientation(event.matches);

  readonly #changeOrientation = (isPortrait: boolean) => {
    if (isPortrait) {
      this.#orientation = OrientationType.Portrait;
      this.#isDocked = false;
      this.dockKeyboard();
    } else {
      this.#orientation = OrientationType.Landscape;
      this.undockKeyboard();
    }

    this.#changeDetectorRef.markForCheck();
  };

  #blockDeviceDefaultKeyboard(target: HTMLInputElement | HTMLTextAreaElement) {
    // Set input or textArea attribute inputmode to block keyboard showing up on ipad
    KeyboardService.focusedInputs.push({ inputMode: target.getAttribute('inputmode') ?? '', element: target });
    target?.setAttribute('inputmode', 'none');
  }
}
