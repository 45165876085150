import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingState, ProfileService } from 'ui-common-lib';

@Component({
    selector: 'kip-student-awards',
    templateUrl: './student-awards.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAwardsComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #canEdit = false;
  #subscriptions: Subscription[] = [];
  #editing = false;
  #trading = false;
  #loadingState: LoadingState = LoadingState.Loading;

  get canEdit() {
    return this.#canEdit;
  }

  get loadingState() {
    return this.#loadingState;
  }

  get trading() {
    return this.#trading;
  }

  get editing() {
    return this.#editing;
  }

  @Input() lessonId: number | undefined;
  @Input({ required: true }) studentId: number | undefined;

  @Output() readonly chatAlert = new EventEmitter<string>();

  ngOnInit() {
    this.#init();
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  add() {
    this.#editing = true;
  }

  trade() {
    this.#trading = true;
  }

  cancelled() {
    this.#editing = false;
    this.#trading = false;
  }

  cancel() {
    // do nothing
  }

  retry() {
    this.cancel();
    this.#init();
  }

  #init() {
    this.#loadingState = LoadingState.Complete;
    this.#subscriptions.push(
      this.#profileService.getCanUpdateStudent().subscribe(value => {
        this.#canEdit = value;
        this.#changeDetectorRef.markForCheck();
      }));
  }
}
