import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateStruct } from 'moment-extensions-lib';

import { determineOptional, FormControlDefinition } from '../../models';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
    selector: 'kip-form-control-date',
    templateUrl: './form-control-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormControlDateComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly control = input.required<FormControl<DateStruct | null>>();
  readonly weekday = input<FormControl<number[] | number | null> | undefined>(undefined);
  readonly minDate = input<DateStruct>({ year: 0, month: 1, day: 1 });
  readonly maxDate = input<DateStruct>({ year: 9999, month: 1, day: 1 });

  get optional() {
    return determineOptional(this.control());
  }

}
