import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-success-request',
    templateUrl: './success-request.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SuccessRequestComponent {

  readonly icons = Icons;
  readonly successTitle = input.required<string>();
  readonly descriptionText = input.required<string>();
  readonly closeBtnText = input('Close');
  readonly close = output();

}
