import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ServiceEnvironment } from 'service-lib';

import { LoadingState } from '../models';

@Component({
    selector: 'kip-loading-state',
    templateUrl: './loading-state.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadingStateComponent {

  #loadingState: LoadingState | undefined;
  #isSlow: LoadingState | undefined;
  #slowRequestTimerSubscription: Subscription | undefined;

  get isLoading(): boolean {
    return this.#loadingState === LoadingState.Loading;
  }

  get isError(): boolean {
    return this.#loadingState === LoadingState.Error;
  }

  get isSlow(): boolean {
    return this.#loadingState === LoadingState.Loading && this.#isSlow === LoadingState.Slow;
  }

  get isCancelled(): boolean {
    return this.#loadingState === LoadingState.Cancelled;
  }

  /* eslint-disable kip/no-unused-public-members */

  @Input({ required: true })
  set loadingState(loadingState: LoadingState | undefined) {
    this.#loadingState = loadingState;
    if (loadingState === LoadingState.Loading) {
      this.#slowRequestTimerSubscription = timer(ServiceEnvironment.value.slowRequestTime).subscribe(() => {
        this.#isSlow = LoadingState.Slow;
      });
    } else if (this.#slowRequestTimerSubscription) {
      this.#slowRequestTimerSubscription.unsubscribe();
      this.#slowRequestTimerSubscription = undefined;
    }
  }

  get loadingState() {
    return this.#loadingState;
  }

  /* eslint-enable kip/no-unused-public-members */

  @Output() readonly cancel = new EventEmitter();
  @Output() readonly retry = new EventEmitter();

  onSlowRequestCancel() {
    this.#loadingState = LoadingState.Cancelled;
    this.cancel.emit();
  }

  retryPageLoad() {
    this.retry.emit();
  }

  reloadPage() {
    window.location.reload();
  }
}
