import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'kip-logo',
    templateUrl: './kip-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LogoComponent {

  readonly gradientId = Math.random().toString(36).slice(2, 9).toString();
  readonly whiteLabel = input<string | undefined>(undefined);
  readonly height = input<number | undefined>(26);

}
