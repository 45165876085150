import { Pipe, PipeTransform } from '@angular/core';

import { TextAlignment } from '../models';

@Pipe({
    name: 'textAlignment',
    standalone: false
})
export class TextAlignmentPipe implements PipeTransform {

  transform(valid: TextAlignment | undefined) {
    switch (valid) {
      case TextAlignment.TextCenter:
        return 'text-center';
      case TextAlignment.TextJustify:
        return 'text-justify';
      case TextAlignment.TextLeft:
        return 'text-start';
      case TextAlignment.TextRight:
        return 'text-end';
      default:
        return 'text-center';
    }
  }
}
