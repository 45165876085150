import { Pipe, PipeTransform } from '@angular/core';

import { DayOfWeek } from '../models';

@Pipe({
    name: 'dayOfWeek',
    standalone: false
})
export class DayOfWeekPipe implements PipeTransform {

  transform(dayOfWeek: DayOfWeek, short = false): string {
    const value = DayOfWeek[dayOfWeek];
    return value.charAt(0).toUpperCase() + (short ? value.slice(1, 3) : value.slice(1));
  }
}
