import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon.component';

@Component({
    selector: 'kip-icon-graph-whiz-girl-c',
    templateUrl: './graph-whiz-girl-c.component.svg',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconGraphWhizGirlCComponent implements IconComponent {

}
