import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AwardsService, StudentPointsRedeemed } from 'awards-lib';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Subscription } from 'rxjs';

enum GroupType {
  TradeIn = 1
}

@Component({
    selector: 'kip-student-awards-trade',
    templateUrl: './student-awards-trade.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAwardsTradeComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #awardsService = inject(AwardsService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #studentId: number | undefined;
  #maxPoints = 0;
  #subscriptions: Subscription[] = [];

  pointsRedeemedForm: FormGroupTypeSafe<StudentPointsRedeemed>;

  set maxPoints(value: number) {
    this.#maxPoints = value;
  }

  get maxPoints() {
    return this.#maxPoints;
  }

  get points() {
    return this.pointsRedeemedForm.getSafe(x => x.points);
  }

  get hasPointsToRedeem() {
    return this.#maxPoints > 0;
  }

  @Input({ required: true }) set studentId(value: number | undefined) {
    if (this.#studentId !== value) {
      this.#studentId = value;
      this.#getPointsTotal();
    }
  }

  get studentId() {
    return this.#studentId;
  }
  @Output() readonly cancel = new EventEmitter();
  @Output() readonly chatAlert = new EventEmitter<string>();

  constructor() {
    /*eslint-disable @typescript-eslint/unbound-method */
    this.pointsRedeemedForm = this.#fb.group<StudentPointsRedeemed>({
      studentId: new FormControl<number>(0, Validators.required),
      pointsRedeemedTypeId: new FormControl<number>(GroupType.TradeIn),
      points: new FormControl<number>(1, [Validators.required, Validators.min(1)])
    });
    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  submit() {
    if (this.studentId) {
      this.pointsRedeemedForm.patchSafe({ studentId: this.studentId });
      const studentPointsRedeemed = this.pointsRedeemedForm.value;
      this.#subscriptions.push(
        this.#awardsService.postRedeemedPoints(studentPointsRedeemed)
          .subscribe(error => {
            if (error) {
              this.pointsRedeemedForm.handleServerErrors(error);
            } else {
              this.chatAlert.emit(`${this.pointsRedeemedForm.value.points} points have been redeemed`);
              this.cancel.emit();
            }
          }));
    }
  }

  #getPointsTotal() {
    if (this.studentId) {
      this.#subscriptions.push(
        this.#awardsService.getStudentTotalPoints(this.studentId).subscribe(data => {
          this.maxPoints = data.points;
          /*eslint-disable @typescript-eslint/unbound-method */
          this.pointsRedeemedForm.controls['points'].setValidators([Validators.required, Validators.min(1), Validators.max(this.maxPoints)]);
          /*eslint-enable @typescript-eslint/unbound-method */
          this.#changeDetectorRef.markForCheck();
        }));
    }
  }
}
